import Dropzone from 'react-dropzone';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { enqueueSnackbar } from 'notistack';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Label, RadioGroup } from '../../../flexyui';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { pinCodesUpload } from '../../../../network/shipping/shipping';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { InfoCardUserEligibility, UserScope } from '../../../../constants/coupons';
import classNames from 'classnames';

export const DiscountUserSpecific = () => {
  const { values, errors, touched, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  useEffect(() => {
    setFieldValue('user_specific_metadata.resource_id', values?.user_specific_metadata?.resource_id || null);
  }, [values?.user_specific_metadata?.resource_id]);

  const pinCodesUploadMutation = async (formData: FormData) => {
    return await pinCodesUpload(formData);
  };

  const handleFileChange = async (file: File[]) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('file_purpose', 'USER_PHONE_NUMBERS');
      if (file[0].size > 1024 * 1024 * 10) {
        enqueueSnackbar('Oops! File size exceeds the limit (10 MB).', {
          variant: 'error'
        });
        return;
      }
      try {
        const response = await pinCodesUploadMutation(formData);
        setFieldValue('user_specific_metadata.resource_id', response.data.resource_id);
        setFieldValue('user_specific_metadata.file_orginial_name', file[0].name);
        enqueueSnackbar('Hurray! User phone numbers are uploaded successfully', {
          variant: 'success'
        });
      } catch (error) {
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
      <DiscountInfoCard data={InfoCardUserEligibility[1]} />
      <div>
        <div className="font-semibold text-base mb-4 block sm:hidden">Is this Discount for specific users?</div>
        <RadioGroup {...getFieldProps('user_specific_metadata.user_scope')} defaultValue={UserScope.ALL_USERS}>
          <RadioWithLabel
            label="All users"
            value={UserScope.ALL_USERS}
            onClick={() => setFieldValue('user_specific_metadata.user_scope', UserScope.ALL_USERS)}
          />
          <RadioWithLabel
            label="Specific users"
            value={UserScope.SPECIFIC_USERS}
            onClick={() => setFieldValue('user_specific_metadata.user_scope', UserScope.SPECIFIC_USERS)}
          />
        </RadioGroup>
        {values?.user_specific_metadata?.user_scope === UserScope.SPECIFIC_USERS && (
          <>
            <Card
              className={classNames('w-full sm:w-[90%] flex items-center justify-between p-3 mt-4 border', {
                'border-red-600':
                  touched.user_specific_metadata?.resource_id && !!errors.user_specific_metadata?.resource_id
              })}
            >
              <div className="flex items-center">
                <div className="p-2 w-fit rounded-full border border-muted">
                  {values?.user_specific_metadata?.resource_id ? <Unicons.UilFileAlt /> : <Unicons.UilExport />}
                </div>
                <div className="flex flex-col ml-3">
                  <Label variant={'default'} size={'paragraph'} className="font-medium">
                    {values?.user_specific_metadata?.file_orginial_name
                      ? values?.user_specific_metadata?.file_orginial_name
                      : 'Upload CSV'}
                  </Label>
                  <Label size={'sm'} className="text-gray-400">
                    {values?.user_specific_metadata?.resource_id
                      ? 'User phone numbers'
                      : 'Upload file from your computer.'}
                  </Label>
                </div>
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleFileChange(acceptedFiles);
                }}
                maxFiles={1}
                multiple={false}
                accept={{
                  'text/csv': ['.csv']
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    {values?.user_specific_metadata?.resource_id ? (
                      <div className="flex space-x-3">
                        {values?.user_specific_metadata?.download_url ? (
                          <a
                            href={values?.user_specific_metadata?.download_url}
                            download={values?.user_specific_metadata?.file_orginial_name}
                            onError={(e) => {
                              console.log('Download error', e);
                            }}
                          >
                            <Button size="icon" variant="icon">
                              <Unicons.UilDownloadAlt />
                            </Button>
                          </a>
                        ) : null}
                        <Button
                          size="icon"
                          variant="icon"
                          onClick={() => {
                            setFieldValue('user_specific_metadata.resource_id', null);
                            setFieldValue('user_specific_metadata.download_url', null);
                            setFieldValue('user_specific_metadata.file_orginial_name', null);
                          }}
                        >
                          <Unicons.UilTrashAlt />
                        </Button>
                      </div>
                    ) : (
                      <Button size={'md'} variant={'outline'} {...getRootProps()}>
                        {'Upload'}
                        <input name="user_phone" {...getInputProps()} accept=".csv" />
                      </Button>
                    )}
                  </>
                )}
              </Dropzone>
            </Card>
            {touched.user_specific_metadata?.resource_id && !!errors.user_specific_metadata?.resource_id && (
              <p className="text-[#cf3c3c] text-xs mt-1 ml-0.5">{errors.user_specific_metadata?.resource_id}</p>
            )}
            <div className="mt-2">
              <Label className="text-gray-500 text-xs font-normal">
                Please make sure that the file you provided is in the right format.{' '}
                <Label className="font-medium text-xs underline cursor-pointer">
                  <a
                    href="https://static.flexype.in/assets/checkout/user_specific_example.csv"
                    download={'user_phone_sample'}
                  >
                    Download a sample file.
                  </a>
                </Label>
              </Label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

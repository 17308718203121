import {
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Label,
  Skeleton
} from '../../../flexyui';
import {
  useTaxDetailsQuery,
  useDeleteTaxDetailMutation,
  useDeleteProvinceFromTaxDetailMutation
} from '../../../../graphql';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { AddOverride } from './add-override';
import { RootState } from '../../../../store';
import { DefaultTaxes } from './default-taxes';
import { RegionOverride } from './region-override';
import * as Unicons from '@iconscout/react-unicons';
import { indianStates } from '../../../../constants/settings';
import { EventCategory, TaxesEvent, trackEvents } from '../../../../analytics';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';

export const SettingsTaxesAndDuties: React.FC = () => {
  const [openProductOverrideModal, setOpenProductOverrideModal] = useState<boolean>(false);
  const [openAddOverrideModal, setOpenAddOverrideModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [regionOverrideData, setRegionOverrideData] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);

  const storeUrl = useSelector((state: RootState) => state.main.storeUrl);

  const { data, loading, refetch } = useTaxDetailsQuery({ fetchPolicy: 'no-cache' });
  const [deleteTaxDetail, { loading: loadingDeleteTaxDetail }] = useDeleteTaxDetailMutation();
  const [deleteProvinceFromTaxDetail, { loading: loadingDeleteProvinceFromTaxDetail }] =
    useDeleteProvinceFromTaxDetailMutation();

  const handleDeleteTaxDetail = async (overrideTaxDetail: any) => {
    try {
      await deleteTaxDetail({
        variables: {
          collectionId: overrideTaxDetail?.collection_id,
          countryCode: overrideTaxDetail?.country_code
        }
      });

      enqueueSnackbar('Hurray! Override deleted successfully!', {
        variant: 'success'
      });

      await refetch();
      trackEvents(EventCategory.TAXES_DUTIES, TaxesEvent.REMOVE_OVERRIDE);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setDeleteData(null);
  };

  const handleDeleteProvinceFromTaxDetail = async (overrideTaxDetail: any, stateCode: string) => {
    try {
      await deleteProvinceFromTaxDetail({
        variables: {
          collectionId: overrideTaxDetail?.collection_id,
          countryCode: overrideTaxDetail?.country_code,
          stateCode
        }
      });

      enqueueSnackbar('Hurray! Region override deleted successfully!', {
        variant: 'success'
      });

      await refetch();
      trackEvents(EventCategory.TAXES_DUTIES, TaxesEvent.REMOVE_PROVINCE);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setDeleteData(null);
  };

  if (loading)
    return (
      <Card className="px-4">
        <Skeleton className="w-[150px] sm:w-[300px] h-6 m-0 my-4" />
        {[1, 2].map((index) => (
          <div className="border border-muted mb-4 rounded-[6px] overflow-hidden">
            <div className="flex items-center justify-between px-4 py-3 bg-gray-100 border-b">
              <Skeleton className="w-[100px] sm:w-[150px] !m-0" />
              <Skeleton className="!m-0 h-5 w-5" />
            </div>
            <div className="flex items-center justify-between px-4 py-3 border-b">
              <Skeleton className="w-[200px] sm:w-[450px] !m-0" />
              <div className="flex items-center gap-2">
                <Skeleton className="!m-0 h-8 w-8" />
                <Skeleton className="!m-0 h-8 w-8" />
              </div>
            </div>
            <div className="flex items-center justify-between px-4 py-3">
              <Skeleton className="w-[150px] sm:w-[300px] !m-0" />
              <div className="flex items-center gap-2">
                <Skeleton className="!m-0 h-8 w-8" />
                <Skeleton className="!m-0 h-8 w-8" />
              </div>
            </div>
          </div>
        ))}
      </Card>
    );

  return (
    <>
      <DefaultTaxes defaultTaxDetails={data?.defaultTaxDetails?.[0]} />

      {data && data?.overrideTaxDetails && (
        <>
          <Label size="md" className="text-base">
            Tax Rates and Exemptions
          </Label>
          <Card className="mt-4 mb-4 h-fit p-4">
            <div className="flex items-start sm:items-center justify-between mb-4">
              <div>
                <div className="font-medium">Product Overrides</div>
                <div className="text-gray-400 text-xs">
                  Use this option to customize taxes for individual collections
                </div>
              </div>
              {(data?.overrideTaxDetails?.length || 0) > 0 && (
                <Button
                  variant="primary"
                  size="sm"
                  className="flex items-center"
                  onClick={() => {
                    setOpenProductOverrideModal(true);
                    trackEvents(EventCategory.TAXES_DUTIES, TaxesEvent.ADD_OVERRIDE_CLICKED);
                  }}
                >
                  <Unicons.UilPlus size={18} className="mr-2" />
                  <div>
                    Add <span className="hidden sm:inline-block">override</span>
                  </div>
                </Button>
              )}
            </div>

            {data?.overrideTaxDetails?.map((overrideTaxDetail: any) => (
              <div
                key={overrideTaxDetail?.collection_id}
                className="border border-muted mt-4 rounded-[6px] overflow-hidden"
              >
                <div className="flex items-center justify-between px-4 py-2.5 bg-gray-100 border-b">
                  <Button variant="link" className="w-fit">
                    {storeUrl?.length > 0 ? (
                      <a
                        href={`https://admin.shopify.com/store/${storeUrl.split('.')[0]}/collections/${
                          overrideTaxDetail?.collection_id
                        }`}
                        target="_blank"
                      >
                        {overrideTaxDetail?.collection?.title} Collection
                      </a>
                    ) : (
                      <div>{overrideTaxDetail?.collection?.title} Collection</div>
                    )}
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="focus:outline-0">
                      <Unicons.UilEllipsisH
                        size={24}
                        className="py-1 text-gray-500 cursor-pointer rounded-lg hover:bg-gray-200"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-36 !z-[1500]" sideOffset={0} align="end">
                      <DropdownMenuItem
                        disabled={overrideTaxDetail?.provinces.length === indianStates.length}
                        onClick={() => {
                          setRegionOverrideData({ overrideTaxDetail: overrideTaxDetail });
                          setOpenAddOverrideModal(true);
                        }}
                      >
                        Add override
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setDeleteData({
                            level: 'top',
                            overrideTaxDetail: overrideTaxDetail
                          });
                          setShowDeleteModal(true);
                        }}
                      >
                        <div className="text-red-500">Remove override</div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>

                {overrideTaxDetail?.provinces?.map((province: any, index: number) => (
                  <div
                    className={`px-4 py-2 bg-white hover:bg-slate-50 transition-colors ease-linear ${
                      index !== overrideTaxDetail?.provinces?.length - 1 ? 'border-b' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="block sm:flex items-center">
                        <div className="min-w-[175px] w-[220px] sm:w-[175px] overflow-hidden overflow-ellipsis whitespace-nowrap font-medium sm:font-normal">
                          {province?.state === 'All Regions' ? 'India' : province?.state}
                        </div>
                        <div className="hidden sm:block mx-5">
                          <Input
                            value={(province.rate * 100).toFixed(3).replace(/\.?0+$/, '')}
                            type="number"
                            readOnly={true}
                            iconPosition="end"
                            className="h-8 w-24"
                            icon={<Unicons.UilPercentage size={18} />}
                          />
                        </div>
                        <div className="hidden sm:block">
                          {province?.state_code === 'DEFAULT'
                            ? `instead of ${((data?.defaultTaxDetails?.[0] as any)?.tax * 100)
                                .toFixed(3)
                                .replace(/\.?0+$/, '')}% federal tax`
                            : ((data?.defaultTaxDetails?.[0] as any)?.provinces?.find(
                                (each: any) => each.state_code === province.state_code
                              )?.tax_value ?? 'No tax value found')}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Button
                          size="icon"
                          variant="icon"
                          onClick={() => {
                            setRegionOverrideData({
                              overrideTaxDetail: overrideTaxDetail,
                              ...province
                            });
                            setOpenAddOverrideModal(true);
                          }}
                          className="mr-2"
                        >
                          <Unicons.UilEdit size={'22'} className="p-[2px]" />
                        </Button>
                        <Button
                          size="icon"
                          variant="icon"
                          onClick={async () => {
                            setDeleteData({
                              level: 'sub',
                              overrideTaxDetail: overrideTaxDetail,
                              stateCode: province?.state_code
                            });
                            setShowDeleteModal(true);
                          }}
                        >
                          <Unicons.UilTrashAlt size="22" className="p-[2px] hover:text-red-500" />
                        </Button>
                      </div>
                    </div>
                    <div className="flex flex-col mt-2 sm:hidden">
                      <div className="w-24 mb-1">
                        <Input
                          value={(province.rate * 100).toFixed(3).replace(/\.?0+$/, '')}
                          type="number"
                          readOnly={true}
                          iconPosition="end"
                          className="h-8"
                          icon={<Unicons.UilPercentage size={18} />}
                        />
                      </div>
                      <div className="ml-0.5">
                        {province?.state_code === 'DEFAULT'
                          ? `instead of ${((data?.defaultTaxDetails?.[0] as any)?.tax * 100)
                              .toFixed(3)
                              .replace(/\.?0+$/, '')}% federal tax`
                          : ((data?.defaultTaxDetails?.[0] as any)?.provinces?.find(
                              (each: any) => each.state_code === province.state_code
                            )?.tax_value ?? 'No tax value found')}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {data && (data?.overrideTaxDetails?.length || 0) === 0 && (
              <div className="border border-muted px-4 py-4 mt-2 rounded-[6px]">
                <div className="text-center my-4">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setOpenProductOverrideModal(true);
                      trackEvents(EventCategory.TAXES_DUTIES, TaxesEvent.ADD_OVERRIDE_CLICKED);
                    }}
                  >
                    <Unicons.UilPlus size={18} className="mr-2" />
                    Add override
                  </Button>
                </div>
              </div>
            )}

            {!data?.overrideTaxDetails && !loading && (
              <div className="text-center my-40">
                <div className="text-gray-300 text-base">Not Available</div>
              </div>
            )}
          </Card>
        </>
      )}

      <AddOverride
        refetch={refetch}
        openProductOverrideModal={openProductOverrideModal}
        setOpenProductOverrideModal={setOpenProductOverrideModal}
      />

      <RegionOverride
        refetch={refetch}
        regionOverrideData={regionOverrideData}
        openAddOverrideModal={openAddOverrideModal}
        setOpenAddOverrideModal={setOpenAddOverrideModal}
      />

      <ConfirmationDialog
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onSave={async () => {
          if (deleteData?.level === 'top') await handleDeleteTaxDetail(deleteData?.overrideTaxDetail);
          else {
            if (deleteData?.overrideTaxDetail?.provinces?.length === 1)
              await handleDeleteTaxDetail(deleteData?.overrideTaxDetail);
            else await handleDeleteProvinceFromTaxDetail(deleteData?.overrideTaxDetail, deleteData?.stateCode);
          }
        }}
        headerText={deleteData?.level === 'top' ? 'Delete override?' : 'Delete region override?'}
        text={
          deleteData?.level === 'top'
            ? 'This Action will delete the selected override'
            : 'This Action will delete the selected region override'
        }
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={loadingDeleteTaxDetail || loadingDeleteProvinceFromTaxDetail}
      />
    </>
  );
};

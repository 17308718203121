import { CartSessionStatus } from '../../graphql';

export const getSessionState = (path: string) => {
  if (path.startsWith('/orders')) {
    return [CartSessionStatus.Completed];
  } else if (path.startsWith('/cart')) {
    return [
      // CartSessionStatus.Initiated,
      CartSessionStatus.LoggedIn,
      CartSessionStatus.AddressUpdated,
      CartSessionStatus.PaymentInitiated
      // CartSessionStatus.Failed
    ];
  }
  return [];
};

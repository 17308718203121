import React from 'react';
import { Card } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { DashboardGraphTitle } from '../title/title';
import { PerformanceMetricsQuery } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';

const convertToMinutes = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const sec = Math.round(totalSeconds % 60);
  if (minutes > 0) {
    return `${minutes} min ${sec} s`;
  }
  return `${sec} s`;
};

type Props = {
  metricsData?: PerformanceMetricsQuery['performanceMetrics'];
  loading: boolean;
};

export const AverageSessionDuration: React.FC<Props> = ({ metricsData, loading }) => {
  return (
    <Card className="min-h-[13rem] sm:min-h-[10rem]">
      <div className="font-medium text-black pt-4 px-4 flex items-center">
        <div>Average Session Duration</div>
        <ButtonWithInfo
          heading="Average Session Duration"
          description="Average time a user is spending on checkout to place an order."
          calculation={false}
          align="center"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <SkeletonDashboard heightGraph="h-[70px]" />
      ) : (
        <DashboardGraphTitle
          loading={loading}
          value={convertToMinutes(metricsData?.metrics?.current?.session_time || 0)}
          title="Average Session Duration"
        />
      )}
    </Card>
  );
};

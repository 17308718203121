import React, { useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { enqueueSnackbar } from 'notistack';

type Props = {
  email: string;
};
export const CopyEmail: React.FC<Props> = ({ email }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <>
      <div className="py-1.5 px-3 flex items-center">
        <span
          className="relative w-fit pr-[25px]"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {email ? email : '-'}{' '}
          {email && isHovered && (
            <Unicons.UilCopy
              size={'20'}
              className="p-[2px] text-[#121b38] cursor-pointer absolute right-[-1px] top-0 z-[1000]"
              onClick={(e: any) => {
                e.stopPropagation();
                navigator.clipboard.writeText(email);
                enqueueSnackbar('Copied to clipboard', {
                  variant: 'success'
                });
              }}
            />
          )}
        </span>
      </div>
    </>
  );
};

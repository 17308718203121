import {
  PaymentState,
  SearchField,
  SortOrder,
  SortOrdersBy,
  PaymentMethod,
  PaymentProvider,
  useOrderQuery
} from '../../../graphql';
import React from 'react';
import { OrderItems } from './order-items';
import { OrderHeader } from './order-header';
import { OrderSummary } from './order-summary';
import { CustomerDetails } from './customer-details';
import { AnalyticsDetails } from './analytics-details';
import { getSessionState } from '../../../utils/orders';
import { ConversionDetails } from './conversion-details';
import { OrderTransactions } from './order-transactions';
import ContentWrapper from '../../layout/content-wrapper';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const today = new Date().toISOString().split('T')[0];
  const last7Days = new Date();
  last7Days.setDate(last7Days.getDate() - 7);
  const last7DaysFormatted = last7Days.toISOString().split('T')[0];

  const SESSION_STORAGE_KEY = 'flexy_date_range';
  const storedDateRange = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const initialDateRange = storedDateRange
    ? JSON.parse(storedDateRange)
    : {
        start: `${last7DaysFormatted}T00:00:00.000Z`,
        end: `${today}T23:59:59.999Z`
      };

  const location = useLocation();
  const sessionState = getSessionState(location.pathname);
  const basePath = `/${location.pathname.split('/')[1]}`;

  const { data, loading, refetch } = useOrderQuery({
    variables: {
      cartSessionId: orderId || '',
      query: searchParams.get('query') || '',
      searchField: (searchParams.get('searchField') as SearchField) || SearchField.All,
      sessionState: sessionState,
      paymentStatus: searchParams.get('paymentStatus')
        ? (searchParams.get('paymentStatus')?.split(',') as PaymentState[])
        : [],
      paymentMethods: searchParams.get('paymentMethods')
        ? (searchParams.get('paymentMethods')?.split(',') as PaymentMethod[])
        : [],
      paymentProviders: searchParams.get('paymentProviders')
        ? (searchParams.get('paymentProviders')?.split(',') as PaymentProvider[])
        : [],
      dateRange: initialDateRange,
      sortBy: (searchParams.get('sortBy') as SortOrdersBy) || SortOrdersBy.CreatedAt,
      sortOrder: (searchParams.get('sortOrder') as SortOrder) || SortOrder.Desc
    },
    onError: (error) => {
      navigate(`${basePath}?${searchParams}`);
      handleGraphqlErrors(error);
    }
  });

  return (
    <>
      <ContentWrapper>
        <div className="px-4 mb-4">
          <OrderHeader data={data?.order} loading={loading} refetch={refetch} basePath={basePath} />

          <div className="w-full flex flex-col md:flex-row justify-between gap-4">
            <div className="w-full md:w-[70%]">
              <OrderItems data={data?.order} loading={loading} basePath={basePath} />
              <OrderSummary data={data?.order} loading={loading} basePath={basePath} />
              {/* {basePath === '/orders' && <ConversionDetails />}*/}
            </div>
            <div className="flex-1">
              <CustomerDetails data={data?.order} loading={loading} />
              <AnalyticsDetails data={data?.order} loading={loading} />
              <OrderTransactions data={data?.order} loading={loading} />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};

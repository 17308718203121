import {
  DropdownMenuItem,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent
} from '../flexyui';
import classNames from 'classnames';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { CustomAvatar } from '../shared/custom-avatar';
import { useGetMerchantListQuery } from '../../graphql';
import { AllStoresDialog } from '../navbar/all-stores-dialog';
import useLoginWithMerchantId from '../../hooks/use-login-with-merchant';
import { AdministrationEvents, EventCategory, trackEvents } from '../../analytics';

export const DashboardAction = () => {
  const loginMutation = useLoginWithMerchantId();
  const { data } = useGetMerchantListQuery();
  const merchantId = useSelector((state: RootState) => state.main.store);
  const userName = useSelector((state: RootState) => state.main.userName);
  const userEmail = useSelector((state: RootState) => state.main.userEmail);
  const storeName = useSelector((state: RootState) => state.main.storeName);

  const [showAllStoresDialog, setShowAllStoreDialog] = useState<boolean>(false);

  const updatedMerchantList = useMemo(() => {
    if (!data) return [];

    const sortedMerchants = [...data.getMerchantList].sort((a, b) => {
      if (a.id === merchantId) return -1;
      if (b.id === merchantId) return 1;
      return 0;
    });

    return sortedMerchants.slice(0, 5);
  }, [data, merchantId]);

  const handleStoreChange = (storeId: string) => {
    if (storeId !== merchantId) {
      trackEvents(EventCategory.ADMINISTRATION, AdministrationEvents.STORE_SWITCH);
      loginMutation.mutate(storeId);
    }
  };

  const handleLogout = () => {
    trackEvents(EventCategory.ADMINISTRATION, AdministrationEvents.LOG_OUT);
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (data && data.getMerchantList.length <= 5) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac/i.test(navigator.userAgent);
      const isCmd = isMac ? event.metaKey : event.ctrlKey;

      if (isCmd && event.shiftKey && event.key.toLowerCase() === 'f') {
        event.preventDefault();
        if (!showAllStoresDialog) setShowAllStoreDialog(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [data]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="focus:outline-0 flex items-center gap-1.5 py-1.5 px-2.5 rounded-xl border border-transparent transition-all ease-in-out cursor-pointer hover:border-gray-100 hover:shadow-md data-[state=open]:border-gray-100 data-[state=open]:shadow-md">
          <Unicons.UilStore size={20} />
          <div>{storeName}</div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className={`!z-[1500] ${updatedMerchantList.length === 1 && 'w-[220px]'}`}
          sideOffset={6}
          align="end"
        >
          {updatedMerchantList.length > 1 && (
            <>
              <DropdownMenuGroup>
                {updatedMerchantList.map((store) => (
                  <DropdownMenuItem
                    className={classNames('mb-0.5 last:mb-0', {
                      'bg-accent cursor-auto': store.id === merchantId
                    })}
                    onClick={() => handleStoreChange(store.id)}
                  >
                    <div className="flex items-center justify-between gap-24">
                      <div className="flex items-center gap-2">
                        <CustomAvatar name={store.name} size="SMALL" />
                        <div>{store.name}</div>
                      </div>
                      {store.id === merchantId && <Unicons.UilCheck className="text-gray-500" />}
                    </div>
                  </DropdownMenuItem>
                ))}
                {data && data.getMerchantList.length > 5 && (
                  <DropdownMenuItem
                    onClick={() => {
                      setShowAllStoreDialog(true);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Unicons.UilStore />
                      <div className="ml-0.5 text-[13px]">All stores</div>
                    </div>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuItem className="focus:bg-white cursor-auto mr-6">
            <div>
              <div className="text-[13px] font-medium">{userName}</div>
              <div className="text-xs -mt-0.5">{userEmail}</div>
            </div>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="text-red-500 focus:text-red-500" onClick={handleLogout}>
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AllStoresDialog
        open={showAllStoresDialog}
        setOpen={setShowAllStoreDialog}
        merchantList={data?.getMerchantList || []}
      />
    </>
  );
};

import dayjs from 'dayjs';
import React, { useState } from 'react';
import { OrderQuery } from '../../../graphql';
import { OrderEdit } from '../order-edit/index';
import { Button, Skeleton } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { Title } from '../../shared/dashboard-title/title';
import { PaymentStatus } from '../payment-status/payment-status';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FulfilledStatus } from '../fulfillment-status/fulfillment-status';
import { EventCategory, OrderEvents, trackEvents } from '../../../analytics';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
  refetch: () => Promise<any>;
  basePath: string;
};

export const OrderHeader: React.FC<Props> = ({ data, loading, refetch, basePath }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  return (
    <div className="flex items-center justify-between mb-2">
      <div className="w-full flex flex-col md:flex-row md:items-center gap-3">
        <div className="flex items-center gap-1.5">
          <Button
            size="icon"
            variant="ghost"
            className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
            onClick={() => navigate(`${basePath}?${searchParams}`)}
          >
            <Unicons.UilArrowLeft />
          </Button>
          <div className="flex items-center gap-1">
            <Title title={basePath === '/orders' ? 'Order' : '#'} />
            {loading ? (
              <Skeleton size="label" />
            ) : basePath === '/orders' ? (
              <Link
                to={data?.platform_order_admin_url || ''}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER_IN_SHOPIFY);
                }}
                className="text-xl text-primary font-semibold hover:underline mr-2"
              >
                {data?.platform_order_name}
              </Link>
            ) : (
              <Title title={data?.id?.slice(0, 18)?.toUpperCase() || ''} />
            )}
          </div>
        </div>
        <div className="w-full md:w-auto flex items-center gap-1.5 -mt-5 ml-2 mb-2 md:m-0">
          {basePath === '/orders' && (
            <>
              <div className="hidden md:flex">
                {loading ? <Skeleton size="label" /> : <PaymentStatus text={data?.order_invoice?.payment_state} />}
              </div>
              <div className="hidden md:flex">
                {' '}
                {loading ? <Skeleton size="label" /> : <FulfilledStatus text={data?.platform_fulfillment_status} />}
              </div>
            </>
          )}
          <div className="hidden md:block h-5 w-1 border-r ml-2.5 mr-3" />
          <div className="flex items-center gap-2 -ml-1">
            <Unicons.UilCalendarAlt size={18} className="text-gray-500" />
            {loading ? (
              <Skeleton size="label" />
            ) : (
              <div className="text-gray-500 ">{dayjs(data?.created_at).format('MMM D YYYY, hh:mm A')}</div>
            )}
          </div>
        </div>
      </div>
      {basePath === '/orders' && (
        <>
          <Button
            size="sm"
            variant="outline"
            className="h-8 hidden md:flex"
            onClick={() => setOpenEditDialog(true)}
            disabled={data?.platform_fulfillment_status === 'fulfilled' || loading}
          >
            <Unicons.UilEdit size={18} className="mr-2" />
            Edit
          </Button>
          <OrderEdit order={data} refetch={refetch} open={openEditDialog} setOpen={setOpenEditDialog} />
        </>
      )}
      <div className="flex justify-center ml-2 -mt-4 md:mt-0">
        <Button
          size="icon"
          variant="icon"
          className="rounded-r-none disabled:bg-border border-r-0"
          disabled={!data?.prev_cart_session_id}
          onClick={() => {
            navigate(`${basePath}/${data?.prev_cart_session_id}?${searchParams}`);

            trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER);
          }}
        >
          <Unicons.UilAngleLeft size="24" className="text-gray-500" />
        </Button>
        <Button
          size="icon"
          variant="icon"
          className="rounded-l-none disabled:bg-border"
          disabled={!data?.next_cart_session_id}
          onClick={() => {
            navigate(`${basePath}/${data?.next_cart_session_id}?${searchParams}`);

            trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER);
          }}
        >
          <Unicons.UilAngleRight size="24" className="text-gray-500" />
        </Button>
      </div>
    </div>
  );
};

import {
  AlertDialog,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogDescription
} from '../../flexyui/AlertDialog';
import {
  CalculatedOrder,
  CalculateLineItem,
  useGetOrderEditSessionQuery,
  useOrderEditEndSessionMutation,
  useOrderEditAddLineItemMutation,
  useOrderEditStartSessionMutation,
  useOrderEditUpdateLineItemMutation,
  useOrderEditDeleteLineItemMutation
} from '../../../graphql';
import { isEqual } from 'lodash';
import { LineItem } from './line-item';
import { Skeleton } from '../../flexyui';
import { Input } from '../../flexyui/Input';
import { Button } from '../../flexyui/Button';
import { OrderSummary } from './order-summary';
import { ApplyDiscount } from './apply-discount';
import { Checkbox } from '../../flexyui/Checkbox';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { LineItemSkeleton } from './line-item-skeleton';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { OrderEditProductSelectionDialog } from './product-selection';
import { EventCategory, OrderEditEvents, trackEvents } from '../../../analytics';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';

type Props = {
  order: any;
  refetch: () => Promise<any>;
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const OrderEdit: React.FC<Props> = ({ order, refetch, open, setOpen }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sendInvoice, setSendInvoice] = useState<boolean>(false);
  const [editReason, setEditReason] = useState<string>('');
  const [selectionSearch, setSelectionSearch] = useState<string>('');
  const [openProductSelection, setOpenProductSelection] = useState<boolean>(false);
  const [openApplyDiscount, setOpenApplyDiscount] = useState<boolean>(false);
  const [addLineItemLoading, setAddLineItemLoading] = useState<boolean>(false);

  const [calculatedOrderId, setCalculatedOrderId] = useState<string>('');
  const [calculatedOrder, setCalculatedOrder] = useState<CalculatedOrder | null>(null);
  const [initialCalculatedOrder, setInitialCalculatedOrder] = useState<CalculatedOrder | null>(null);
  const [discountLineItem, setDiscountLineItem] = useState<CalculateLineItem | null>(null);

  const {
    data,
    loading,
    refetch: refetchOrderEditSession
  } = useGetOrderEditSessionQuery({
    variables: { calculatedOrderId },
    skip: !calculatedOrderId,
    onError: (error) => {
      setOpen(false);
      handleGraphqlErrors(error);
    }
  });

  const [startOrderEditSession, { loading: startOrderEditSessionLoading }] = useOrderEditStartSessionMutation();
  const [endOrderEditSession, { loading: endOrderEditSessionLoading }] = useOrderEditEndSessionMutation();
  const [addLineItem] = useOrderEditAddLineItemMutation();
  const [editLineItemQuantity, { loading: editLineItemQuantityLoading }] = useOrderEditUpdateLineItemMutation();
  const [deleteLineItem, { loading: deleteLineItemLoading }] = useOrderEditDeleteLineItemMutation();

  const initializeOrderEditSession = async () => {
    try {
      const res = await startOrderEditSession({
        variables: { orderId: order.platform_order_id }
      });

      const responseData = res?.data?.orderEditStartSession;
      if (responseData) setCalculatedOrderId(responseData);

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.SESSION_START, {
        orderId: order.platform_order_id
      });
    } catch (error) {
      setOpen(false);
      handleGraphqlErrors(error);
    }
  };
  const handleAddVariantToOrder = async (selectedVariantIds: Array<number>) => {
    try {
      setAddLineItemLoading(true);
      for (const selectedVariantId of selectedVariantIds) {
        await addLineItem({
          variables: {
            calculatedOrderId: calculatedOrderId,
            variantId: `gid://shopify/ProductVariant/${selectedVariantId}`
          }
        });

        trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.ADD_PRODUCT, {
          calculatedOrderId: calculatedOrderId,
          variantId: `gid://shopify/ProductVariant/${selectedVariantId}`
        });

        await refetchOrderEditSession();
      }

      setAddLineItemLoading(false);
    } catch (error) {
      setAddLineItemLoading(false);
      handleGraphqlErrors(error);
    }
  };
  const handleEditLineItemQuantity = async (lineItem: CalculateLineItem, functionality: string) => {
    const updatedQuantity = functionality === 'decrement' ? lineItem.quantity - 1 : lineItem.quantity + 1;

    try {
      await editLineItemQuantity({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          quantity: updatedQuantity
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.EDIT_QUANTITY, {
        calculatedOrderId: calculatedOrderId,
        calculatedLineItemId: lineItem.calculated_line_item_id,
        quantity: updatedQuantity
      });

      await refetchOrderEditSession();
    } catch (error) {
      handleGraphqlErrors(error);
    }
  };
  const handleDeleteLineItem = async (lineItem: CalculateLineItem) => {
    try {
      await deleteLineItem({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.REMOVE_PRODUCT, {
        calculatedOrderId: calculatedOrderId,
        calculatedLineItemId: lineItem.calculated_line_item_id
      });

      await refetchOrderEditSession();
    } catch (error) {
      handleGraphqlErrors(error);
    }
  };
  const handleEndSessionBySaving = async () => {
    try {
      await endOrderEditSession({
        variables: {
          calculatedOrderId: calculatedOrderId,
          sendInvoiceToCustomer: sendInvoice,
          reasonForEdit: editReason
        }
      });

      setOpen(false);
      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.SESSION_END, {
        orderId: order.platform_order_id,
        calculatedOrderId: calculatedOrderId,
        sendInvoiceToCustomer: sendInvoice,
        reasonForEdit: editReason
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }
  };

  const isDisabled =
    loading ||
    startOrderEditSessionLoading ||
    addLineItemLoading ||
    deleteLineItemLoading ||
    editLineItemQuantityLoading;

  const handleLineItemDiscount = (data: CalculateLineItem) => {
    setDiscountLineItem(data);
    setOpenApplyDiscount(true);
  };

  useEffect(() => {
    if (open) initializeOrderEditSession();
    else {
      setCalculatedOrderId('');
      setCalculatedOrder(null);
      setInitialCalculatedOrder(null);
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      const updatedData = {
        ...data.getOrderEditSession,
        calculated_line_items: data.getOrderEditSession.calculated_line_items
          ? [...data.getOrderEditSession.calculated_line_items].reverse()
          : []
      };
      setCalculatedOrder(updatedData);
      if (!initialCalculatedOrder) setInitialCalculatedOrder(updatedData);
    }
  }, [data]);

  useEffect(() => {
    if (selectionSearch.length > 0) setOpenProductSelection(true);
    else setOpenProductSelection(false);
  }, [selectionSearch]);

  return (
    <>
      <AlertDialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <AlertDialogContent className="!gap-0" size="lg">
          <div>
            <AlertDialogHeader>
              <AlertDialogTitle className="pl-4 pr-2.5 py-2.5 ">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 font-semibold">
                    <span>Edit Order</span>
                    <span className="font-normal">{order?.name}</span>
                  </div>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => {
                      if (!isEqual(initialCalculatedOrder, calculatedOrder)) setShowModal(true);
                      else setOpen(false);
                    }}
                  >
                    <Unicons.UilTimes className="text-primary" />
                  </Button>
                </div>
              </AlertDialogTitle>
              <AlertDialogDescription className="!p-0 !m-0 text-[#121B38]">
                <div className="w-full h-[80vh] flex box-border">
                  <div className="w-[60%] border-r-[1px] p-4">
                    <div className="border-[1px] rounded-xl">
                      <div className="text-base text-black font-semibold p-4 flex items-center justify-between">
                        <div className="!w-[calc(100%-90px)]">
                          <Input
                            type="text"
                            placeholder="Search Products..."
                            className="!m-0 rounded-lg font-normal"
                            value={selectionSearch}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                              setSelectionSearch(e.target.value);
                            }}
                          />
                        </div>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setOpenProductSelection(true)}
                          disabled={isDisabled}
                        >
                          Browse
                        </Button>
                      </div>
                      <hr />
                      <div className="h-[calc(80vh-105px)] overflow-y-auto scroll-smooth">
                        {addLineItemLoading && <LineItemSkeleton />}
                        {(loading || startOrderEditSessionLoading) &&
                          [0, 1, 2].map((index) => <LineItemSkeleton key={index} />)}
                        {!isDisabled &&
                          calculatedOrder?.calculated_line_items?.every((item) => (item?.quantity || 0) === 0) && (
                            <div className="w-full h-[80%] flex items-center justify-center">
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => setOpenProductSelection(true)}
                                disabled={isDisabled}
                              >
                                <Unicons.UilPlus size={18} className="mr-2" />
                                Add products
                              </Button>
                            </div>
                          )}

                        {calculatedOrder?.calculated_line_items
                          ?.filter((each) => (each?.quantity || 0) > 0)
                          ?.map((data) => (
                            <LineItem
                              loading={isDisabled}
                              data={data as CalculateLineItem}
                              handleLineItemDiscount={handleLineItemDiscount}
                              handleEditLineItemQuantity={handleEditLineItemQuantity}
                              handleDeleteLineItem={handleDeleteLineItem}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-[40%] p-4">
                    <div className="border-[1px] rounded-xl mb-4">
                      <div className="text-black font-semibold px-4 py-2">Summary</div>
                      <hr />
                      <div className="px-4 py-4">
                        {isDisabled ? (
                          [0, 1, 2, 3].map((index) => (
                            <div key={`${index}_summary`} className="flex items-center justify-between pb-2">
                              <Skeleton size="md" className="w-32" />
                              <Skeleton size="md" />
                            </div>
                          ))
                        ) : (
                          <OrderSummary
                            calculatedOrderId={calculatedOrderId}
                            calculatedOrder={calculatedOrder as CalculatedOrder}
                            refetch={refetchOrderEditSession}
                          />
                        )}
                      </div>
                    </div>
                    <div className="border-[1px] rounded-xl mb-4">
                      <div className="text-black font-semibold px-4 py-2">Reason for edit</div>
                      <hr />
                      <div className="p-3">
                        <Input
                          type="text"
                          name="update_reason"
                          className="!m-0 rounded-md text-black"
                          value={editReason}
                          onChange={(e) => setEditReason(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex items-center gap-2 mb-0.5">
                        <Checkbox
                          id="send-invoice"
                          checked={sendInvoice}
                          onChange={() => setSendInvoice(!sendInvoice)}
                        />
                        <label className="text-black" htmlFor="send-invoice">
                          Send invoice to customer
                        </label>
                      </div>
                      {!sendInvoice && (
                        <div className="text-[rgb(89,95,116)] leading-4 pl-6 text-xs">
                          Your customer won’t be notified of these changes.
                        </div>
                      )}
                    </div>
                    <Button
                      variant="default"
                      size="md"
                      onClick={handleEndSessionBySaving}
                      disabled={isDisabled || isEqual(initialCalculatedOrder, calculatedOrder)}
                      className={`w-full max-w-full rounded-lg ${endOrderEditSessionLoading && '!bg-primary'}`}
                    >
                      {endOrderEditSessionLoading ? (
                        <LoadingIcon height={20} className={'animate-spin text-white'} />
                      ) : (
                        'Update Order'
                      )}
                    </Button>
                  </div>
                </div>
              </AlertDialogDescription>
            </AlertDialogHeader>
          </div>
        </AlertDialogContent>
      </AlertDialog>

      <ApplyDiscount
        refetch={refetchOrderEditSession}
        calculatedOrderId={calculatedOrderId}
        lineItem={discountLineItem}
        setDiscountLineItem={setDiscountLineItem}
        openApplyDiscount={openApplyDiscount}
        setOpenApplyDiscount={setOpenApplyDiscount}
      />

      <OrderEditProductSelectionDialog
        key="OrderEditProductSelection"
        search={selectionSearch}
        open={openProductSelection}
        setOpen={(val: boolean) => {
          setSelectionSearch('');
          setOpenProductSelection(val);
        }}
        preSelections={(calculatedOrder?.calculated_line_items || []) as CalculateLineItem[]}
        onSave={(value: Array<number>) => {
          setSelectionSearch('');
          handleAddVariantToOrder(value);
        }}
      />

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={() => setOpen(false)}
        headerText="Discard changes"
        text="This action will discard changes. Are you sure you want to continue?"
        confirmButtonText="Discard"
        confirmActionVariant="destructive"
        loading={false}
      />
    </>
  );
};

import React from 'react';
import { PaymentState } from '../../../graphql';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  text: any;
};

export const PaymentStatus: React.FC<Props> = ({ text }) => {
  switch (text) {
    case PaymentState.Pending:
      return (
        <div className="flex gap-0.5 items-center text-[#736000] bg-[#FFEDBD] rounded-2xl py-[3px] pl-1 pr-2.5 font-medium">
          <Unicons.UilClock className="h-4" />
          <div className="text-xs">Pending</div>
        </div>
      );
    case PaymentState.Refunded:
      return (
        <div className="flex gap-0.5 items-center text-[#595F74] bg-[#E7E7E7] rounded-2xl py-[3px] pl-1 pr-2.5 font-medium">
          <Unicons.UilCornerUpLeft className="h-4" />
          <div className="text-xs">Refunded</div>
        </div>
      );
    case PaymentState.PartiallyPaid:
      return (
        <div className="flex gap-0.5 items-center text-[#4f4700] bg-[#ffd6a4] rounded-2xl py-[3px] pl-1 pr-2.5 font-medium">
          <Unicons.UilAdjustHalf className="h-4" />
          <div className="text-xs">Partially paid</div>
        </div>
      );
    case PaymentState.Completed:
      return (
        <div className="flex gap-0.5 items-center text-[#007305] bg-[#C7FFE0] rounded-2xl py-[3px] pl-1 pr-2.5 font-medium">
          <Unicons.UilCheck className="h-4" />
          <div className="text-xs">Paid</div>
        </div>
      );

    default:
      return <></>;
  }
};

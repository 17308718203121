import {
  SortBy,
  SortOrder,
  CouponType,
  CouponOperation,
  ApplicationType,
  useGetCouponsLazyQuery,
  useBulkUpdateCouponMutation
} from '../../graphql';
import debounce from 'debounce';
import { enqueueSnackbar } from 'notistack';
import { ColumnDef } from '@tanstack/react-table';
import * as Unicons from '@iconscout/react-unicons';
import { CouponFilterOptions } from '../../constants/coupons';
import { formatIndianRupees } from '../../utils/format-currency';
import { Title } from '../../components/shared/dashboard-title/title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DiscountEvent, EventCategory, trackEvents } from '../../analytics';
import { Switch, Button, Checkbox, DataTable } from '../../components/flexyui';
import { CouponChip } from '../../components/coupons/coupon-chip/coupon-chip';
import { DiscountBxgyDialog } from '../../components/discounts/discount-bxgy-dialog';
import { CouponFilters } from '../../components/coupons/coupon-filters/coupon-filters';
import { DataTableColumnHeader } from '../../components/flexyui/DataTable/column-header';
import { DiscountAmountDialog } from '../../components/discounts/discount-amount-dialog';
import { DiscountFreebieDialog } from '../../components/discounts/discount-freebie-dialog';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { AddCouponDialog } from '../../components/coupons/add-coupon-dialog/add-coupon-dialog';
import { CreateDiscountPreview } from '../../components/coupons/coupons-preview/coupons-preview';
import { DiscountPurchaseDrivenDialog } from '../../components/discounts/discount-purchase-driven-dialog';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '../../components/flexyui/Drawer';

export const Coupons = () => {
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'code',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader className="" column={column} title="Code" />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3 flex items-center font-semibold" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.code} <CouponChip data={row} />
          </div>
        );
      }
    },
    {
      accessorKey: 'orders',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Orders" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div className="text-right py-1.5 px-3" onClick={() => onRowClickHandler(row?.original)}>
            <span className="invisible">Orders</span>
            {row.original.orders}
          </div>
        );
      }
    },
    {
      accessorKey: 'gross-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Gross Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex py-1.5 px-3 justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            <span className="invisible">Gross Sale</span>
            {row.original['gross-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'discount-amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Discount Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-1.5 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            <span className="invisible">Discount Amount</span>
            {row.original['discount-amount']}
          </div>
        );
      }
    },
    {
      accessorKey: 'net-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Net Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit py-1.5 px-3 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            <span className="invisible">Net sales</span>
            {row.original['net-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'visibility',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Visibility" className="flex justify-center" />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex py-1.5 px-8 justify-center items-center">
            {row.original.row?.application_type !== ApplicationType.Automatic ? (
              <Checkbox
                disabled={row.original.row?.application_type === ApplicationType.Automatic}
                checked={
                  row.original.row?.application_type === ApplicationType.Automatic ? false : row.original.discoverable
                }
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                onChange={async () => {
                  updateColumnData(row.original.id, 'visibility');
                  await handleBulkUpdate(
                    row.original.discoverable ? CouponOperation.NonDiscoverable : CouponOperation.Discoverable,
                    row.original
                  );
                }}
              />
            ) : (
              <> - </>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: 'active',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="flex py-1.5 px-4 justify-center items-center">
            <Switch
              checked={row.original.active}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onCheckedChange={async () => {
                updateColumnData(row.original.id, 'status');
                await handleBulkUpdate(
                  row.original.active ? CouponOperation.Deactivate : CouponOperation.Activate,
                  row.original
                );
              }}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1 px-3">
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setCouponToEdit(row?.original.row);
                openDiscountDialog(row?.original.row.coupon_type, 'edit');
              }}
              className="mr-2"
            >
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74] cursor-pointer" />
            </Button>
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setCouponToDelete(row?.original);
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const [couponToAdd, setCouponToAdd] = useState<boolean>(false);
  const [couponToEdit, setCouponToEdit] = useState<any>(null);
  const [couponToDelete, setCouponToDelete] = useState<any>(null);

  const [showAmountDiscount, setShowAmountDiscount] = useState<boolean>(false);
  const [showBxgyDiscount, setShowBxgyDiscount] = useState<boolean>(false);
  const [showPurchaseDrivenDiscount, setShowPurchaseDrivenDiscount] = useState<boolean>(false);
  const [showFreebieDiscount, setShowFreebieDiscount] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [rows, setRows] = useState<any>(null);

  const [activeFilters, setActiveFilters] = useState<Array<{ filter: string; open: boolean }>>([]);
  const [filterOptions, setFilterOptions] = useState<CouponFilterOptions>({
    code: '',
    couponTypes: [],
    applicationType: [],
    status: [],
    platform: [],
    combines: [],
    sortBy: SortBy.CreatedAt,
    sortOrder: SortOrder.Desc
  });

  const resetSelectionRef = useRef<(() => void) | null>(null);

  const [getCoupons, { data: couponData, loading, refetch }] = useGetCouponsLazyQuery();

  const refetchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const rowData = couponData?.getCoupons.data.map((row) => {
      return {
        id: row.id,
        active: row.active,
        discoverable: row.discoverable,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        row
      };
    });
    setRows(rowData);
  }, [couponData]);

  const updateColumnData = (rowId: string, handler: 'status' | 'visibility') => {
    setRows((prevRows: any) =>
      prevRows.map((row: any) => {
        if (row.id === rowId) {
          if (handler === 'status') {
            return {
              ...row,
              active: !row.active
            };
          } else if (handler === 'visibility') {
            return {
              ...row,
              discoverable: !row.discoverable
            };
          }
        }
        return row;
      })
    );
  };

  const [bulkDiscountUpdate, { loading: bulkLoading }] = useBulkUpdateCouponMutation();

  const getNetSale = (sales: number) => {
    return formatIndianRupees(Number(sales.toFixed(2) || '0'));
  };
  const getDiscountAmount = (discount: number) => {
    return formatIndianRupees(Number(discount.toFixed(2) || '0'));
  };
  const getGrossSales = (sale: number) => {
    return formatIndianRupees(Number(sale.toFixed(2) || '0'));
  };
  const getOrdersCount = (count: number) => {
    return count || '0';
  };

  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setShowDetailsModal(true);
      setCouponToEdit(item?.row);
      trackEvents(EventCategory.COUPONS, DiscountEvent.VIEW_DISCOUNT_DETAILS);
    }
  };

  const handleConfirm = async () => {
    await handleBulkUpdate(CouponOperation.Delete, couponToDelete);
    setShowConfirmationDialog(false);
    setCouponToDelete(null);
  };

  const handleChangeRowsPerPageByValue = (value: any) => {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  };

  const handleBulkUpdate = async (operation: CouponOperation, coupons: any | any[]) => {
    const couponIds = Array.isArray(coupons) ? coupons.map((coupon) => coupon.id) : [coupons.id];

    if (!couponIds || couponIds.length === 0) return;

    if (refetchTimeout.current) clearTimeout(refetchTimeout.current);

    try {
      await bulkDiscountUpdate({
        variables: {
          couponIds,
          operation
        }
      });
      enqueueSnackbar('Hurray! Coupons updated successfully!', {
        variant: 'success'
      });

      if (couponIds.length > 1) await refetch();
      else
        refetchTimeout.current = setTimeout(() => {
          refetch();
          refetchTimeout.current = null;
        }, 1500);

      if (operation === CouponOperation.Activate || operation === CouponOperation.Deactivate)
        trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_STATUS_CHANGED, { count: couponIds.length });
      else if (operation === CouponOperation.Discoverable || operation === CouponOperation.NonDiscoverable)
        trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_VISIBILITY_CHANGED, { count: couponIds.length });
      else if (operation === CouponOperation.Delete)
        trackEvents(EventCategory.COUPONS, DiscountEvent.REMOVE_DISCOUNT, { count: couponIds.length });
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    if (resetSelectionRef.current) resetSelectionRef.current();
  };

  const handleSearch = (searchParams: CouponFilterOptions, skip: number, limit: number) => {
    getCoupons({
      variables: {
        skip,
        limit,
        code: searchParams.code || '',
        couponTypes: searchParams.couponTypes,
        applicationType: searchParams.applicationType,
        status: searchParams.status,
        platform: searchParams.platform,
        combines: searchParams.combines,
        sortBy: searchParams.sortBy,
        sortOrder: searchParams.sortOrder
      }
    });

    trackEvents(EventCategory.COUPONS, DiscountEvent.FILTERED_SEARCH, {
      code: searchParams.code || '',
      couponTypes: searchParams.couponTypes,
      applicationType: searchParams.applicationType,
      status: searchParams.status,
      platform: searchParams.platform,
      combines: searchParams.combines,
      sortBy: searchParams.sortBy,
      sortOrder: searchParams.sortOrder
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    setPage(0);
    debouncedSearch(filterOptions, 0, rowPerPage);
  }, [filterOptions]);

  useEffect(() => {
    debouncedSearch(filterOptions, rowPerPage * page, rowPerPage);
  }, [rowPerPage, page]);

  const openDiscountDialog = (couponType: CouponType, action: 'add' | 'edit') => {
    switch (couponType) {
      case CouponType.Bxgy:
        setShowBxgyDiscount(true);
        break;
      case CouponType.Amount:
      case CouponType.CartAmount:
      case CouponType.ProductAmount:
        setShowAmountDiscount(true);
        break;
      case CouponType.PurchaseHistory:
        setShowPurchaseDrivenDiscount(true);
        break;
      case CouponType.AddOn:
        setShowFreebieDiscount(true);
        break;
      default:
        break;
    }
    if (action === 'edit')
      trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_EDITING_STARTED, {
        type: couponType
      });
    if (action === 'add')
      trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_ADDITION_STARTED, {
        type: couponType
      });
  };

  return (
    <>
      <div className="mx-4">
        <Title title="Coupons">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setCouponToEdit(null);
              setCouponToAdd(true);
              trackEvents(EventCategory.COUPONS, DiscountEvent.ADD_DISCOUNT_CLICKED);
            }}
            disabled={loading}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Coupon
          </Button>
        </Title>

        <div className="mt-2">
          <DataTable
            columnData={columns}
            data={rows}
            showFilterInput={true}
            showPagination={true}
            onRowClick={onRowClickHandler}
            enableRowSelection={true}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={couponData?.getCoupons?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
            onBulkUpdate={handleBulkUpdate}
            bulkLoading={bulkLoading}
            resetSelectionRef={resetSelectionRef}
            filterContent={
              <CouponFilters
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
              />
            }
          />
        </div>
      </div>

      <AddCouponDialog open={couponToAdd} setOpen={setCouponToAdd} openDiscountDialog={openDiscountDialog} />

      {showAmountDiscount && (
        <DiscountAmountDialog
          open={showAmountDiscount}
          setOpen={setShowAmountDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}
      {showBxgyDiscount && (
        <DiscountBxgyDialog
          open={showBxgyDiscount}
          setOpen={setShowBxgyDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}
      {showPurchaseDrivenDiscount && (
        <DiscountPurchaseDrivenDialog
          open={showPurchaseDrivenDiscount}
          setOpen={setShowPurchaseDrivenDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}
      {showFreebieDiscount && (
        <DiscountFreebieDialog
          open={showFreebieDiscount}
          setOpen={setShowFreebieDiscount}
          couponDetails={couponToEdit}
          setCouponDetails={setCouponToEdit}
          refetch={refetch}
        />
      )}

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleConfirm}
        headerText="Delete Coupon?"
        text="This action will delete the selected coupon."
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
      />

      <Drawer direction="right" open={showDetailsModal} onOpenChange={(value: boolean) => setShowDetailsModal(value)}>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between items-center mb-4">
              <DrawerTitle>Coupon Details</DrawerTitle>
              <Button size="icon" variant="icon" onClick={() => setShowDetailsModal(false)}>
                <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
              </Button>
            </div>
            <DrawerDescription className="text-black text-left">
              <CreateDiscountPreview
                activeStep={4}
                selectedCoupon={couponToEdit}
                isEdit={showAmountDiscount || showBxgyDiscount || showPurchaseDrivenDiscount}
              />
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </>
  );
};

import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AppDispatch } from '../../../store';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import { setConfirmationDialog, setRouteLink, setSidebarState } from '../../../store/main-slice';

type Props = {
  routeLink: any;
  text: string;
  icon?: any;
};

//

export const SidebarLink: React.FC<Props> = ({ routeLink, text, icon }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showActions } = useSaveCancelButtons();
  return (
    <NavLink
      data-testid={`sidebar-${routeLink}`}
      to={`${routeLink}`}
      onClick={(e) => {
        dispatch(setRouteLink(`${routeLink}`));
        if (showActions) {
          e.preventDefault();
          dispatch(setConfirmationDialog(true));
          history.pushState(null, document.title, routeLink);
        }
        dispatch(setSidebarState(false));
      }}
    >
      {({ isActive }) => (
        <div
          className={classNames('p-2 rounded-lg my-0.5 transition-all ease-linear', {
            'bg-primary text-white font-semibold': isActive,
            'text-[rgba(18,27,56,0.5)] font-medium hover:bg-slate-100': !isActive
          })}
        >
          <div className="flex items-center gap-2.5 h-[21px]">
            <div className="h-4 px-0.5">{icon}</div>
            <span
              className={classNames('transition-[opacity,transform] ease-in-out transform min-w-[150px]', {
                'opacity-0 scale-95 translate-y-2': !text,
                'opacity-100 scale-100 translate-y-0': text
              })}
            >
              {text}
            </span>
          </div>
        </div>
      )}
    </NavLink>
  );
};

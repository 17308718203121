import { OrderQuery } from '../../../graphql';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { formatPrice } from '../../../utils/format-price';
import { Button, Card, Label, Separator, Skeleton } from '../../flexyui';
import { FulfilledStatus } from '../fulfillment-status/fulfillment-status';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { RootState } from '@/src/store';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
  basePath: string;
};

export const OrderItems: React.FC<Props> = ({ data, loading, basePath }) => {
  const [cartData, setCartData] = useState<any>([]);
  const storeUrl = useSelector((state: RootState) => state.main.storeUrl);

  useEffect(() => {
    if (!data?.ordered_products) return;
    const orderItemList: any = [];
    const products: any = {};

    data.ordered_products.forEach((item) => {
      item?.groups?.forEach((group) => {
        const orderItem = {
          ...item,
          groups: [group]
        };
        orderItemList.push(orderItem);
      });
    });

    orderItemList.forEach((item: any) => {
      const discounts = item.groups?.[0]?.line_price.discounts || [];
      const itemKey = `${item.variant_id}-${discounts.length > 0 ? discounts[0].code : 'NoDiscount'}-${
        item.groups?.[0]?.group_element_type ? item.groups[0].group_element_type : 'NoGroupType'
      }`;
      if (!products[itemKey]) {
        products[itemKey] = {
          ...item,
          groups: [
            {
              ...item.groups?.[0],
              line_price: {
                ...item.groups?.[0]?.line_price,
                sub_total: item.groups?.[0]?.line_price.sub_total,
                total: item.groups?.[0]?.line_price.total
              }
            }
          ]
        };
      } else {
        products[itemKey].groups.forEach((group: any) => {
          group.quantity += item.groups?.[0]?.quantity || 0;
          group.line_price.sub_total += item.groups?.[0]?.line_price?.sub_total || 0;
          group.line_price.total += item.groups?.[0]?.line_price?.total || 0;
        });
      }
    });

    const cartList = Object.values(products);
    setCartData(cartList);
  }, [data]);

  return (
    <Card className="p-4 col-span-2 mb-4">
      <div className={`flex items-center gap-3 mb-4 ${basePath === '/cart' && 'justify-between'}`}>
        <Label size="md" className="text-base">
          {basePath === '/orders' ? 'Order' : 'Checkout'} Items
        </Label>
        {loading ? (
          <Skeleton size="label" />
        ) : basePath === '/orders' ? (
          <FulfilledStatus text={data?.platform_fulfillment_status} />
        ) : (
          storeUrl && (
            <Button
              variant="outline"
              size="sm"
              className="px-3 h-7"
              onClick={(e: any) => {
                e.stopPropagation();
                navigator.clipboard.writeText(`https://${storeUrl}?flx_s=${data?.id || ''}`);
                enqueueSnackbar('Copied to clipboard', {
                  variant: 'success'
                });
              }}
            >
              <Unicons.UilCopy size="16" className="mr-1.5" />
              Copy checkout url
            </Button>
          )
        )}
      </div>

      {loading
        ? [0, 1].map(() => (
            <div className="py-2 border-b-[1px] last:border-b-0">
              <div className="flex flex-col md:flex-row justify-between gap-2">
                <div className="flex justify-between w-full md:w-[70%]">
                  <div className="flex gap-2">
                    <div className="w-[50px] flex justify-center">
                      <Skeleton size="xl" className="h-11" />
                    </div>
                    <div>
                      <Skeleton size="label" className="w-40" />
                      <Skeleton size="md" className="mt-1.5" />
                    </div>
                  </div>
                  <Skeleton size="label" className="hidden md:block" />
                </div>
                <div className="flex flex-row-reverse gap-4 w-full md:w-[30%]">
                  <Skeleton size="label" />
                  <Skeleton size="label" />
                </div>
              </div>
              <Skeleton size="md" className="mt-2 w-32 hidden md:block" />
            </div>
          ))
        : cartData?.map((item: any) => (
            <>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="flex gap-2">
                  <div className="w-[48px] min-w-[48px] h-fit flex justify-center">
                    {item?.image ? (
                      <img src={item.image} className="h-[48px] rounded-md" />
                    ) : (
                      <div className="border rounded-lg p-2 h-fit">
                        <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between gap-2 flex-1">
                      <div className="font-medium">{item?.title}</div>
                      <div className="w-[90px] block md:hidden font-medium text-right">
                        ₹{formatPrice(item?.groups?.[0]?.line_price?.total)}
                      </div>
                    </div>

                    {!item?.single_variant && (
                      <div className="bg-gray-300 py-0.5 px-2 rounded-lg w-fit text-xs mt-0.5">
                        {item?.variant?.title}
                      </div>
                    )}
                    {item?.groups?.[0]?.line_price?.discounts?.length > 0 && (
                      <div className="flex flex-wrap items-center text-gray-500 opacity-70 mt-1">
                        {item.groups[0].line_price.discounts.map((discount: any) => (
                          <>
                            <div className="flex items-center gap-1">
                              <Unicons.UilTagAlt size={16} />
                              <div>
                                {discount?.code} (-₹{formatPrice(discount?.discount_amount || 0)})
                              </div>
                            </div>
                            <div className="mx-1.5 last:hidden">|</div>
                          </>
                        ))}
                      </div>
                    )}
                    {item.properties && (
                      <ul className="mt-1 text-xs font-normal text-gray-500">
                        {Object.entries(item.properties)
                          .filter(([key, value]) => value && !key.startsWith('_'))
                          .map(([key, value]) => (
                            <li key={key}>
                              <>
                                <span>{key}:</span> {value}
                              </>
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="flex gap-1 md:gap-2 justify-start md:justify-end text-right ml-14 mt-1 md:m-0">
                  {item?.groups?.[0]?.line_price?.total_discount > 0 && (
                    <div className="text-gray-500 opacity-70 line-through mr-2">
                      ₹{formatPrice(item?.groups?.[0]?.line_price?.sub_total / item.groups?.[0]?.quantity)}
                    </div>
                  )}
                  <div className="w-auto md:w-[100px]">
                    ₹{formatPrice(item?.groups?.[0]?.line_price?.total / item.groups?.[0]?.quantity)} x{' '}
                    {item.groups?.[0]?.quantity}
                  </div>
                  <div className="w-[90px] hidden md:block font-medium">
                    ₹{formatPrice(item?.groups?.[0]?.line_price?.total)}
                  </div>
                </div>
              </div>
              <Separator className="my-3 last:hidden" />
            </>
          ))}
    </Card>
  );
};

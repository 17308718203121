import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Skeleton
} from '../../../flexyui';
import {
  DashboardUserType,
  useAddMerchantPocMutation,
  useDeleteMerchantPocMutation,
  useGetMerchantPoCsQuery,
  useUpdateMerchantPocMutation
} from '../../../../graphql';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { CustomAvatar } from '../../../shared/custom-avatar';
import { MerchantPoc } from '../../../../utils/validation-schemas/settings';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/loading.svg';
import { EventCategory, StoreDetailsEvent, trackEvents } from '../../../../analytics';

export const Poc = () => {
  const userType = useSelector((state: RootState) => state.main.userType);

  const [pocToEdit, setPocToEdit] = useState<any>(null);
  const [pocToDelete, setPocToDelete] = useState<string | null>(null);
  const [openPocDialog, setOpenPocDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const { data, loading, refetch } = useGetMerchantPoCsQuery();
  const [addPoc] = useAddMerchantPocMutation();
  const [updatePoc] = useUpdateMerchantPocMutation();
  const [deletePoc] = useDeleteMerchantPocMutation();

  const handleSave = async (values: any) => {
    try {
      if (pocToEdit) {
        await updatePoc({
          variables: {
            pocId: pocToEdit.id,
            data: values
          }
        });
        trackEvents(EventCategory.STORE_DETAILS, StoreDetailsEvent.EDIT_POC);
      } else {
        await addPoc({
          variables: {
            data: values
          }
        });
        trackEvents(EventCategory.STORE_DETAILS, StoreDetailsEvent.ADD_POC);
      }

      enqueueSnackbar(`Contact has been ${pocToEdit ? 'updated' : 'added'} successfully!`, {
        variant: 'success'
      });

      await refetch();
    } catch {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setPocToEdit(null);
      setOpenPocDialog(false);
    }
  };

  const handleDeleteUser = async () => {
    if (!pocToDelete) return;

    try {
      await deletePoc({
        variables: {
          pocId: pocToDelete
        }
      });

      enqueueSnackbar('Contact has been deleted successfully!', {
        variant: 'success'
      });

      await refetch();
      trackEvents(EventCategory.STORE_DETAILS, StoreDetailsEvent.REMOVE_POC);
    } catch {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setPocToDelete(null);
      setShowConfirmationDialog(false);
    }
  };

  const initialValues = useMemo(() => {
    return {
      phone: {
        number: pocToEdit ? pocToEdit?.phone?.number : '',
        dial_code: 91
      },
      name: pocToEdit ? pocToEdit?.name : '',
      email: pocToEdit ? pocToEdit?.email : '',
      designation: pocToEdit ? pocToEdit?.designation : ''
    };
  }, [pocToEdit]);

  return (
    <>
      <Card className="mb-4 p-4">
        <div className="flex items-start sm:items-center justify-between mb-4">
          <div>
            <div className="font-medium">Finance Point of Contact for Billing</div>
            <div className="text-gray-400 text-xs">The individuals managing all billing and financial matters.</div>
          </div>
          {data && data.getMerchantPOCs.length > 0 && data.getMerchantPOCs.length <= 3 && (
            <Button
              variant="primary"
              size="sm"
              className="flex items-center"
              disabled={userType === DashboardUserType.Staff}
              onClick={() => {
                setPocToEdit(null);
                setOpenPocDialog(true);
              }}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              <div>
                Add <span className="hidden sm:inline-block">contact</span>
              </div>
            </Button>
          )}
        </div>

        {loading && (
          <div className="border border-muted px-4 py-4 rounded-lg mb-3 last:mb-0">
            <div className="flex items-center justify-between">
              <Skeleton className="w-[150px] !m-0" />
              <div className="flex items-center gap-2">
                <Skeleton className="!m-0 h-8 w-8" />
                <Skeleton className="!m-0 h-8 w-8" />
              </div>
            </div>
            <div className="mt-4">
              <Skeleton className="w-full !m-0 !mb-2" />
              <Skeleton className="w-[70%] sm:w-[250px] !m-0" />
            </div>
          </div>
        )}

        {data &&
          data.getMerchantPOCs.map((contact: any) => (
            <div className="text-xs border border-muted px-4 py-4 rounded-lg mb-3 last:mb-0">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <CustomAvatar name={contact.name} />
                  <div>
                    <div className="text-sm font-semibold">{contact.name}</div>
                    {contact?.designation && <div>{contact.designation}</div>}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    size="icon"
                    variant="icon"
                    onClick={() => {
                      setPocToEdit(contact);
                      setOpenPocDialog(true);
                    }}
                    disabled={userType === DashboardUserType.Staff}
                  >
                    <Unicons.UilEdit size={'22'} className="p-[2px]" />
                  </Button>
                  <Button
                    size="icon"
                    variant="icon"
                    disabled={userType === DashboardUserType.Staff}
                    onClick={() => {
                      setPocToDelete(contact.id);
                      setShowConfirmationDialog(true);
                    }}
                    className="hover:text-red-500"
                  >
                    <Unicons.UilTrashAlt size="22" className="p-[2px]" />
                  </Button>
                </div>
              </div>
              <div className="flex items-center gap-1 mt-3 mb-1">
                <div className="text-gray-500">Email:</div>
                <div>{contact?.email ? contact.email : '-'}</div>
              </div>
              <div className="flex items-center gap-1">
                <div className="text-gray-500">Phone:</div>
                <div>{contact?.phone?.number ? `+91${contact.phone.number}` : ''}</div>
              </div>
            </div>
          ))}

        {data && data.getMerchantPOCs.length === 0 && (
          <div className="border border-muted px-4 py-4 mt-2 rounded-lg">
            <div className="text-center my-4">
              <Button
                variant="primary"
                size="sm"
                disabled={userType === DashboardUserType.Staff}
                onClick={() => {
                  setPocToEdit(null);
                  setOpenPocDialog(true);
                }}
              >
                <Unicons.UilPlus size={18} className="mr-2" />
                Add contact
              </Button>
            </div>
          </div>
        )}

        {!data?.getMerchantPOCs && !loading && (
          <div className="text-center my-8">
            <div className="text-gray-300 text-base">Not Available</div>
          </div>
        )}
      </Card>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteUser}
        text="This action will delete the contact."
      />

      <Formik<{
        phone: {
          number: number;
          dial_code: number;
        };
        name: string;
        email: string;
        designation: string;
      }>
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={true}
        validateOnMount={true}
        validateOnBlur={true}
        validationSchema={MerchantPoc}
        onSubmit={handleSave}
      >
        {({ errors, touched, getFieldProps, submitForm, resetForm, isSubmitting }) => {
          return (
            <Dialog open={openPocDialog} onOpenChange={(value) => setOpenPocDialog(value)}>
              <DialogContent className="!gap-0" size="sm" close={true}>
                <div>
                  <DialogHeader>
                    <DialogTitle>
                      <p>{pocToEdit ? 'Edit' : 'Add'} contact</p>
                    </DialogTitle>
                    <DialogDescription className="!p-0 !m-0">
                      <div className="p-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <Label size="paragraph" className="required-field">
                              Name
                            </Label>
                            <Input
                              {...getFieldProps('name')}
                              error={touched.name && !!errors.name}
                              errorMessage={errors.name}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <Label size="paragraph">Designation</Label>
                            <Input
                              {...getFieldProps('designation')}
                              error={touched.designation && !!errors.designation}
                              errorMessage={errors.designation}
                              className="mt-1"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mt-3">
                          <div>
                            <Label size="paragraph" className="required-field">
                              Email
                            </Label>
                            <Input
                              {...getFieldProps('email')}
                              error={touched.email && !!errors.email}
                              errorMessage={errors.email}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <Label size="paragraph" className="required-field">
                              Phone
                            </Label>
                            <Input
                              {...getFieldProps('phone.number')}
                              type="number"
                              error={touched.phone?.number && !!errors.phone?.number}
                              errorMessage={errors.phone?.number}
                              className="mt-1"
                            />
                          </div>
                        </div>
                      </div>
                    </DialogDescription>
                  </DialogHeader>
                </div>
                <DialogFooter>
                  <Button variant="outline" size="md" onClick={() => setOpenPocDialog(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="default"
                    size="md"
                    disabled={Object.keys(errors).length > 0 || isSubmitting}
                    onClick={async () => {
                      await submitForm();
                      resetForm();
                    }}
                  >
                    {isSubmitting ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Add'}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

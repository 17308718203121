/* eslint-disable camelcase */
import {
  GoogleAnalyticsConversionAction,
  useDeleteGoogleAnalyticsMutation,
  useUpdateGoogleAnalyticsMutation
} from '../../../../../graphql';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Chip, Label } from '../../../../flexyui';
import { EventCategory, IntegrationEvent, trackEvents } from '../../../../../analytics';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';

type Props = {
  conversion: GoogleAnalyticsConversionAction;
  handleEditClick: (value: GoogleAnalyticsConversionAction) => void;
  handleGoogleAnalyticsConversionStatus: () => void;
  refetch: () => void;
};

export const GoogleAnalyticsCard: React.FC<Props> = ({
  conversion,
  handleEditClick,
  handleGoogleAnalyticsConversionStatus,
  refetch
}) => {
  const [updateConversion] = useUpdateGoogleAnalyticsMutation();
  const [deleteConversion, { loading: deleteLoading }] = useDeleteGoogleAnalyticsMutation();

  const [conversionStatus, setConversionStatus] = useState<boolean>(conversion.enabled);
  const [conversionToDelete, setConversionToDelete] = useState<GoogleAnalyticsConversionAction | null>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  useEffect(() => {
    if (conversion) setConversionStatus(conversion.enabled);
  }, [conversion]);

  const handleConversionStatusUpdate = async (value: boolean) => {
    setConversionStatus(value);

    const { id, ...rest } = conversion;

    try {
      await updateConversion({
        variables: {
          configs: {
            ...rest,
            enabled: value
          },
          updateGoogleAnalyticsId: conversion.id
        }
      });
      enqueueSnackbar(`Conversion has been ${value ? 'enabled' : 'disabled'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });

      setConversionStatus(conversion.enabled);
    }
  };

  const handleDeleteConversion = async () => {
    if (!conversionToDelete) return;

    try {
      handleGoogleAnalyticsConversionStatus();
      await deleteConversion({
        variables: {
          deleteGoogleAnalyticsId: conversionToDelete.id
        }
      });

      enqueueSnackbar('Conversion has been deleted successfully!', {
        variant: 'success'
      });

      refetch();
      trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.REMOVE_GA);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setConversionToDelete(null);
      setShowConfirmationDialog(false);
    }
  };

  return (
    <>
      <div className="text-gray-400 text-xs border border-muted px-4 py-4 rounded-lg mb-3 last:mb-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox
              checked={conversionStatus}
              id={'conversion_enabled'}
              name={'conversionEnabled'}
              onChange={(e) => handleConversionStatusUpdate(e.target.checked)}
            />
            <div className="grid gap-[5px] leading-none ml-3">
              <Label htmlFor={'conversion_enabled'} size={'paragraph'} className="font-normal text-gray-500">
                Enable this google analytics
              </Label>
            </div>
          </div>
          <div className="flex items-center">
            <Button size="icon" variant="icon" onClick={() => handleEditClick(conversion)} className="mr-2">
              <Unicons.UilEdit size="22" className="p-[2px]" />
            </Button>
            <Button
              size="icon"
              variant="icon"
              onClick={() => {
                setConversionToDelete(conversion);
                setShowConfirmationDialog(true);
              }}
              className="hover:text-red-500"
            >
              <Unicons.UilTrashAlt size="22" className="p-[2px]" />
            </Button>
          </div>
        </div>
        <div className="mt-3 font-normal text-gray-500">Measurement ID</div>
        <div className="flex justify-start text-sm font-normal">
          {conversion?.measurement_id ? conversion?.measurement_id : '-'}
        </div>
        <div className="mt-3 font-normal text-gray-500 mr-2">API Secret</div>
        <div className="max-w-[80%] mt-0.5 text-sm font-normal break-all">
          {conversion?.api_secret ? conversion?.api_secret : '-'}
        </div>
      </div>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteConversion}
        headerText="Delete Conversion?"
        text="This action will delete the conversion"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleteLoading}
      />
    </>
  );
};

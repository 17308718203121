import React from 'react';
import { isEqual } from 'lodash';
import { OrderQuery } from '../../../graphql';
import { useNavigate } from 'react-router-dom';
import { Card, Label, Skeleton } from '../../flexyui';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
};

export const CustomerDetails: React.FC<Props> = ({ data, loading }) => {
  const navigate = useNavigate();

  return (
    <Card className="p-4 mb-4">
      <div className="mb-4">
        <Label size="md" className="text-base">
          Customer
        </Label>
      </div>
      <div className="mb-3 text-primary">
        {loading ? (
          <Skeleton size={'label'} className="w-[50%] h-4" />
        ) : (
          !!data?.customer?.first_name && (
            <div>
              {data?.customer?.first_name} {data?.customer?.last_name || ''}
            </div>
          )
        )}
        {loading ? (
          <Skeleton size={'label'} className="w-[50%] h-4 mt-1" />
        ) : (data?.customer?.order_count || 0) > 0 ? (
          <div
            className="hover:underline cursor-pointer"
            onClick={() => {
              if (data?.customer?.phone) navigate(`/orders?query=${data.customer.phone}&searchField=PHONE`);
            }}
          >
            {data?.customer?.order_count || 1} order{(data?.customer?.order_count || 0) > 1 && 's'}
          </div>
        ) : (
          <div className="text-gray-500">No data available</div>
        )}
      </div>
      <div className="mb-3">
        <div className="font-medium mb-1">Contact information</div>
        <div className="text-primary">
          {loading ? (
            <>
              <Skeleton size={'label'} className="w-[80%] h-4" />
              <Skeleton size={'label'} className="w-[50%] h-4 mt-1" />
            </>
          ) : data?.customer?.email || data?.customer?.phone ? (
            <>
              <div>{data?.customer?.email}</div>
              <div>
                {data?.customer?.dial_code && '+'}
                {data?.customer?.dial_code} {data?.customer?.phone}
              </div>
            </>
          ) : (
            <div className="text-gray-500">No data available</div>
          )}
        </div>
      </div>
      <div className="mb-3">
        <div className="font-medium mb-1">Shipping address</div>
        <div className="text-gray-500">
          {loading ? (
            <>
              <Skeleton size={'label'} className="w-[50%] h-4" />
              <Skeleton size={'label'} className="w-[80%] h-4 mt-1" />
              <Skeleton size={'label'} className="w-[50%] h-4 mt-1" />
              <Skeleton size={'label'} className="w-[50%] h-4 mt-1" />
            </>
          ) : data?.shipping_address?.address1 ? (
            <div className="flex flex-col">
              <div>
                {data?.shipping_address?.first_name} {data?.shipping_address?.last_name || ''}
              </div>
              <div>{data?.shipping_address?.address1}</div>
              <div>{data?.shipping_address?.address2 || ''}</div>
              <div className="flex">
                {data?.shipping_address?.city}
                {data?.shipping_address?.city && ','}&nbsp;
                {data?.shipping_address?.state} -&nbsp;
                {data?.shipping_address?.postal_code}
              </div>
              <div>{data?.shipping_address?.country}</div>
            </div>
          ) : (
            'No data available'
          )}
        </div>
      </div>
      <div>
        <div className="font-medium mb-1">Billing address</div>
        <div className="text-gray-500">
          {loading ? (
            <Skeleton size={'label'} className="w-[80%] h-4" />
          ) : data?.shipping_address?.address1 ? (
            isEqual(data?.shipping_address, data?.billing_address) ? (
              'Same as shipping address'
            ) : (
              <div className="flex flex-col">
                <div>
                  {data?.billing_address?.first_name} {data?.billing_address?.last_name || ''}
                </div>
                <div>{data?.billing_address?.address1}</div>
                <div>{data?.billing_address?.address2 || ''}</div>
                <div className="flex">
                  {data?.billing_address?.city}
                  {data?.billing_address?.city && ','}&nbsp;
                  {data?.billing_address?.state} -&nbsp;
                  {data?.billing_address?.postal_code}
                </div>
                <div>{data?.billing_address?.country}</div>
              </div>
            )
          ) : (
            'No data available'
          )}
        </div>
      </div>
    </Card>
  );
};

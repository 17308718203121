import React from 'react';
import classNames from 'classnames';
import { Button, Input } from '../../flexyui';
import { PriceDetails } from './price-details';
import * as Unicons from '@iconscout/react-unicons';
import { CalculateLineItem } from '../../../graphql';
import { formatPrice } from '../../../utils/format-price';

type Props = {
  data: CalculateLineItem;
  loading: boolean;
  handleLineItemDiscount: (data: CalculateLineItem) => void;
  handleEditLineItemQuantity: (lineItem: CalculateLineItem, functionality: string) => void;
  handleDeleteLineItem: (lineItem: CalculateLineItem) => void;
};

export const LineItem: React.FC<Props> = ({
  data,
  loading,
  handleLineItemDiscount,
  handleEditLineItemQuantity,
  handleDeleteLineItem
}) => {
  return (
    <div key={data.calculated_line_item_id} className="p-4 border-b-[1px] last:border-b-0">
      <div className="flex justify-between flex-col lg:flex-row">
        <div className="flex justify-between w-full lg:w-[70%]">
          <div className="flex gap-2">
            <div className="w-[48px] flex justify-center">
              {data?.image ? (
                <img src={data.image} className="h-[48px] rounded-md" />
              ) : (
                <div className="border rounded-lg p-2 h-fit">
                  <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold sm:max-w-[265px] xl:max-w-[320px] truncate">{data.title}</div>
              {!data.single_variant && (
                <div className="bg-gray-300 py-0.5 px-2 rounded-lg w-fit text-xs mt-0.5">{data.variant?.title}</div>
              )}
              <PriceDetails data={data as CalculateLineItem} className="" />
              {data.is_editable && (
                <div
                  className={classNames('text-primary text-sm cursor-pointer w-fit hover:underline', {
                    'pointer-events-none': loading
                  })}
                  onClick={() => handleLineItemDiscount(data)}
                >
                  {data.calculated_discount_application_id === null ? 'Apply discount' : 'Edit discount'}
                </div>
              )}
            </div>
          </div>
          <div className="text-black font-medium">
            {data.discount?.amount_after_discount !== null && Number(data.discount?.amount_after_discount) === 0
              ? 'Free'
              : `₹${formatPrice(Number(data.discount?.amount_after_discount ?? data.price) * Number(data.quantity))}`}
          </div>
        </div>
        <div className="w-full lg:w-[30%]">
          <div className="flex flex-row-reverse items-center gap-4">
            <Button
              size="icon"
              variant="icon"
              disabled={loading}
              className="px-2 py-[1.2rem] rounded-xl"
              onClick={() => handleDeleteLineItem(data)}
            >
              <Unicons.UilTrashAlt size={'26'} className="p-[2px] text-gray-500 cursor-pointer" />
            </Button>
            {data.is_editable ? (
              <div className="flex items-center justify-center px-2 rounded-xl border border-input h-fit">
                <Unicons.UilMinus
                  size={'20'}
                  className={`text-gray-500 cursor-pointer ${data.quantity === 1 || (loading && 'pointer-events-none')}`}
                  onClick={() => {
                    if (data.quantity !== 1 && !loading) handleEditLineItemQuantity(data, 'decrement');
                  }}
                />
                <Input
                  type="number"
                  name="quantity"
                  className="!m-0 !px-0 text-center w-[40px] border-0 text-black focus-visible:ring-0 focus-visible:ring-offset-0 !bg-white !cursor-auto"
                  value={data.quantity}
                  disabled
                />
                <Unicons.UilPlus
                  size={'20'}
                  className={`text-gray-500 cursor-pointer ${loading && 'pointer-events-none'}`}
                  onClick={() => {
                    if (!loading) handleEditLineItemQuantity(data, 'increment');
                  }}
                />
              </div>
            ) : (
              <Input
                type="number"
                name="quantity"
                className="!m-0 rounded-xl w-[70px] text-center text-black focus-visible:ring-0 bg-white !px-3"
                value={data.quantity}
                disabled
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { DropdownMenuItem } from '../../flexyui';
import { NotificationPriority, UrlTarget, useUpdateNotificationStatusMutation } from '../../../graphql';

type Props = {
  notificationDetails: any;
  refetch: () => void;
};

export const NotificationCard: React.FC<Props> = ({ notificationDetails, refetch }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isRead, setIsRead] = useState<boolean>(notificationDetails.is_read);

  const formattedDate = new Date(notificationDetails.activation_period.start)
    .toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true })
    .replace(',', ' at');

  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();

  useEffect(() => {
    if (notificationDetails) setIsRead(notificationDetails.is_read);
  }, [notificationDetails]);

  const handleUpdateNotificationStatus = async (value: boolean) => {
    setIsRead(value);

    try {
      await updateNotificationStatus({
        variables: {
          notificationIds: notificationDetails.id,
          isRead: value
        }
      });

      refetch();
    } catch (e) {
      setIsRead(notificationDetails.is_read);
    }
  };

  return (
    <DropdownMenuItem
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={isHovered ? 'bg-slate-100' : ''}
    >
      <Link
        to={notificationDetails.target?.url || ''}
        target={notificationDetails.target?.url_target === UrlTarget.Blank ? '_blank' : '_self'}
        className="w-full"
      >
        <div className="w-full px-1 py-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className={classNames('h-2 w-2 rounded-full mr-3', {
                  'bg-primary': notificationDetails?.priority === NotificationPriority.Low,
                  'bg-[#ffb800]': notificationDetails?.priority === NotificationPriority.Medium,
                  'bg-[#c70a24]': notificationDetails?.priority === NotificationPriority.High,
                  '!bg-transparent': isRead
                })}
              />

              <div className="text-xs text-gray-400">
                {notificationDetails.category.charAt(0).toUpperCase() +
                  notificationDetails.category.slice(1).toLowerCase()}{' '}
                {''}• {formattedDate}
              </div>
            </div>
            {isHovered && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateNotificationStatus(!notificationDetails.is_read);
                }}
                className="aspect-square h-3.5 min-w-3.5 rounded-full border-[1.9px] border-[#8a8a8a] text-[#8a8a8a] ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-center"
              >
                {isRead && <div className="h-2 w-2 rounded-full bg-[#8a8a8a]" />}
              </div>
            )}
          </div>
          <div className="ml-5 mt-1.5">
            <div className="font-semibold">{notificationDetails.title}</div>
            <div>{notificationDetails.description}</div>
          </div>
        </div>
      </Link>
    </DropdownMenuItem>
  );
};

/* eslint-disable camelcase */
import { enqueueSnackbar } from 'notistack';
import { ColumnDef } from '@tanstack/react-table';
import * as Unicons from '@iconscout/react-unicons';
import { formatIndianRupees } from '../../utils/format-currency';
import { Button, DataTable, Switch } from '../../components/flexyui';
import { Title } from '../../components/shared/dashboard-title/title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DiscountEvent, EventCategory, trackEvents } from '../../analytics';
import { DataTableColumnHeader } from '../../components/flexyui/DataTable/column-header';
import { DiscountPaymentDialog } from '../../components/discounts/discount-payment-dialog';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { CreateOfferPreview } from '../../components/payment-offers/offers-preview/offer-preview';
import { CouponOperation, CouponType, useBulkUpdateCouponMutation, useGetCouponsLazyQuery } from '../../graphql';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerDescription } from '../../components/flexyui/Drawer';

export const PaymentOffers = () => {
  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'code',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3 flex items-center font-semibold" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.code}{' '}
          </div>
        );
      }
    },
    {
      accessorKey: 'orders',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Orders" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="text-center py-1.5 px-3" onClick={() => onRowClickHandler(row?.original)}>
            {row.original.orders}
          </div>
        );
      }
    },
    {
      accessorKey: 'gross-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Gross Sales" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex justify-end px-3 py-1.5"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['gross-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'discount-amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Discount Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit flex justify-end px-3 py-1.5"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['net-sales']}
          </div>
        );
      }
    },
    {
      accessorKey: 'net-sales',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Net Sales" className="text-right flex justify-end px-3 py-2" />
      ),
      cell: ({ row }) => {
        return (
          <div
            className="text-right min-w-fit px-3 py-1.5 flex justify-end"
            onClick={() => onRowClickHandler(row?.original)}
          >
            {row.original['discount-amount']}
          </div>
        );
      }
    },
    {
      accessorKey: 'active',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="flex py-1.5 px-3 justify-center items-center">
            <Switch
              checked={row.original.active}
              onClick={(e: any) => e.stopPropagation()}
              onCheckedChange={() => {
                updateColumnData(row.original.id);
                handleBulkUpdate(
                  row?.original?.active ? CouponOperation.Deactivate : CouponOperation.Activate,
                  row.original
                );
              }}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      id: 'Action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1 px-3">
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setOfferToEdit(row?.original.row);
                setShowPaymentDiscount(true);
                trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.DISCOUNT_EDITING_STARTED, {
                  type: CouponType.Payment
                });
              }}
              className="mr-2"
            >
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74] cursor-pointer" />
            </Button>
            <Button
              type="submit"
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setOfferToDelete(row?.original);
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const [offerToEdit, setOfferToEdit] = useState<any>();
  const [offerToDelete, setOfferToDelete] = useState<any>(null);

  const [showPaymentDiscount, setShowPaymentDiscount] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [rows, setRows] = useState<any>(null);

  const [getPaymentOffers, { data: paymentOfferData, loading, refetch }] = useGetCouponsLazyQuery({
    variables: {
      couponTypes: CouponType.Payment,
      skip: 0,
      limit: rowPerPage
    }
  });

  const refetchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const rowData = paymentOfferData?.getCoupons.data.map((row) => {
      return {
        id: row.id,
        active: row.active,
        code: row.code,
        orders: getOrdersCount(row?.metrics?.order_count || 0),
        'gross-sales': `₹${getGrossSales(row?.metrics?.gross_sales || 0)}`,
        'discount-amount': `₹${getDiscountAmount(row?.metrics?.discount || 0)}`,
        'net-sales': `₹${getNetSale(row?.metrics?.net_sales || 0)}`,
        row
      };
    });
    setRows(rowData);
  }, [paymentOfferData]);

  const [bulkDiscountUpdate, { loading: bulkLoading }] = useBulkUpdateCouponMutation();

  const getNetSale = (sales: number) => {
    return formatIndianRupees(Number(sales.toFixed(2) || '0'));
  };
  const getDiscountAmount = (discount: number) => {
    return formatIndianRupees(Number(discount.toFixed(2) || '0'));
  };
  const getGrossSales = (sale: number) => {
    return formatIndianRupees(Number(sale.toFixed(2) || '0'));
  };
  const getOrdersCount = (count: number) => {
    return count || '0';
  };

  const updateColumnData = (rowId: string) => {
    setRows((prevRows: any) =>
      prevRows.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            active: !row.active
          };
        }
        return row;
      })
    );
  };

  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setShowDetailsModal(true);
      setOfferToEdit(item?.row);
      trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.VIEW_DISCOUNT_DETAILS);
    }
  };

  const handleConfirm = async () => {
    await handleBulkUpdate(CouponOperation.Delete, offerToDelete);
    setShowConfirmationDialog(false);
  };

  const handleChangeRowsPerPageByValue = (value: any) => {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  };

  const handleBulkUpdate = async (operation: CouponOperation, coupons: any | any[]) => {
    const couponIds = Array.isArray(coupons) ? coupons.map((coupon) => coupon.id) : [coupons.id];

    if (!couponIds || couponIds.length === 0) return;

    if (refetchTimeout.current) clearTimeout(refetchTimeout.current);

    try {
      await bulkDiscountUpdate({
        variables: {
          couponIds,
          operation
        }
      });
      enqueueSnackbar('Hurray! Payment Offer updated successfully!', {
        variant: 'success'
      });

      refetchTimeout.current = setTimeout(() => {
        refetch();
        refetchTimeout.current = null;
      }, 1500);

      if (operation === CouponOperation.Activate || operation === CouponOperation.Deactivate)
        trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.DISCOUNT_STATUS_CHANGED, { count: couponIds.length });
      else if (operation === CouponOperation.Delete)
        trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.REMOVE_DISCOUNT, { count: couponIds.length });
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const handleSearch = (skip: number, limit: number) => {
    getPaymentOffers({
      variables: {
        couponTypes: CouponType.Payment,
        skip,
        limit
      }
    });
  };

  const handleGetPaymentOffers = useCallback(handleSearch, []);

  useEffect(() => {
    handleGetPaymentOffers(rowPerPage * page, rowPerPage);
  }, [rowPerPage, page]);

  return (
    <>
      <div className="mx-4">
        <Title title="Payment Offers">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setOfferToEdit(null);
              setShowPaymentDiscount(true);
              trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.ADD_DISCOUNT_CLICKED);
              trackEvents(EventCategory.PAYMENT_OFFERS, DiscountEvent.DISCOUNT_ADDITION_STARTED, {
                type: CouponType.Payment
              });
            }}
            disabled={loading}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add Offer
          </Button>
        </Title>

        <div className="mt-2">
          <DataTable
            columnData={columns}
            data={rows}
            showPagination={true}
            onRowClick={onRowClickHandler}
            enableRowSelection={true}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={paymentOfferData?.getCoupons?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
            onBulkUpdate={handleBulkUpdate}
            bulkLoading={bulkLoading}
            isPaymentOffer={true}
          />
        </div>
      </div>

      {showPaymentDiscount && (
        <DiscountPaymentDialog
          open={showPaymentDiscount}
          setOpen={setShowPaymentDiscount}
          offerDetails={offerToEdit}
          setOfferDetails={setOfferToEdit}
          refetch={refetch}
        />
      )}

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleConfirm}
        headerText="Delete Offer?"
        text="This action will delete the selected offer."
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
      />

      <Drawer direction="right" open={showDetailsModal} onOpenChange={(value: boolean) => setShowDetailsModal(value)}>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex justify-between items-center mb-4">
              <DrawerTitle>Payment Offer Details</DrawerTitle>
              <Button size="icon" variant="icon" onClick={() => setShowDetailsModal(false)}>
                <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
              </Button>
            </div>
            <DrawerDescription className="text-black text-left">
              <CreateOfferPreview activeStep={4} selectedPaymentOffer={offerToEdit} isEdit={showPaymentDiscount} />
            </DrawerDescription>
          </DrawerHeader>
        </DrawerContent>
      </Drawer>
    </>
  );
};

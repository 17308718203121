import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { modifyCollectionData } from '../../../utils/rto-suite';
import { ConsiderationType, RtoSuitQuery } from '../../../graphql';
import { EventCategory, RtoSuiteEvents, trackEvents } from '../../../analytics';
import SelectedCollections from '../../shared/selected-collections/selected-collections';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../../flexyui/Accordion';
import { Button, Chip, Input, Label, RadioGroup, RadioGroupItem, Switch } from '../../flexyui';
import { CollectionSelectionDialog } from '../../shared/collection-selection/collection-selection';

const FilterCollections = ({ selectedCollections, setSelectedCollections, loadMoreCollections }: any) => {
  const { values, setFieldValue } = useFormikContext<RtoSuitQuery>();

  const [collectionSelectionSearch, setCollectionSelectionSearch] = useState('');
  const [openCollectionSelection, setOpenCollectionSelection] = useState<boolean>(false);

  const handleAddAndRemoveCollections = (value: any) => {
    setSelectedCollections(value);
    const originalArray = modifyCollectionData(values?.codAvailabilityConfig?.collection_filter?.collections);
    const addedElements = value
      .filter(
        (updatedItem: any) =>
          !originalArray.find((originalItem: any) => originalItem.collection_id === updatedItem.collection_id)
      )
      .map((each: any) => {
        return {
          collection_id: each?.collection_id
        };
      });
    const removedElements = originalArray
      .filter(
        (originalItem: any) =>
          !value.find((updatedItem: any) => updatedItem.collection_id === originalItem.collection_id)
      )
      .map((each: any) => {
        return {
          collection_id: each?.collection_id
        };
      });

    if (addedElements.length > 0) {
      setFieldValue('codAvailabilityConfig.collection_filter.added_collections', addedElements);
    } else {
      setFieldValue('codAvailabilityConfig.collection_filter.added_collections', null);
    }

    if (removedElements.length > 0) {
      setFieldValue('codAvailabilityConfig.collection_filter.removed_collections', removedElements);
    } else {
      setFieldValue('codAvailabilityConfig.collection_filter.removed_collections', null);
    }
  };

  useEffect(() => {
    if (values) {
      if (!(values.codAvailabilityConfig.collection_filter as { added_collections?: any })?.added_collections) {
        delete (values.codAvailabilityConfig.collection_filter as { added_collections?: any }).added_collections;
      }

      if (!(values.codAvailabilityConfig.collection_filter as { removed_collections?: any })?.removed_collections) {
        delete (values.codAvailabilityConfig.collection_filter as { removed_collections?: any }).removed_collections;
      }
    }
  }, [values]);

  useEffect(() => {
    setOpenCollectionSelection(collectionSelectionSearch.length > 0);
  }, [collectionSelectionSearch]);

  return (
    <>
      <AccordionItem value="item-2" className="border-b ">
        <div className="px-4">
          <AccordionTrigger className="py-3">
            <div className="flex flex-col items-start space-y-1">
              <Label size="md" className="text-sm cursor-pointer flex items-center gap-2">
                <div className="py-0.5">Filter based on Collections</div>
                {values?.codAvailabilityConfig?.collection_filter?.enabled && (
                  <Chip
                    text={values?.codAvailabilityConfig?.collection_filter?.enabled ? 'Enabled' : 'Disabled'}
                    variant={values?.codAvailabilityConfig?.collection_filter?.enabled ? 'success' : 'draft'}
                  />
                )}
              </Label>
              <Label className="text-gray-400 cursor-pointer text-left max-w-[95%] sm:max-w-full" size={'sm'}>
                Choose collections to include or exclude from cash on delivery orders.
              </Label>
            </div>
          </AccordionTrigger>
        </div>
        <AccordionContent className="bg-white p-3 border-t">
          <div className="flex items-center gap-2 mb-4 mx-0.5">
            <Switch
              checked={values?.codAvailabilityConfig?.collection_filter?.enabled}
              onCheckedChange={(value) => {
                setFieldValue('codAvailabilityConfig.collection_filter.enabled', value);
                trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.COLLECTION_FILTER_STATUS_CHANGED);
              }}
              id="codAvailabilityConfig.collection_filter.enabled"
            />
            <Label
              size={'paragraph'}
              className="text-gray-600"
              htmlFor="codAvailabilityConfig.collection_filter.enabled"
            >
              Enable Collection filtering
            </Label>
          </div>
          <div className="flex items-center gap-2 p-1 mb-2">
            <Input
              type="text"
              placeholder="Search"
              iconPosition="start"
              icon={<Unicons.UilSearch size={18} />}
              className="w-full sm:w-[500px] -ml-0.5"
              value={collectionSelectionSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setCollectionSelectionSearch(e.target.value);
              }}
            />
            <Button
              variant="outline"
              className="h-10 w-[85px]"
              onClick={() => {
                setOpenCollectionSelection(true);
              }}
            >
              Browse
            </Button>
            <CollectionSelectionDialog
              key={'CollectionSelection'}
              open={openCollectionSelection}
              setOpen={(val: boolean) => {
                setOpenCollectionSelection(val);
                setCollectionSelectionSearch('');
              }}
              search={collectionSelectionSearch}
              preSelections={selectedCollections}
              onSave={(value: any) => {
                handleAddAndRemoveCollections(value);
                trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.ADD_COLLECTION_FILTER);
              }}
            />
          </div>
          <div className="w-full sm:w-[595px] pl-0.5">
            <SelectedCollections
              on="codAvailabilityConfig.collection_filter.collections"
              setFieldValue={setFieldValue}
              value={selectedCollections}
              onRemove={(value: any) => {
                handleAddAndRemoveCollections(value);
                trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.REMOVE_COLLECTION_FILTER);
              }}
            />
            <div className="flex justify-end mt-2">
              {values &&
              values?.codAvailabilityConfig?.collection_filter?.collections?.length <
                values?.codAvailabilityConfig?.collection_filter?.collection_count ? (
                <Button
                  variant="link"
                  className="w-fit flex items-center text-primary text-sm cursor-pointer hover:no-underline"
                  onClick={() => {
                    loadMoreCollections();
                  }}
                >
                  <Unicons.UilAngleDown className="pt-0.5" />
                  <div>Load More</div>
                </Button>
              ) : null}
            </div>
          </div>
          <div className="mb-1 mt-4">
            <Label size="md" className="mb-2 text-sm">
              Collections type
            </Label>
            <RadioGroup
              className="mt-1"
              defaultValue={ConsiderationType.AllowSelectionsOnly}
              value={
                values?.codAvailabilityConfig?.collection_filter?.consideration_type ||
                ConsiderationType.AllowSelectionsOnly
              }
              onValueChange={(value: ConsiderationType) => {
                setFieldValue('codAvailabilityConfig.collection_filter.consideration_type', value);
                trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.COLLECTION_FILTER_TYPE, {
                  consideration_type: value
                });
              }}
            >
              <div className="flex items-start space-x-2">
                <RadioGroupItem
                  value={ConsiderationType.AllowSelectionsOnly}
                  id="codAvailabilityConfig.collection_filter.consideration_type.allowSelectionsOnly"
                  className="mt-0.5"
                />
                <Label
                  size={'paragraph'}
                  className="text-gray-600"
                  htmlFor="codAvailabilityConfig.collection_filter.consideration_type.allowSelectionsOnly"
                >
                  Allow COD orders only for products under these collections
                </Label>
              </div>
              <div className="flex items-start space-x-2">
                <RadioGroupItem
                  value={ConsiderationType.BlockSelections}
                  id="codAvailabilityConfig.collection_filter.consideration_type.blockSelections"
                  className="mt-0.5"
                />
                <Label
                  size={'paragraph'}
                  className="text-gray-600"
                  htmlFor="codAvailabilityConfig.collection_filter.consideration_type.blockSelections"
                >
                  Block COD orders for products under these collections
                </Label>
              </div>
            </RadioGroup>
          </div>
        </AccordionContent>
      </AccordionItem>
    </>
  );
};

export default FilterCollections;

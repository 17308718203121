import React from 'react';
import { Orders } from './orders/orders';
import { Upsell } from './upsell/upsell';
import { Coupons } from './coupons/coupons';
import RTOSuite from './rto-suite/rto-suite';
import { Settings } from './settings/settings';
import { Shipping } from './shipping/shipping';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './dashboard/dashboard';
import { Customization } from './customization/customization';
import { PaymentOffers } from './payment-offers/payment-offers';
import { OrderDetails } from '../components/orders/order-details';
import { MainLayoutWrapper } from '../components/layout/main-layout-wrapper';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainLayoutWrapper />}>
        <Route element={<Dashboard />} path="/" />
        <Route element={<Orders key="orders" />} path="/orders" />
        <Route element={<Orders key="cart" />} path="/cart" />
        <Route element={<OrderDetails />} path="orders/:orderId" />
        <Route element={<OrderDetails />} path="cart/:orderId" />
        <Route element={<Shipping />} path="/shipping/*" />
        <Route element={<Coupons />} path="/coupons" />
        <Route element={<Settings />} path="/settings/*" />
        <Route element={<Customization />} path="/customization/*" />
        <Route element={<RTOSuite />} path="/rto-suite" />
        <Route element={<PaymentOffers />} path="/payment-offers" />
        <Route element={<Upsell />} path="/upsell/*" />
      </Route>
      {/* <Route element={<Demo />} path="/demo" />*/}
    </Routes>
  );
};

import { ApplicationType, CombineStatus, CouponStatus, CouponType, Platform, SortBy, SortOrder } from '../../graphql';

export enum ItemsFrom {
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
  SPECIFIC_COLLECTION = 'SPECIFIC_COLLECTION'
}

export enum UserScope {
  ALL_USERS = 'all_users',
  SPECIFIC_USERS = 'specific_users'
}

type CouponFilterOption<T> = {
  label: string;
  value: T;
};

type CouponFilterConfig<T> = {
  label: string;
  value: string;
  options: CouponFilterOption<T>[];
}[];

export interface CouponFilterOptions {
  code: string;
  couponTypes: CouponType[];
  applicationType: ApplicationType[];
  status: CouponStatus[];
  platform: Platform[];
  combines: CombineStatus[];
  sortBy: SortBy;
  sortOrder: SortOrder;
}

export const filterChoices: CouponFilterConfig<CouponType | ApplicationType | CouponStatus | Platform | CombineStatus> =
  [
    {
      label: 'Coupon Type',
      value: 'couponTypes',
      options: [
        // { label: 'Amount Discount', value: CouponType.Amount },
        { label: 'Cart Amount', value: CouponType.CartAmount },
        { label: 'Product Amount', value: CouponType.ProductAmount },
        { label: 'Buy X Get Y', value: CouponType.Bxgy },
        { label: 'Purchase Driven', value: CouponType.PurchaseHistory },
        { label: 'Freebie', value: CouponType.AddOn }
      ]
    },
    {
      label: 'Application Type',
      value: 'applicationType',
      options: [
        { label: 'Code', value: ApplicationType.Code },
        { label: 'Automatic', value: ApplicationType.Automatic }
      ]
    },
    {
      label: 'Status',
      value: 'status',
      options: [
        { label: 'Active', value: CouponStatus.Active },
        { label: 'Scheduled', value: CouponStatus.Scheduled },
        { label: 'Expired', value: CouponStatus.Expired }
      ]
    },
    {
      label: 'Platform',
      value: 'platform',
      options: [
        { label: 'Shopify', value: Platform.Shopify },
        { label: 'FlexyPe', value: Platform.Flexype }
      ]
    },
    {
      label: 'Combines',
      value: 'combines',
      options: [
        { label: 'Combinable', value: CombineStatus.Combinable },
        { label: 'Not Combinable', value: CombineStatus.NotCombinable }
      ]
    }
  ];

export const sortChoices = [
  {
    label: 'Sort By',
    value: 'sortBy',
    options: [
      { label: 'Created at date', value: SortBy.CreatedAt },
      { label: 'Updated at date', value: SortBy.UpdatedAt },
      { label: 'Start date', value: SortBy.StartDate },
      { label: 'End date', value: SortBy.EndDate },
      { label: 'Code', value: SortBy.Code }
    ]
  },
  {
    label: 'Sort Order',
    value: 'sortOrder',
    options: [
      { label: 'Ascending', value: SortOrder.Asc },
      { label: 'Descending', value: SortOrder.Desc }
    ]
  }
];

export const addCouponsData = [
  {
    id: CouponType.CartAmount,
    title: 'Amount off Products/Order',
    desc: 'Discount specific products or collections of products / total order amount.'
  },
  {
    id: CouponType.Bxgy,
    title: 'Buy X get Y',
    desc: 'Discount products based on a customer’s purchase.'
  },
  {
    id: CouponType.PurchaseHistory,
    title: 'Purchase Driven',
    desc: 'Discount based on users’ past order patterns.'
  },
  {
    id: CouponType.AddOn,
    title: 'Exclusive Free Gift',
    desc: 'Get a complimentary product with your order.',
    isNew: true
  }
];

export const formatCouponType = (couponType: any) => {
  switch (couponType) {
    case CouponType.Amount:
      return 'Amount';
    case CouponType.Bxgy:
      return 'Buy X get Y';
    case CouponType.CartAmount:
      return 'Cart Amount';
    case CouponType.Payment:
      return 'Payment';
    case CouponType.ProductAmount:
      return 'Product Amount';
    case CouponType.PurchaseHistory:
      return 'Purchase Driven';
    default:
      return '';
  }
};

export const InfoCardSetup = [
  {
    heading: 'How does the discount apply?',
    info: [
      'Coupon Code: Customers must enter this code at checkout.',
      'Automatic Discount: Customers will see this in their cart and at checkout.'
    ]
  },
  {
    heading: 'What should this discount do?',
    info: [
      'Discount on cart/products: Discount on specific products, collections of products, or on the total order amount.'
      // 'Buy X get Y: Discount on products based on a customer’s purchase.'
    ]
  },
  {
    heading: 'What is the discount value?',
    info: [
      'Percentage off: Customers get a percentage off on their purchase.',
      'Fixed amount: Customers get a fixed amount off on their purchase.'
    ]
  },
  {
    heading: 'Products to be added',
    info: [
      'Quantity: max number of product to give for free.',
      'Products added as freebie will not be considered in any other coupon even if this coupon is combinable.'
    ]
  }
];

export const InfoCardBehaviour = [
  {
    heading: 'Customer behaviour',
    info: [
      'Use coupon code: This discount is available only to customers who have previously used this coupon.',
      'Minimum order amount: This discount is valid only for customers who have previously made at least one purchase meeting the minimum amount requirement.',
      'Minimum number of orders: This discount is offered only to customers with a minimum number of past orders.'
    ]
  },
  {
    heading: 'Behaviour period',
    info: ['The customer behavior rule applies only during the specified inclusive date range. ']
  }
];

export const InfoCardApplication = [
  {
    heading: 'What does the discount applies to?',
    info: [
      'Total order value: Customers get a discount on the total order value.',
      'Specific products: Customers get a discount on specific products only.'
    ]
  },
  {
    heading: 'Are there any purchase requirements?',
    info: [
      'No requirement: The coupon can be applied on any order.',
      ' Purchase amount: The coupon can be applied only when total cart value meets a certain amount.',
      'Purchase quantity: The coupon can be applied only when total cart items meets a certain quantity.'
    ]
  }
];

export const InfoCardBXGYApplication = [
  {
    heading: 'Customer buys',
    info: ['Buy X get Y discounts are only supported with one-time purchases.']
  },
  {
    heading: 'Customer gets',
    info: ['Customers must add the quantity of items specified below to their cart.']
  }
];

export const InfoCardUserEligibility = [
  {
    heading: 'User Eligibility',
    info: [
      'All Orders: The discount is valid for all orders.',
      'First Order Only: The discount is valid for the first order only.\n',
      'Total Order Count: The discount applies based on the specified number of previous orders.'
    ]
  },
  {
    heading: 'User Specific',
    info: [
      'All Users: The discount applies to every user.',
      'Specific Users: The discount is available only to selected users.'
    ]
  }
];

export const InfoCardUsageLimit = [
  {
    heading: 'Maximum discount usage',
    info: []
  },
  {
    heading: 'Discount active period',
    info: ['Set a start and end(optional) date during which this discount will be applicable.']
  },
  {
    heading: 'Combine',
    info: ['Selecting this option allows this coupon to be combined with other discounts.']
  },
  {
    heading: 'Disable Cash on Delivery (COD)',
    info: [
      'Selecting this option restricts customers from choosing Cash on Delivery as a payment method, ensuring only prepaid payment options are available at checkout.'
    ]
  }
];

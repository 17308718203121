/* eslint-disable camelcase */
import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label
} from '../../../flexyui';
import { Formik } from 'formik';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { RootState } from '../../../../store';
import * as Unicons from '@iconscout/react-unicons';
import { EventCategory, PaymentEvent, trackEvents } from '../../../../analytics';
import { paymentProviderSchema } from '../../../../utils/validation-schemas/settings';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/loading.svg';
import { MerchantPaymentProvider, useUpdatePaymentProviderMutation } from '../../../../graphql';
import { PaymentProviderFields, razorpayDevRedirect, razorpayProdRedirect } from '../../../../constants/settings';

type Props = {
  provider: any;
  refetch: () => void;
  usedProviders: Array<string>;
};

export const PaymentProviderCard: React.FC<Props> = ({ provider, refetch, usedProviders }) => {
  const providerName =
    provider.name === MerchantPaymentProvider.Ccavenue
      ? 'CCAvenue'
      : provider.name === MerchantPaymentProvider.Payu
        ? 'PayU'
        : provider.name[0].toUpperCase() + provider.name.substring(1).toLowerCase();
  const isProviderUsed = usedProviders.includes(provider.name);
  const providerFieldDetails = PaymentProviderFields[provider.name as keyof typeof PaymentProviderFields];
  const showRazorpayOAuthMigration =
    provider.name === MerchantPaymentProvider.Razorpay &&
    !!provider.api_key &&
    !!provider.api_secret &&
    provider.account_type === 'MANUAL';

  const [updatePaymentProvider, { loading }] = useUpdatePaymentProviderMutation();

  const merchantId = useSelector((state: RootState) => state.main.store);
  const [open, setOpen] = useState<boolean>(false);
  const [showRedirectDialog, setShowRedirectDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

  const handleSave = async (values: any) => {
    const successMessage =
      !!values.api_key && !!values.api_secret
        ? `${providerName} has been configured successfully!`
        : `${providerName} credentials have been deleted successfully!`;

    const { has_api_secret, ...rest } = values;

    try {
      await updatePaymentProvider({
        variables: {
          provider: provider.name,
          providerKeys: rest
        }
      });
      enqueueSnackbar(successMessage, { variant: 'success' });

      refetch();
      if (!!values.api_key && !!values.api_secret)
        trackEvents(EventCategory.PAYMENT, PaymentEvent.CONFIGURE_PROVIDER, {
          provider: provider.name
        });
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
    }
  };

  const initialValues = {
    account_id: provider.account_id || '',
    api_key: provider?.api_key || '',
    api_secret: '',
    has_api_secret: !!provider?.api_secret
    // api_key: provider?.api_key || (provider.name === MerchantPaymentProvider.Phonepe ? '-' : ''),
    // api_secret: provider?.api_secret || (provider.name === MerchantPaymentProvider.Phonepe ? '-' : '')
  };

  return (
    <>
      <Card
        className={classNames('h-fit p-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]', {
          'border-[#5fb863]': provider.active,
          'border-[#ffba76]': showRazorpayOAuthMigration
        })}
      >
        <div className="flex items-center justify-between mb-8">
          {provider.icon}
          <Chip
            text={provider.active ? 'Active' : 'Inactive'}
            variant={showRazorpayOAuthMigration ? 'warning' : provider.active ? 'success' : 'draft'}
          />
        </div>
        <div className="flex justify-end">
          <Button
            variant="info"
            onClick={() => {
              if (provider.name === MerchantPaymentProvider.Razorpay) {
                if (!!provider.api_key && !!provider.api_secret)
                  if (provider.account_type === 'MANUAL') setShowRedirectDialog(true);
                  else setShowDeleteConfirmationDialog(true);
                else setShowRedirectDialog(true);
              } else setOpen(true);
            }}
            className="no-underline w-fit h-fit font-normal text-neutral-400 relative group -mr-1"
          >
            {provider.name === MerchantPaymentProvider.Razorpay &&
            !!provider.api_key &&
            !!provider.api_secret &&
            provider.account_type === 'OAUTH'
              ? 'Disconnect'
              : showRazorpayOAuthMigration
                ? 'Set up OAuth'
                : 'Configure'}
            <Unicons.UilAngleRight
              size={20}
              className="transition-transform duration-300 ease-in-out transform group-hover:translate-x-1"
            />
          </Button>
        </div>
      </Card>

      <ConfirmationDialog
        showModal={showRedirectDialog}
        setShowModal={setShowRedirectDialog}
        onSave={() => {
          const redirectUrl =
            process.env.REACT_APP_ENV === 'development'
              ? razorpayDevRedirect(merchantId)
              : razorpayProdRedirect(merchantId);
          window.open(redirectUrl, '_self');
        }}
        headerText="Authorize your razorpay account?"
        text="Authorize your Razorpay account to enable secure payment processing. Do you wish to proceed?"
        loading={loading}
      />

      <ConfirmationDialog
        showModal={showDeleteConfirmationDialog}
        setShowModal={setShowDeleteConfirmationDialog}
        onSave={async () => {
          if (isProviderUsed) {
            setShowDeleteConfirmationDialog(false);
            trackEvents(EventCategory.PAYMENT, PaymentEvent.REMOVE_ACTIVE_PROVIDER, {
              provider: provider.name
            });
          } else {
            await handleSave({
              account_id: '',
              api_key: '',
              api_secret: ''
            });
            trackEvents(EventCategory.PAYMENT, PaymentEvent.REMOVE_PROVIDER, {
              provider: provider.name
            });
          }
        }}
        headerText="Delete Credentials?"
        text={
          isProviderUsed
            ? `The payment provider ${providerName} is in use. 
                        Unlink it from active usage before proceeding.`
            : `This action will permanently delete the credentials for ${providerName}. 
                        This cannot be undone.`
        }
        confirmButtonText={isProviderUsed ? 'Ok' : 'Delete'}
        confirmActionVariant={isProviderUsed ? 'destructive' : 'default'}
        loading={loading}
      />

      {open && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          validationSchema={paymentProviderSchema(providerFieldDetails.isAccountIdRequired)}
          onSubmit={handleSave}
        >
          {({ values, errors, touched, getFieldProps, submitForm, isSubmitting }) => {
            return (
              <>
                <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
                  <DialogContent className="!gap-0" size="sm" close={true}>
                    <div>
                      <DialogHeader>
                        <DialogTitle>
                          <p>Configure {providerName}</p>
                        </DialogTitle>
                        <DialogDescription className="!p-0 !m-0">
                          <div className="p-4">
                            {providerFieldDetails.isAccountIdRequired && (
                              <div className="mb-3">
                                <Label size="paragraph">{providerFieldDetails.accountIdFieldName}</Label>
                                <Input
                                  {...getFieldProps('account_id')}
                                  error={touched.account_id && !!errors.account_id}
                                  errorMessage={`${providerFieldDetails.accountIdFieldName} ${errors.account_id}`}
                                  className="font-mono text-[#121b38] mt-1"
                                />
                              </div>
                            )}
                            {/* {provider.name !== MerchantPaymentProvider.Phonepe && (*/}
                            <>
                              <div>
                                <Label size="paragraph">{providerFieldDetails.apiKeyFieldName}</Label>
                                <Input
                                  {...getFieldProps('api_key')}
                                  error={touched.api_key && !!errors.api_key}
                                  errorMessage={`${providerFieldDetails.apiKeyFieldName} ${errors.api_key}`}
                                  className="font-mono text-[#121b38] mt-1"
                                />
                              </div>

                              <div className="mt-3">
                                <Label size="paragraph">{providerFieldDetails.apiSecretFieldName}</Label>
                                <Input
                                  {...getFieldProps('api_secret')}
                                  error={touched.api_secret && !!errors.api_secret}
                                  errorMessage={`${providerFieldDetails.apiSecretFieldName} ${errors.api_secret}`}
                                  className="font-mono text-[#121b38] mt-1"
                                />
                              </div>

                              {values.has_api_secret && (
                                <div className="text-xs text-[#888D9B] mt-2 leading-[15px]">
                                  This secret is not stored permanently and must be configured each time you configure
                                  it.
                                </div>
                              )}
                            </>
                            {/* )}*/}
                          </div>
                        </DialogDescription>
                      </DialogHeader>
                    </div>
                    <DialogFooter>
                      <div className="w-full flex items-center justify-between">
                        {!!provider.api_key && !!provider.api_secret ? (
                          <Button
                            variant="destructive"
                            size="md"
                            onClick={() => {
                              setShowDeleteConfirmationDialog(true);
                            }}
                          >
                            Delete
                          </Button>
                        ) : (
                          <span />
                        )}
                        <div className="flex items-center gap-2">
                          <Button
                            variant="outline"
                            size="md"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="default"
                            size="md"
                            type="submit"
                            disabled={Object.keys(errors).length > 0 || isSubmitting}
                            onClick={() => setShowConfirmationDialog(true)}
                          >
                            {isSubmitting ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Save'}
                          </Button>
                        </div>
                      </div>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>

                <ConfirmationDialog
                  showModal={showConfirmationDialog}
                  setShowModal={setShowConfirmationDialog}
                  onSave={async () => {
                    await submitForm();
                  }}
                  headerText="Change Credentials?"
                  text={`
                    This action will configure the credentials for ${providerName}. 
                    Please ensure all information is accurate. Additionally, 
                    configure the webhook to complete the setup.
                  `}
                  confirmButtonText="Confirm"
                  confirmActionVariant="default"
                  loading={loading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

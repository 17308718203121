import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SideBar } from '../../constants/sidebar';
import * as Unicons from '@iconscout/react-unicons';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarState } from '../../store/main-slice';
import { Drawer, DrawerContent } from '../flexyui/Drawer';
import { ButtonWithInfo } from '../flexyui/ButtonWithInfo';
import { SidebarLink } from '../shared/sidebar-link/sidebar-link';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';

type props = {
  sidebarCollapsed: boolean;
};

export const Sidebar: React.FC<props> = ({ sidebarCollapsed }) => {
  const dispatch = useDispatch<AppDispatch>();
  const banners = useSelector((state: RootState) => state.main.banners);
  const sideBarState = useSelector((state: RootState) => state.main.sideBarState);
  const storeUrl = useSelector((state: RootState) => state.main.storeUrl);

  return (
    <>
      <div
        className={classNames(
          'fixed z-10 border-r-[1px] border-t-[1px] transition-all duration-300 ease-in-out bg-white',
          {
            'w-16': sidebarCollapsed,
            'w-56': !sidebarCollapsed,
            'top-20': banners.length > 0,
            'top-12': banners.length === 0
          }
        )}
      >
        <div
          className={classNames('py-2 hidden lg:flex flex-col justify-between transition-all ease-linear', {
            'h-[calc(100vh-80px)]': banners.length > 0,
            'h-[calc(100vh-48px)]': banners.length === 0,
            'px-3': sidebarCollapsed,
            'px-4': !sidebarCollapsed
          })}
        >
          <div className="w-full flex flex-col items-center">
            {SideBar.map((item, index) => (
              <div key={index} className="w-full">
                <ButtonWithInfo heading={sidebarCollapsed ? item.name : ''} description="" simple={true} side="right">
                  <SidebarLink text={!sidebarCollapsed ? item.name : ''} routeLink={item.to} icon={item.icon} />
                </ButtonWithInfo>
              </div>
            ))}
          </div>
          {storeUrl.length > 0 && (
            <Link
              to={`https://${storeUrl}`}
              target="_blank"
              className="p-2 rounded-lg my-0.5 transition-all ease-linear text-[rgba(18,27,56,0.5)] font-medium hover:bg-slate-100"
            >
              <div className="flex items-center gap-2.5 h-[21px]">
                <div className="h-4 px-0.5">
                  <Unicons.UilEye size={18} />
                </div>
                <span
                  className={classNames('transition-[opacity,transform] ease-in-out transform min-w-[150px]', {
                    'opacity-0 scale-95 translate-y-2': sidebarCollapsed,
                    'opacity-100 scale-100 translate-y-0': !sidebarCollapsed
                  })}
                >
                  View Store
                </span>
              </div>
            </Link>
          )}
        </div>
      </div>

      <Drawer direction="left" open={sideBarState} onOpenChange={(value: boolean) => dispatch(setSidebarState(value))}>
        <DrawerContent anchor="left" className="w-auto">
          <div className="w-[225px] h-full flex flex-col justify-between">
            <div className="px-4">
              <Logo className="h-8 w-24 my-4" />
              {SideBar.map((item: any, index) => {
                return <SidebarLink key={index} text={item.name} routeLink={item.to} icon={item.icon} />;
              })}
            </div>
            {storeUrl.length > 0 && (
              <Link
                to={`https://${storeUrl}`}
                target="_blank"
                className="p-2 mx-4 mb-4 flex items-center gap-3 rounded-md my-1 transition-all ease-linear hover:bg-slate-100"
              >
                <div className="h-4">
                  <Unicons.UilEye size={18} />
                </div>
                View Store
              </Link>
            )}
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};

import React from 'react';

import * as Unicons from '@iconscout/react-unicons';
import { convertAmount } from '../../../utils/convert-amount';

type Props = {
  loading: boolean;
  value?: string;
  title: string;
  prevValue?: string;
  percentageChange?: number | string;
};

export const DashboardGraphTitle: React.FC<Props> = ({ loading, value, title, prevValue, percentageChange }) => {
  return (
    <>
      {value && (
        <div className="flex justify-between px-4 pt-2 my-2">
          <div className="flex flex-wrap font-semibold text-xl items-baseline gap-2 ">
            <span>
              {title !== 'Total Orders' && title !== 'Average Session Duration' && '₹ '}
              {value}
            </span>
            {percentageChange === '-'
              ? '-'
              : (percentageChange || percentageChange === 0) &&
                !loading && (
                  <div
                    className={`flex items-center font-medium text-xs ${
                      percentageChange >= 0 ? 'text-green-600' : 'text-red-600'
                    } `}
                  >
                    {convertAmount(Number(percentageChange))}%
                    {percentageChange >= 0 ? <Unicons.UilArrowUp size={16} /> : <Unicons.UilArrowDown size={16} />}
                  </div>
                )}
          </div>

          {prevValue && (
            <div className="text-gray-400 font-medium text-lg">
              {(title !== 'Total Orders' || 'Average Session Duration') && '₹ '}
              {prevValue}
            </div>
          )}
        </div>
      )}
    </>
  );
};

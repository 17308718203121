import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogDescription
} from '../../flexyui/Dialog';
import {
  DiscountType,
  CalculateLineItem,
  useOrderEditAddDiscountMutation,
  useOrderEditDeleteDiscountMutation,
  useOrderEditUpdateDiscountMutation
} from '../../../graphql';
import classNames from 'classnames';
import { Button, Label } from '../../flexyui';
import { Input } from '../../flexyui/Input';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import { EventCategory, OrderEditEvents, trackEvents } from '../../../analytics';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../flexyui/Select';

type Props = {
  lineItem: CalculateLineItem | null;
  refetch: () => Promise<any>;
  calculatedOrderId: string;
  setDiscountLineItem: (data: any) => void;
  openApplyDiscount: boolean;
  setOpenApplyDiscount: (data: boolean) => void;
};

export const ApplyDiscount: React.FC<Props> = ({
  lineItem,
  refetch,
  calculatedOrderId,
  setDiscountLineItem,
  openApplyDiscount,
  setOpenApplyDiscount
}) => {
  const [editReason, setEditReason] = useState<string>(lineItem?.discount?.reason_for_edit || '');
  const [discountType, setDiscountType] = useState(lineItem?.discount?.discount_type || DiscountType.Fixed);
  const [discountAmount, setDiscountAmount] = useState<any>(
    lineItem?.discount?.discount_type === DiscountType.Fixed ? lineItem?.discount?.discount_amount : ''
  );
  const [discountPercentage, setDiscountPercentage] = useState<any>(
    lineItem?.discount?.discount_type === DiscountType.Percentage ? lineItem?.discount?.discount_amount : ''
  );

  const [addLineItemDiscount, { loading: addLineItemDiscountLoading }] = useOrderEditAddDiscountMutation();
  const [editLineItemDiscount, { loading: editLineItemDiscountLoading }] = useOrderEditUpdateDiscountMutation();
  const [deleteLineItemDiscount, { loading: deleteLineItemDiscountLoading }] = useOrderEditDeleteDiscountMutation();

  const handleAddLineItemDiscount = async () => {
    if (!lineItem?.calculated_line_item_id) return;

    try {
      await addLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          discountType,
          discountValue:
            discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
          reasonForEdit: editReason || 'DISCOUNT'
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.ADD_DISCOUNT, {
        calculatedOrderId: calculatedOrderId,
        discountType,
        discountValue:
          discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
        reasonForEdit: editReason || 'DISCOUNT'
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setOpenApplyDiscount(false);
  };
  const handleEditLineItemDiscount = async () => {
    if (!lineItem?.calculated_discount_application_id) return;

    try {
      await editLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          calculatedDiscountApplicationId: lineItem?.calculated_discount_application_id,
          discountType,
          discountValue:
            discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
          reasonForEdit: editReason || 'DISCOUNT'
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.EDIT_DISCOUNT, {
        calculatedOrderId: calculatedOrderId,
        discountType,
        discountValue:
          discountType === DiscountType.Fixed ? parseFloat(discountAmount) : parseFloat(discountPercentage),
        reasonForEdit: editReason || 'DISCOUNT'
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setOpenApplyDiscount(false);
  };
  const handleDeleteLineItemDiscount = async () => {
    if (!lineItem?.calculated_discount_application_id) return;

    try {
      await deleteLineItemDiscount({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedDiscountApplicationId: lineItem?.calculated_discount_application_id
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.REMOVE_DISCOUNT, {
        calculatedOrderId: calculatedOrderId
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setOpenApplyDiscount(false);
  };

  const isDisabled = addLineItemDiscountLoading || editLineItemDiscountLoading || deleteLineItemDiscountLoading;

  useEffect(() => {
    if (!openApplyDiscount) setDiscountLineItem(null);
  }, [openApplyDiscount]);

  useEffect(() => {
    if (lineItem) {
      setDiscountType(lineItem?.discount?.discount_type || DiscountType.Fixed);
      setDiscountAmount(
        lineItem?.discount?.discount_type === DiscountType.Fixed ? lineItem?.discount?.discount_amount : ''
      );
      setDiscountPercentage(
        lineItem?.discount?.discount_type === DiscountType.Percentage ? lineItem?.discount?.discount_amount : ''
      );
      setEditReason(lineItem?.discount?.reason_for_edit || '');
    }
  }, [lineItem]);

  return (
    <>
      <Dialog open={openApplyDiscount} onOpenChange={(value: boolean) => setOpenApplyDiscount(value)}>
        <DialogContent className="!gap-0 !max-w-lg" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <div className="text-sm flex items-center gap-2 font-semibold">
                  {lineItem?.calculated_discount_application_id ? 'Edit discount of' : 'Apply  discount to'}{' '}
                  {lineItem?.variant?.title}
                </div>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0 text-[#121B38]">
                <div className="w-full p-4 box-border">
                  <div className="flex justify-between items-center mb-4">
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Discount Type</div>
                      <Select
                        value={discountType}
                        defaultValue={DiscountType.Fixed}
                        onValueChange={(value) => {
                          setEditReason('');
                          setDiscountType(value as DiscountType);
                        }}
                      >
                        <SelectTrigger className="h-10 w-full text-black">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent side="bottom">
                          <SelectItem value={DiscountType.Fixed}>Amount</SelectItem>
                          <SelectItem value={DiscountType.Percentage}>Percentage</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Discount Value</div>
                      {discountType === DiscountType.Fixed ? (
                        <Input
                          type="number"
                          name="discount_amount"
                          className="!m-0 rounded-md text-black"
                          iconPosition="start"
                          icon={<Unicons.UilRupeeSign size={15} className="text-[#2A324C]" />}
                          value={discountAmount}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(value)) {
                              setDiscountAmount(value);
                            }
                          }}
                        />
                      ) : (
                        <Input
                          type="number"
                          name="discount_percentage"
                          className="!m-0 rounded-md text-black"
                          iconPosition="end"
                          icon={<Unicons.UilPercentage size={18} className="text-[#2A324C]" />}
                          value={discountPercentage}
                          onChange={(e) => {
                            const value = e.target.value;
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (regex.test(value)) {
                              setDiscountPercentage(value);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="font-medium mb-1 text-[#2A324C]">
                      Reason for edit <span className="text-[#888D9B] font-normal">(optional)</span>
                    </div>
                    <Input
                      type="text"
                      name="edit_reason"
                      className="!m-0 rounded-md text-black"
                      value={editReason}
                      onChange={(e) => setEditReason(e.target.value)}
                    />
                  </div>
                  <div className="text-[rgb(89,95,116)] leading-4 text-xs">Your customers can see this reason</div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter className="!justify-between !flex-row-reverse !py-2.5 !px-3">
            <Button
              variant="default"
              size="md"
              onClick={() => {
                if (lineItem?.calculated_discount_application_id) handleEditLineItemDiscount();
                else handleAddLineItemDiscount();
              }}
              disabled={
                isDisabled ||
                (discountType === DiscountType.Fixed
                  ? !(discountAmount.length > 0 && parseFloat(discountAmount) > 0)
                  : !(
                      discountPercentage.length > 0 &&
                      parseFloat(discountPercentage) > 0 &&
                      parseFloat(discountPercentage) <= 100
                    ))
              }
            >
              {addLineItemDiscountLoading || editLineItemDiscountLoading ? (
                <LoadingIcon height={20} className={'animate-spin text-white'} />
              ) : lineItem?.calculated_discount_application_id ? (
                'Save'
              ) : (
                'Apply'
              )}
            </Button>
            {lineItem?.calculated_discount_application_id && (
              <Button
                size="sm"
                variant="destructive"
                disabled={isDisabled}
                onClick={handleDeleteLineItemDiscount}
                className={classNames('!m-0 w-[140px] disabled:bg-muted', {
                  '!bg-destructive': deleteLineItemDiscountLoading
                })}
              >
                {deleteLineItemDiscountLoading ? (
                  <LoadingIcon height={20} className={'animate-spin text-white'} />
                ) : (
                  'Remove discount'
                )}
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

import React from 'react';

type Props = {
  variable: string;
  value: string;
};

const Calculations = ({ variable, value }: Props) => {
  return (
    <span className="font-mono text-xs">
      <span className="text-blue-500 leading-5">{variable}</span>
      <span> = </span>
      <span className="text-green-700 leading-5">{value}</span>
    </span>
  );
};

export default Calculations;

import {
  CartSessionStatus,
  PaymentMethod,
  PaymentProvider,
  PaymentState,
  SearchField,
  SortOrder,
  SortOrdersBy
} from '../../graphql';

type OrderFilterOption<T> = {
  label: string;
  value: T;
};

type OrderFilterConfig<T> = {
  label: string;
  value: string;
  options: OrderFilterOption<T>[];
}[];

export interface OrderFilterOptions {
  query: string;
  searchField: SearchField;
  sessionState: CartSessionStatus[];
  paymentStatus: PaymentState[];
  paymentMethods: PaymentMethod[];
  paymentProviders: PaymentProvider[];
  dateRange: {
    start: string;
    end: string;
  };
  sortBy: SortOrdersBy;
  sortOrder: SortOrder;
}

export const orderFilterChoices: OrderFilterConfig<PaymentState | PaymentMethod | PaymentProvider> = [
  {
    label: 'Payment Status',
    value: 'paymentStatus',
    options: [
      // { label: 'Initiated', value: PaymentState.Initiated },
      { label: 'Pending', value: PaymentState.Pending },
      { label: 'Partially Paid', value: PaymentState.PartiallyPaid },
      { label: 'Paid', value: PaymentState.Completed },
      { label: 'Refunded', value: PaymentState.Refunded }
      // { label: 'Draft', value: PaymentState.Draft },
      // { label: 'Failed', value: PaymentState.Failed },
    ]
  },
  {
    label: 'Payment Method',
    value: 'paymentMethods',
    options: [
      { label: 'UPI', value: PaymentMethod.Upi },
      { label: 'EMI', value: PaymentMethod.Emi },
      { label: 'Card', value: PaymentMethod.Card },
      { label: 'Wallet', value: PaymentMethod.Wallet },
      { label: 'Net Banking', value: PaymentMethod.NetBanking },
      { label: 'Pay Later', value: PaymentMethod.PayLater },
      { label: 'Snapmint', value: PaymentMethod.Snapmint },
      { label: 'Online', value: PaymentMethod.Online },
      { label: 'COD', value: PaymentMethod.Cod }
    ]
  },
  {
    label: 'Payment Provider',
    value: 'paymentProviders',
    options: [
      { label: 'Razorpay', value: PaymentProvider.Razorpay },
      { label: 'Easebuzz', value: PaymentProvider.Easebuzz },
      { label: 'PhonePe', value: PaymentProvider.Phonepe },
      { label: 'Cashfree', value: PaymentProvider.Cashfree },
      { label: 'CCAvenue', value: PaymentProvider.Ccavenue },
      { label: 'PayU', value: PaymentProvider.Payu },
      { label: 'Snapmint', value: PaymentProvider.Snapmint }
      // { label: 'COD', value: PaymentProvider.CashOnDelivery }
    ]
  }
];

export const orderSortChoices = [
  {
    label: 'Sort By',
    value: 'sortBy',
    options: [
      { label: 'Created at date', value: SortOrdersBy.CreatedAt },
      { label: 'Updated at date', value: SortOrdersBy.UpdatedAt },
      { label: 'Total price', value: SortOrdersBy.TotalPrice }
      // { label: 'Expected delivery date', value: SortOrdersBy.ExpectedDeliveryDate }
    ]
  },
  {
    label: 'Sort Order',
    value: 'sortOrder',
    options: [
      { label: 'Ascending', value: SortOrder.Asc },
      { label: 'Descending', value: SortOrder.Desc }
    ]
  }
];

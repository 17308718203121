import {
  usePaymentMetricsQuery,
  usePerformanceMetricsQuery,
  useCouponPerformanceMetricsQuery,
  ComparableCouponPerformanceMetrics
} from '../../graphql';
import { DateTime } from 'luxon';
import { Card } from '../../components/flexyui';
import React, { useState, useEffect } from 'react';
import { TotalSales } from '../../components/dashboard/sales/sales';
import ContentWrapper from '../../components/layout/content-wrapper';
import { Title } from '../../components/shared/dashboard-title/title';
import { TotalOrders } from '../../components/dashboard/orders/orders';
import { DatePicker } from '../../components/shared/date-picker/date-picker';
import { AnalyticEvents, EventCategory, trackEvents } from '../../analytics';
import { CouponUsage } from '../../components/dashboard/coupon-usage/coupon-usage';
import { SalesChannels } from '../../components/dashboard/sales-channels/sales-channels';
import { PaymentMethod } from '../../components/dashboard/payment-methods/payment-methods';
import { DatePickerDisplayButton } from '../../components/shared/date-picker/date-picker-display';
import { ConversionFunnel } from '../../components/dashboard/conversion-funnel/conversion-funnel';
import { RevenuePerVisitor } from '../../components/dashboard/revenue-per-visitor/revenue-per-visitor';
import { AverageOrderValue } from '../../components/dashboard/average-order-value/average-order-value';
import { AverageSessionDuration } from '../../components/dashboard/average-session-duration/average-session-duration';

export const Dashboard: React.FC = () => {
  const today = new Date().toISOString().split('T')[0];
  const last7Days = new Date();
  last7Days.setDate(last7Days.getDate() - 7);
  const last7DaysFormatted = last7Days.toISOString().split('T')[0];

  const SESSION_STORAGE_KEY = 'flexy_date_range';
  const storedDateRange = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const initialDateRange = storedDateRange
    ? JSON.parse(storedDateRange)
    : {
        start: `${last7DaysFormatted}T00:00:00.000Z`,
        end: `${today}T23:59:59.999Z`
      };

  const [showDateRange, setShowDateRange] = useState(false);
  const [ranges, setRanges] = useState(initialDateRange);

  const handleCloseDatePicker = () => setShowDateRange(!showDateRange);

  const { data: metricData, loading: metricLoading } = usePerformanceMetricsQuery({
    variables: {
      dateRange: ranges
    }
  });

  const { data: couponPerformanceData, loading: couponPerformanceLoading } = useCouponPerformanceMetricsQuery({
    variables: {
      dateRange: ranges
    }
  });

  const { data: paymentGraphData, loading: loadingPaymentMetric } = usePaymentMetricsQuery({
    variables: {
      dateRange: ranges
    }
  });

  return (
    <>
      <ContentWrapper>
        <div className="mx-4 overflow-x-hidden">
          <Title title="Dashboard">
            <DatePickerDisplayButton
              setShowDateRange={setShowDateRange}
              showDateRange={showDateRange}
              ranges={{
                startDate: ranges.start.split('T')[0],
                endDate: ranges.end.split('T')[0]
              }}
            />
          </Title>

          <div className="mb-4 grid gap-3 sm:gap-2 grid-cols-1 lg:grid-cols-3 sm:grid-cols-2">
            <Card className="col-span-1 sm:col-span-2 row-span-2">
              <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2">
                <TotalSales metricsData={metricData?.performanceMetrics} loading={metricLoading} />
                <AverageOrderValue metricsData={metricData?.performanceMetrics} loading={metricLoading} />
                <RevenuePerVisitor metricsData={metricData?.performanceMetrics} loading={metricLoading} />
              </div>
            </Card>
            <TotalOrders metricsData={metricData?.performanceMetrics} loading={metricLoading} />
            <AverageSessionDuration metricsData={metricData?.performanceMetrics} loading={metricLoading} />
            <ConversionFunnel dateRange={ranges} />
            <CouponUsage
              couponPerformanceData={
                couponPerformanceData?.couponPerformanceMetrics as ComparableCouponPerformanceMetrics
              }
              loading={couponPerformanceLoading}
            />
            {/* <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
            {/* <UpsellingPerformanceMetrics*/}
            {/*  totalOrders={Number(metricData?.metrics?.current?.total_orders)}*/}
            {/*  metrics={UpsellingPerformanceData?.upsellingMetrics?.current}*/}
            {/*  loading={UpsellingPerformanceLoading}*/}
            {/* />*/}
            <SalesChannels dateRange={ranges} />
            <PaymentMethod paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />
            {/* <PaymentSuccessRates paymentGraphData={paymentGraphData} loading={loadingPaymentMetric} />*/}
          </div>
        </div>
      </ContentWrapper>

      <DatePicker
        open={showDateRange}
        onClose={handleCloseDatePicker}
        range={{
          startDate: ranges.start.split('T')[0],
          endDate: ranges.end.split('T')[0]
        }}
        setDateRange={(ranges: any) => {
          const newDateRange = {
            start: `${ranges.startDate}T00:00:00.000Z`,
            end: `${ranges.endDate}T23:59:59.999Z`
          };

          sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newDateRange));
          setRanges(newDateRange);

          trackEvents(EventCategory.ANALYTICS, AnalyticEvents.DATE_RANGE_CHANGED);
        }}
      />
    </>
  );
};

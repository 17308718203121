import React from 'react';
import { formatPrice } from '../../../utils/format-price';
import { CalculateLineItem, DiscountType } from '../../../graphql';

type Props = {
  data: CalculateLineItem;
  className?: string;
};

export const PriceDetails: React.FC<Props> = ({ data, className }) => {
  return (
    <div className={`text-[#121B38] ${className}`}>
      <span>
        {data?.quantity} x &#x20B9;
        {formatPrice(data?.discount?.amount_after_discount ?? data?.price ?? 0)}
      </span>
      {data?.discount?.discount_type && data?.discount?.discount_amount && (
        <span className="!text-[#595F74] opacity-70 ml-2">
          <span className="line-through">&#x20B9;{data?.price?.toFixed(2)}</span>
          <span>
            {' '}
            (Discount: -
            {data?.discount?.discount_type === DiscountType.Fixed
              ? `₹${formatPrice(data.discount.discount_amount)}`
              : `${formatPrice(data.discount.discount_amount)}%`}
            )
          </span>
        </span>
      )}
    </div>
  );
};

import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../../lib/utils';

const skeletonVariants = cva('animate-pulse bg-muted', {
  variants: {
    variant: {
      default: 'rounded-sm',
      circular: 'rounded-full',
      rectangular: 'rounded-xs',
      rounded: 'rounded-md',
      text: 'rounded-xs min-w-[100px]',
      label: 'rounded-sm'
    },
    size: {
      default: 'h-4 w-4',
      sm: 'h-4 w-5',
      icon: 'h-4 w-5',
      md: 'h-4 w-10',
      lg: 'h-4 w-32',
      xl: 'h-4 w-full',
      label: 'w-20 h-6'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});

export interface skeletonProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof skeletonVariants> {}

function Skeleton({ className, size, variant, ...props }: skeletonProps) {
  return <div className={cn(skeletonVariants({ className, size, variant }))} {...props} />;
}

export { Skeleton };

import {
  Input,
  Button,
  DropdownMenu,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Select as SelectCN,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem
} from '../../flexyui';
import classNames from 'classnames';
import { SearchField } from '../../../graphql';
import { useLocation, useSearchParams } from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import React, { FC, useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../utils/convert-currency';
import { orderFilterChoices, OrderFilterOptions, orderSortChoices } from '../../../constants/orders';

type props = {
  activeFilters: Array<{ filter: string; open: boolean }>;
  setActiveFilters: React.Dispatch<React.SetStateAction<Array<{ filter: string; open: boolean }>>>;
  filterOptions: OrderFilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<OrderFilterOptions>>;
};

export const OrderFilters: FC<props> = ({ activeFilters, setActiveFilters, filterOptions, setFilterOptions }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [enableFilters, setEnableFilters] = useState<boolean>(false);

  const availableFilters = orderFilterChoices.filter((option) => !activeFilters.some((f) => f.filter === option.value));
  const addFilter = (filterKey: string) => {
    if (!activeFilters.some((f) => f.filter === filterKey)) {
      setActiveFilters((prev) => [...prev, { filter: filterKey, open: true }]);
    }
  };
  const removeFilter = (filterKey: string) => {
    setActiveFilters((prev) => prev.filter((f) => f.filter !== filterKey));
    setFilterOptions((prev: any) => ({
      ...prev,
      [filterKey]: []
    }));
  };
  const toggleDropdown = (filterKey: string, isOpen: boolean) => {
    setActiveFilters((prev) => prev.map((f) => (f.filter === filterKey ? { ...f, open: isOpen } : f)));
  };

  const handleUserInput = (e: any, type: string) => {
    setFilterOptions((prevState: any) => {
      return {
        ...prevState,
        [type]: e.target.value
      };
    });
  };

  const handleFilterChange = (filterKey: string, value: any) => {
    setFilterOptions((prev: any) => {
      const currentValue = prev[filterKey];

      if (Array.isArray(currentValue)) {
        const updatedValues = currentValue.includes(value)
          ? currentValue.filter((v) => v !== value)
          : [...currentValue, value];

        return { ...prev, [filterKey]: updatedValues };
      }

      return { ...prev, [filterKey]: value };
    });
  };
  const handleCancelFilters = () => {
    setEnableFilters(false);
    setActiveFilters([]);
    setFilterOptions((prev) => ({
      ...prev,
      query: '',
      searchField: SearchField.All,
      paymentStatus: [],
      paymentMethods: [],
      paymentProviders: []
    }));
  };

  const removeUnderScoreAndCapitalize = (word: string) => {
    if (word.includes('_')) {
      return word
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char?.toUpperCase())
        .replace(/\B\w/g, (char) => char.toLowerCase());
    }
    return capitalizeFirstLetter(word);
  };

  useEffect(() => {
    if (searchParams.toString().length > 0) setEnableFilters(true);
  }, [searchParams]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handleCancelFilters();

      if ((event.metaKey || event.ctrlKey) && event.key === 'f') {
        event.preventDefault();
        setEnableFilters(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="w-full p-2 border-b">
      <div className="flex items-center justify-between gap-2">
        {enableFilters ? (
          <div className="flex items-center justify-between gap-2 flex-1">
            <div className="flex-1 relative">
              <Input
                placeholder="Search"
                autoFocus
                value={filterOptions.query}
                onChange={(e) => handleUserInput(e, 'query')}
                icon={<Unicons.UilSearch size={18} />}
                iconPosition="start"
                className="w-full h-8"
              />
              <SelectCN
                value={filterOptions.searchField}
                onValueChange={(value: SearchField) => {
                  setFilterOptions((prevState) => {
                    return {
                      ...prevState,
                      searchField: value
                    };
                  });
                }}
                defaultValue={SearchField.All}
              >
                <SelectTrigger className="absolute h-8 w-[100px] right-0 top-0 rounded-l-none bg-gray-50 focus:ring-0 focus:ring-offset-0">
                  <SelectValue placeholder="Search Field" />
                </SelectTrigger>
                <SelectContent side="bottom">
                  {Object.values(SearchField).map((filter) => (
                    <SelectItem key={filter} value={filter}>
                      {removeUnderScoreAndCapitalize(filter)}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectCN>
            </div>
            <Button variant="outline" size="sm" className="px-4 h-8" onClick={handleCancelFilters}>
              Cancel
            </Button>
          </div>
        ) : (
          <div className="flex items-center justify-between flex-1">
            <Button variant="ghost" size="sm" className="px-4 h-8 bg-gray-200">
              All
            </Button>
            <Button
              size="icon"
              variant="icon"
              className="flex items-center text-gray-500 h-8"
              onClick={() => setEnableFilters(true)}
            >
              <Unicons.UilSearch size={22} className="p-[2px]" />
              <Unicons.UilFilter size={22} className="p-[2px]" />
            </Button>
          </div>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="icon" className="h-8">
              <Unicons.UilExchange size={22} className="p-[2px] text-gray-500 rotate-90" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {orderSortChoices.map(({ value: groupValue, options }, index) => (
              <React.Fragment key={groupValue}>
                <DropdownMenuRadioGroup
                  value={filterOptions[groupValue as keyof OrderFilterOptions] as string}
                  onValueChange={(val) => handleFilterChange(groupValue, val)}
                >
                  {options.map(({ label, value }) => (
                    <DropdownMenuRadioItem key={value} value={value} className="mb-0.5 cursor-pointer">
                      {label}
                    </DropdownMenuRadioItem>
                  ))}
                </DropdownMenuRadioGroup>
                {index === 0 && <DropdownMenuSeparator />}
              </React.Fragment>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div
        className={classNames(
          'w-full flex items-center gap-2 overflow-x-scroll scrollbar-hide transition-all duration-200',
          {
            'h-[28px] mt-2 opacity-100': enableFilters,
            'h-0 opacity-0': !enableFilters,
            hidden: location.pathname === '/cart'
          }
        )}
      >
        {activeFilters.map((filterData) => {
          const filterKey = filterData.filter as keyof OrderFilterOptions;
          const filter = orderFilterChoices.find((f) => f.value === filterKey);

          if (!filter) return <></>;

          const selectedValues = filterOptions[filter.value as keyof OrderFilterOptions];

          const selectedLabels =
            Array.isArray(selectedValues) && selectedValues.length > 0
              ? selectedValues.map((val) => filter.options.find((opt) => opt.value === val)?.label || val).join(', ')
              : filter.label;

          return (
            <DropdownMenu
              key={filterKey}
              open={!!activeFilters.find((f) => f.filter === filterKey)?.open}
              onOpenChange={(isOpen) => toggleDropdown(filterKey, isOpen)}
            >
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="min-w-fit w-fit flex items-center justify-between gap-2 h-7 text-xs px-2 font-normal !ring-offset-0 !ring-0"
                >
                  <div className="text-xs">{selectedLabels}</div>
                  <Unicons.UilAngleDown size={18} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {filter.options.map((option: { label: string; value: string }) => (
                  <DropdownMenuCheckboxItem
                    key={option.value}
                    checked={(filterOptions[filterKey] as unknown as any[]).includes(option.value)}
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilterChange(filterKey, option.value);
                    }}
                    className={classNames('mb-0.5 cursor-pointer', {
                      'bg-slate-200 hover:bg-slate-200': (filterOptions[filterKey] as unknown as any[]).includes(
                        option.value
                      )
                    })}
                  >
                    {option.label}
                  </DropdownMenuCheckboxItem>
                ))}
                <DropdownMenuSeparator />
                <div className="flex justify-end py-1 px-2">
                  <div
                    className="cursor-pointer text-red-500 text-xs hover:underline"
                    onClick={() => removeFilter(filterKey)}
                  >
                    Clear
                  </div>
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        })}
        <DropdownMenu>
          <DropdownMenuTrigger asChild disabled={availableFilters.length === 0}>
            <Button
              variant="outline"
              className="min-w-fit w-fit outline-0 flex items-center gap-2 h-7 text-xs px-2 font-normal"
            >
              <div>Add Filter</div>
              <Unicons.UilPlus size={18} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {availableFilters.map((option) => (
              <DropdownMenuItem key={option.value} onSelect={() => addFilter(option.value)}>
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';
import { ShippingZoneType } from '../../graphql';
import * as Unicons from '@iconscout/react-unicons';
import { Countries } from '../../constants/countries';
import { ButtonWithInfo } from '../flexyui/ButtonWithInfo';
import { getZoneExtraPart, getZoneTitle } from '../../utils/shipping';

type Props = {
  zone: any;
};

export const ZoneTableTitle: React.FC<Props> = ({ zone }) => {
  const hasOneCountry = zone?.zone_codes?.length === 1;
  const singleCountry = zone?.zone_codes?.[0];
  const singleCountryData = Countries.find((country: any) => country.code === singleCountry?.country_code);
  const totalSelectedStates = zone?.zone_codes?.[0]?.states?.length || 0;
  const totalStates = singleCountryData?.zones?.length || 0;
  const hasPartialStates = totalStates > 0 && totalSelectedStates && totalStates !== totalSelectedStates;

  return (
    <div className="flex items-center">
      {zone.type === ShippingZoneType.StateCountry ? (
        hasOneCountry ? (
          <div className="rounded overflow-hidden mr-3 flex" style={{ width: '33px', height: '23px' }}>
            <img
              src={`https://flagcdn.com/${zone?.zone_codes?.[0].country_code.toLowerCase()}.svg`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              alt={`${zone?.zone_codes?.[0].country} flag`}
            />
          </div>
        ) : (
          <Unicons.UilGlobe size={26} className="mr-2 min-w-[26px]" />
        )
      ) : (
        <Unicons.UilMapPinAlt size={26} className="mr-2 min-w-[26px]" />
      )}
      <div className="font-medium mr-2">{zone.name}</div>
      {!!getZoneTitle(zone) && (
        <>
          <div className="mr-2">•</div>
          <div className="min-w-fit w-fit">{getZoneTitle(zone)}</div>
          {hasPartialStates && (
            <div className="ml-2">
              ({totalSelectedStates} of {totalStates} {singleCountryData?.provinceKey?.toLowerCase() || 'state'}s)
            </div>
          )}
        </>
      )}
      {getZoneExtraPart(zone)?.length > 0 && (
        <>
          {','}
          <ButtonWithInfo
            heading={getZoneExtraPart(zone)?.join(', ')}
            description=""
            simple={true}
            side="bottom"
            className={classNames('!py-2 !px-3 !font-normal', {
              'w-[100px] max-w-[100px]': getZoneExtraPart(zone)?.length === 2,
              'w-[200px] max-w-[200px]': getZoneExtraPart(zone)?.length === 4,
              'w-[300px] max-w-[300px]': getZoneExtraPart(zone)?.length > 4
            })}
          >
            <div className="cursor-pointer underline ml-1">{getZoneExtraPart(zone)?.length} more</div>
          </ButtonWithInfo>
        </>
      )}
    </div>
  );
};

import {
  SearchField,
  SortOrder,
  SortOrdersBy,
  PaymentState,
  PaymentMethod,
  PaymentProvider,
  useOrdersLazyQuery
} from '../../graphql';
import dayjs from 'dayjs';
import debounce from 'debounce';
import { ColumnDef } from '@tanstack/react-table';
import { getSessionState } from '../../utils/orders';
import { formatPrice } from '../../utils/format-price';
import { OrderFilterOptions } from '../../constants/orders';
import { convertCountry } from '../../utils/convert-currency';
import { CopyEmail } from '../../components/orders/copy-email';
import React, { useEffect, useState, useCallback } from 'react';
import { Title } from '../../components/shared/dashboard-title/title';
import { DataTable, DataTableColumnHeader } from '../../components/flexyui';
import { DatePicker } from '../../components/shared/date-picker/date-picker';
import { OrderFilters } from '../../components/orders/order-filters/order-filters';
import { SessionState } from '../../components/orders/session-state/session-state';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PaymentStatus } from '../../components/orders/payment-status/payment-status';
import { AnalyticEvents, EventCategory, OrderEvents, trackEvents } from '../../analytics';
import { DatePickerDisplayButton } from '../../components/shared/date-picker/date-picker-display';

export const Orders: React.FC = () => {
  const orderColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'order_id',
      enablePinning: true,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Order ID" className="w-full flex justify-start" />
      ),
      cell: ({ row: order }) => {
        return (
          <div className="py-1.5 px-3">
            <Link
              to={order.original.row?.platform_order_admin_url}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER_IN_SHOPIFY);
              }}
              className="text-primary font-semibold hover:underline"
            >
              {order.original?.row?.name}
            </Link>
          </div>
        );
      }
    },
    {
      accessorKey: 'customer',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" className="" />,
      cell: ({ row: order }) => {
        return (
          <div className="py-1.5 px-3">
            {order?.original.row.customer?.first_name} {order?.original.row.customer?.last_name}
          </div>
        );
      }
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" className="" />,
      cell: ({ row: order }) => {
        return <CopyEmail email={order?.original.email} />;
      }
    },
    {
      accessorKey: 'phone',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Phone" className="" />,
      cell: ({ row: order }) => {
        return (
          <div className="py-1.5 px-3">
            {order.original.row.customer?.dial_code ? `+${order.original.row.customer.dial_code}` : ''}{' '}
            {order.original?.phone ? order.original.phone : <div className="text-center">-</div>}
          </div>
        );
      }
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row: order }) => {
        return (
          <div className="text-right min-w-fit py-1.5 px-3 flex justify-end">
            <div>{convertCountry(order.original.row.currency)}</div>
            <div>{formatPrice(order.original.row.total_amount)}</div>
          </div>
        );
      }
    },
    {
      accessorKey: 'payment-mode',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Payment Mode" className="flex justify-center" />
      ),
      id: 'Payment Mode',
      cell: ({ row: order }) => {
        return <div className="text-center py-1.5 px-3">{order.original['payment-mode']}</div>;
      }
    },
    {
      accessorKey: 'payment-status',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Payment Status" className="flex justify-center" />
      ),
      id: 'Payment status',
      cell: ({ row: order }) => {
        return (
          <div className="flex py-1.5 px-3 justify-center">
            <PaymentStatus text={order.original['payment-status']} />
          </div>
        );
      }
    },
    {
      accessorKey: 'date-created',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date Created" className="!" />,
      id: 'Date created',
      cell: ({ row }) => {
        return <div className="py-1.5 px-3">{dayjs(row.original.row.created_at).format('MMM D YYYY, hh:mm A')}</div>;
      }
    }
  ];
  const cartColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'order_id',
      enablePinning: true,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Checkout ID" className="w-full flex justify-start" />
      ),
      cell: ({ row: order }) => {
        return <div className="py-1.5 px-3 font-semibold">#{order.original.row.id.slice(0, 18).toUpperCase()}</div>;
      }
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" className="" />,
      cell: ({ row: order }) => {
        return <CopyEmail email={order?.original.email} />;
      }
    },
    {
      accessorKey: 'phone',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Phone" className="" />,
      cell: ({ row: order }) => {
        return (
          <div className="py-1.5 px-3">
            {order.original.row.customer?.dial_code ? `+${order.original.row.customer.dial_code}` : ''}{' '}
            {order.original?.phone ? order.original.phone : <div className="text-center">-</div>}
          </div>
        );
      }
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row: order }) => {
        return (
          <div className="text-right min-w-fit py-1.5 px-3 flex justify-end">
            <div>{convertCountry(order.original.row.currency)}</div>
            <div>{formatPrice(order.original.row.total_amount)}</div>
          </div>
        );
      }
    },
    {
      accessorKey: 'payment-status',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Session State" className="flex justify-center" />
      ),
      id: 'Payment status',
      cell: ({ row: order }) => {
        return (
          <div className="flex py-1.5 px-3 justify-center">
            <SessionState state={order.original.row.session_state} />
          </div>
        );
      }
    },
    {
      accessorKey: 'date-created',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date Created" className="!" />,
      id: 'Date created',
      cell: ({ row }) => {
        return <div className="py-1.5 px-3">{dayjs(row.original.row.created_at).format('MMM D YYYY, hh:mm A')}</div>;
      }
    }
  ];

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<any>(null);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [showDateRange, setShowDateRange] = useState(false);

  const today = new Date().toISOString().split('T')[0];
  const last7Days = new Date();
  last7Days.setDate(last7Days.getDate() - 7);
  const last7DaysFormatted = last7Days.toISOString().split('T')[0];

  const SESSION_STORAGE_KEY = 'flexy_date_range';
  const storedDateRange = sessionStorage.getItem(SESSION_STORAGE_KEY);
  const initialDateRange = storedDateRange
    ? JSON.parse(storedDateRange)
    : {
        start: `${last7DaysFormatted}T00:00:00.000Z`,
        end: `${today}T23:59:59.999Z`
      };

  const location = useLocation();
  const sessionState = getSessionState(location.pathname);

  const [activeFilters, setActiveFilters] = useState<Array<{ filter: string; open: boolean }>>([]);
  const [filterOptions, setFilterOptions] = useState<OrderFilterOptions>({
    query: searchParams.get('query') || '',
    searchField: (searchParams.get('searchField') as SearchField) || SearchField.All,
    sessionState,
    paymentStatus: searchParams.get('paymentStatus')
      ? (searchParams.get('paymentStatus')?.split(',') as PaymentState[])
      : [],
    paymentMethods: searchParams.get('paymentMethods')
      ? (searchParams.get('paymentMethods')?.split(',') as PaymentMethod[])
      : [],
    paymentProviders: searchParams.get('paymentProviders')
      ? (searchParams.get('paymentProviders')?.split(',') as PaymentProvider[])
      : [],
    dateRange: initialDateRange,
    sortBy: (searchParams.get('sortBy') as SortOrdersBy) || SortOrdersBy.CreatedAt,
    sortOrder: (searchParams.get('sortOrder') as SortOrder) || SortOrder.Desc
  });

  const [getOrders, { data: orderData }] = useOrdersLazyQuery();

  const handleChangeRowsPerPageByValue = (value: any) => {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  };

  const handleCloseDatePicker = () => {
    setShowDateRange(!showDateRange);
  };

  const onRowClickHandler = (order: any) => {
    navigate(`${location.pathname}/${order.row.id}?${searchParams}`);

    trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER);
  };

  useEffect(() => {
    const rowData = orderData?.orders.data.map((row) => {
      return {
        order_id: row.name,
        customer: row.customer?.last_name,
        email: row.customer?.email,
        phone: row.customer?.phone,
        amount: row.total_amount,
        'payment-mode': row.payment_method
          ? row.payment_method?.toUpperCase()
          : row.payment_provider === 'CASH_ON_DELIVERY'
            ? 'COD'
            : '-',
        'payment-status': row.payment_state,
        'date-created': row.created_at,
        row
      };
    });
    setRows(rowData);
  }, [orderData]);

  const handleSearch = (searchParams: OrderFilterOptions, skip: number, limit: number) => {
    getOrders({
      variables: {
        skip,
        limit,
        query: searchParams.query,
        searchField: searchParams.searchField,
        sessionState,
        paymentStatus: searchParams.paymentStatus,
        paymentMethods: searchParams.paymentMethods,
        paymentProviders: searchParams.paymentProviders,
        dateRange: {
          start: searchParams.dateRange.start,
          end: searchParams.dateRange.end
        },
        sortBy: searchParams.sortBy,
        sortOrder: searchParams.sortOrder
      }
    });

    trackEvents(EventCategory.ORDERS, OrderEvents.FILTERED_SEARCH, {
      query: searchParams.query,
      searchField: searchParams.searchField,
      sessionState,
      paymentStatus: searchParams.paymentStatus,
      paymentMethods: searchParams.paymentMethods,
      paymentProviders: searchParams.paymentProviders,
      dateRange: {
        start: searchParams.dateRange.start,
        end: searchParams.dateRange.end
      },
      sortBy: searchParams.sortBy,
      sortOrder: searchParams.sortOrder
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    const params: Record<string, string> = {};
    const activeFiltersArray: Array<{ filter: string; open: boolean }> = [];

    if (filterOptions.query) params.query = filterOptions.query;
    if (filterOptions.searchField !== SearchField.All) params.searchField = filterOptions.searchField;
    if (filterOptions.sortBy !== SortOrdersBy.CreatedAt) params.sortBy = filterOptions.sortBy;
    if (filterOptions.sortOrder !== SortOrder.Desc) params.sortOrder = filterOptions.sortOrder;
    if (filterOptions.paymentStatus.length) {
      params.paymentStatus = filterOptions.paymentStatus.join(',');
      activeFiltersArray.push({
        filter: 'paymentStatus',
        open: activeFilters.find((each) => each.filter === 'paymentStatus')?.open || false
      });
    }
    if (filterOptions.paymentMethods.length) {
      params.paymentMethods = filterOptions.paymentMethods.join(',');
      activeFiltersArray.push({
        filter: 'paymentMethods',
        open: activeFilters.find((each) => each.filter === 'paymentMethods')?.open || false
      });
    }
    if (filterOptions.paymentProviders.length) {
      params.paymentProviders = filterOptions.paymentProviders.join(',');
      activeFiltersArray.push({
        filter: 'paymentProviders',
        open: activeFilters.find((each) => each.filter === 'paymentProviders')?.open || false
      });
    }

    setSearchParams(params);
    setActiveFilters(activeFiltersArray);

    setPage(0);
    debouncedSearch(filterOptions, 0, rowPerPage);
  }, [filterOptions]);

  useEffect(() => {
    debouncedSearch(filterOptions, rowPerPage * page, rowPerPage);
  }, [rowPerPage, page]);

  return (
    <>
      <div className="px-4">
        <Title title={location.pathname === '/orders' ? 'Orders' : 'Abandoned Cart'}>
          <DatePickerDisplayButton
            setShowDateRange={setShowDateRange}
            showDateRange={showDateRange}
            ranges={{
              startDate: filterOptions.dateRange.start.split('T')[0],
              endDate: filterOptions.dateRange.end.split('T')[0]
            }}
          />
        </Title>

        <div className="mt-2">
          <DataTable
            columnData={location.pathname === '/orders' ? orderColumns : cartColumns}
            data={rows}
            showFilterInput={true}
            showPagination={true}
            onRowClick={onRowClickHandler}
            enableRowSelection={false}
            rowsPerPageOptions={[25, 50, 100]}
            rowsPerPage={rowPerPage}
            count={orderData?.orders?.page_info?.total_count || 0}
            page={page}
            setPage={setPage}
            onRowsPerPageChange={handleChangeRowsPerPageByValue}
            filterContent={
              <OrderFilters
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
              />
            }
          />
        </div>
      </div>

      <DatePicker
        open={showDateRange}
        onClose={handleCloseDatePicker}
        range={{
          startDate: filterOptions.dateRange.start.split('T')[0],
          endDate: filterOptions.dateRange.end.split('T')[0]
        }}
        setDateRange={(ranges: any) => {
          const newDateRange = {
            start: `${ranges.startDate}T00:00:00.000Z`,
            end: `${ranges.endDate}T23:59:59.999Z`
          };

          sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newDateRange));
          setFilterOptions((previous) => ({
            ...previous,
            dateRange: newDateRange
          }));

          trackEvents(EventCategory.ANALYTICS, AnalyticEvents.DATE_RANGE_CHANGED);
        }}
      />
    </>
  );
};

import { Separator, Skeleton } from '../../../flexyui';

const GeneralTabLoading = () => {
  return (
    <div>
      <Skeleton variant="label" size="label" />
      <div className="flex mt-3">
        <Skeleton className="w-[150px] h-10 p-3 mr-6" />
        <Skeleton className="w-[284px] h-[104px]" />
      </div>
      <Separator />
      <Skeleton variant="label" size="label" />
      <div className="flex mt-3 gap-8">
        {[1, 2].map((_, index) => (
          <div className="flex gap-2" key={index}>
            <Skeleton className="h-10" size="md" />
            <div>
              <Skeleton variant="text" size="md" />
              <Skeleton size="sm" className="w-16 mt-2" />
            </div>
          </div>
        ))}
      </div>
      <Separator />
      <Skeleton variant="label" size="label" />
      <div className="flex mt-3 items-center space-x-2">
        <Skeleton className="w-[213px] h-[80px]" />
        <Skeleton className="w-[213px] h-[80px]" />
      </div>
      <Separator />
      <Skeleton variant="label" size="label" />
      <div className="mt-3 space-y-2">
        {[1, 2, 3].map((_, index) => (
          <Skeleton key={index} variant="rounded" size="xl" className="h-10" />
        ))}
      </div>
      <Separator />
      <div className="flex items-center justify-end">
        <Skeleton className="w-[130px] h-[40px]" />
        <Skeleton className="w-[130px] h-[40px]" />
      </div>
    </div>
  );
};

export default GeneralTabLoading;

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from '../../flexyui';
import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { NotificationCard } from './notification-card';
import { useGetNotificationsQuery } from '../../../graphql';
import useScreenSize from '../../../hooks/use-screen-size';

export const Notifications = () => {
  const isMobile = useScreenSize().width < 640;
  const { data, refetch } = useGetNotificationsQuery({ variables: { limit: 3 } });

  const unreadCount =
    data?.getNotifications?.notifications?.filter((notification) => !notification.is_read).length || 0;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="relative focus:outline-0 flex items-center gap-1.5 p-1.5 rounded-xl border border-transparent transition-all ease-in-out cursor-pointer hover:border-gray-100 hover:shadow-md data-[state=open]:border-gray-100 data-[state=open]:shadow-md">
        {unreadCount > 0 && (
          <div className="absolute top-1 right-1 rounded-full bg-red-600 text-white text-[8px] h-3 w-3">
            {unreadCount}
          </div>
        )}
        <Unicons.UilBell size={20} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={6}
        className="!z-[1500] w-[100vw] sm:w-[380px]"
        align={isMobile ? 'center' : 'end'}
      >
        {data &&
          data.getNotifications.notifications.map((notification) => (
            <React.Fragment>
              <NotificationCard notificationDetails={notification} refetch={refetch} />
              <DropdownMenuSeparator />
            </React.Fragment>
          ))}
        <DropdownMenuItem disabled className="w-full py-2 flex items-center font-semibold text-gray-500">
          <div className="w-full text-center">No more alerts</div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateUpSellingProductsV2Mutation, useUpSellingConfigV2Query } from '../../graphql';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { ColumnDef } from '@tanstack/table-core';
import {
  Button,
  Chip,
  DataTableColumnHeader,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../components/flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { UpsellingProductTable } from '../../components/upsell/upselling-product-table';
import { enqueueSnackbar } from 'notistack';
import { isEqual } from 'lodash';
import { ExpandedState } from '@tanstack/react-table';
import { ProductSelectionDialog } from '../../components/shared/product-selection/product-selection';
import { EventCategory, trackEvents, UpsellingEvents } from '../../analytics';

type props = {
  productModal: boolean;
  setProductModal: (data: boolean) => void;
  productSearchId: number | null;
  setProductSearchId: (data: any) => void;
};

export const UpsellingProduct: React.FC<props> = ({
  productModal,
  setProductModal,
  productSearchId,
  setProductSearchId
}) => {
  const [products, setProducts] = useState<Array<any>>([]);
  const [originalProducts, setOriginalProducts] = useState<Array<any>>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { data, refetch, loading } = useUpSellingConfigV2Query();
  const [updateUpSellingProducts] = useUpdateUpSellingProductsV2Mutation();
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();

  const handleProducts = () => {
    const pinProducts = data?.upSellingConfigV2?.products || [];
    setProducts(pinProducts);
    setOriginalProducts(pinProducts);
  };

  useEffect(() => {
    handleProducts();
  }, [data, data?.upSellingConfigV2?.products]);

  const findProductByVariantId = (variantId: number) => {
    const product = products.find((product) =>
      product.variants.some((variant: any) => variant.variant_id === variantId)
    );
    return product || null;
  };

  const handleDeleteProduct = (productId: number) => {
    const updatedProducts = products.filter((product) => product.product_id !== productId);

    setProducts([...updatedProducts]);
  };

  const handleDeleteVariant = (variantId: number) => {
    const parentProduct = findProductByVariantId(variantId);

    if (parentProduct.variants.length === 1) handleDeleteProduct(parentProduct.product_id);
    else {
      setProducts((prevProducts: any) => {
        return prevProducts.map((product: any) => {
          if (product.product_id === parentProduct.product_id) {
            const updatedVariants = product.variants.filter((variant: any) => variant.variant_id !== variantId);
            return { ...product, variants: updatedVariants };
          }
          return product;
        });
      });
    }
  };

  const handleCancel = useCallback(() => {
    setExpanded({});
    handleProducts();
    setLoadingActions(false);
    setShowActions(false);
  }, [data?.upSellingConfigV2?.products]);

  const handleSave = async () => {
    setLoadingActions(true);
    try {
      await updateUpSellingProducts({
        variables: {
          products: products.map((product, index) => {
            return {
              product_id: product.product_id,
              product_position: index,
              variants: product?.variants?.map((variant: any) => variant.variant_id)
            };
          })
        }
      });
      await refetch();
      setLoadingActions(false);
      setShowActions(false);
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
  };

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, [handleCancel]);

  useEffect(() => {
    const hasChanges = !isEqual(originalProducts, products);
    if (hasChanges) {
      if (!loading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [originalProducts, products, saveButtonClicked, cancelButtonClicked]);

  const mainColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'product_image',
      header: () => <div />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3 flex justify-left">
            {row.original.image_src ? (
              <img src={row.original.image_src} loading="lazy" className="h-[42px] min-w-[42px] w-[42px] rounded-md" />
            ) : (
              <div className="border rounded-lg border-black-200 border-solid p-2">
                <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
              </div>
            )}
          </div>
        );
      }
    },
    {
      accessorKey: 'product_title',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Product" />,
      cell: ({ row }) => {
        return <div className="py-1.5 px-3">{row.original.title}</div>;
      }
    },
    {
      accessorKey: 'product_inventory',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Inventory" />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3">
            {row.original.variants.reduce((acc: number, variant: any) => {
              return acc + variant.inventory_quantity;
            }, 0)}{' '}
            in stock for {row.original.variants.length} {row.original.variants.length > 0 ? 'variants' : 'variant'}
          </div>
        );
      }
    },
    {
      accessorKey: 'product_status',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3 flex justify-center">
            <Chip
              text={row.original.status === 'active' ? 'Active' : 'Draft'}
              variant={row.original.status === 'active' ? 'success' : 'draft'}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'product_selected_variants',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Selected variants" className="flex justify-end" />
      ),
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3  flex justify-end">
            {row.original.variants.length}/{row.original.total_variants}
          </div>
        );
      }
    },
    {
      accessorKey: 'product_actions',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Actions" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1 px-3">
            <DropdownMenu>
              <DropdownMenuTrigger className="focus:outline-0">
                <Button size="icon" variant="icon">
                  <Unicons.UilEllipsisH size={22} className="p-[2px]" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-36 !z-[1500]" sideOffset={0} align="end">
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setProductSearchId(row.original.product_id);

                    trackEvents(EventCategory.UPSELLING, UpsellingEvents.EDIT_PRODUCT_CLICKED);
                  }}
                >
                  Edit product
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDeleteProduct(row.original.product_id);

                    trackEvents(EventCategory.UPSELLING, UpsellingEvents.REMOVE_PRODUCT);
                  }}
                >
                  <div className="text-red-500">Remove product</div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            {row.getCanExpand() && (
              <Button
                size="icon"
                variant="icon"
                className="ml-2"
                onClick={() => {
                  row.getToggleExpandedHandler();
                  trackEvents(EventCategory.UPSELLING, UpsellingEvents.VIEW_VARIANTS);
                }}
              >
                <Unicons.UilAngleRightB
                  size={'22'}
                  className={`p-[2px] transition ${row.getIsExpanded() && 'rotate-90'}`}
                />
              </Button>
            )}
          </div>
        );
      }
    }
  ];

  const subRowColumns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'empty_cell_1',
      header: () => <div />,
      cell: () => <div className="h-[42px] w-[42px]"></div>
    },
    {
      accessorKey: 'variant_title',
      header: () => <DataTableColumnHeader title="Variant" />,
      cell: ({ row }) => (
        <div className="py-1.5 px-3 flex items-center">
          {row.original.image_src || row.original.variant_image_src ? (
            <img
              src={row.original.image_src || row.original.variant_image_src}
              loading="lazy"
              className="h-[42px] rounded-md"
            />
          ) : (
            <div className="border rounded-lg border-black-200 border-solid p-2">
              <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
            </div>
          )}
          <div className="ml-4">{row.original.title || row.original.variant_title}</div>
        </div>
      )
    },
    {
      accessorKey: 'variant_available',
      header: () => <DataTableColumnHeader title="Available" />,
      cell: ({ row }) => {
        return (
          <div className="py-1.5 px-3">{row.original.inventory_quantity ? row.original.inventory_quantity : '-'}</div>
        );
      }
    },
    {
      accessorKey: 'variant_price',
      header: () => <DataTableColumnHeader title="Price" className="flex justify-center" />,
      cell: ({ row }) => {
        return <div className="py-1.5 px-3 flex justify-center">₹{row.original.price}</div>;
      }
    },
    {
      accessorKey: 'empty_cell_2',
      header: () => <div />,
      cell: () => <div className="h-[42px] w-[42px]"></div>
    },
    {
      accessorKey: 'variant_actions',
      header: () => <div />,
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1 px-3">
            <Button
              size="icon"
              variant="icon"
              className="hover:text-red-500"
              onClick={() => {
                handleDeleteVariant(row.original.variant_id);
              }}
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <UpsellingProductTable
        data={products}
        setProducts={setProducts}
        mainColumns={mainColumns}
        subRowColumns={subRowColumns}
        expanded={expanded}
        setExpanded={setExpanded}
        loading={loading}
      />
      <ProductSelectionDialog
        key={'UpsellingProductSelection'}
        open={productModal}
        setOpen={(val: boolean) => {
          setProductModal(val);
        }}
        searchId={productSearchId}
        preSelections={products}
        onSave={(productsData) => {
          setProducts(productsData);
          trackEvents(EventCategory.UPSELLING, UpsellingEvents.ADD_PRODUCT);
        }}
      />
    </>
  );
};

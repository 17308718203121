import React, { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { DateTimePicker } from '../../../flexyui/DateTimePicker';
import { Coupon, PurchaseActivityType } from '../../../../graphql';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { Button, Card, Input, Label, RadioGroup } from '../../../flexyui';
import { CouponSelection } from '../../coupon-selection/coupon-selection';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';
import { formatCouponType, InfoCardBehaviour } from '../../../../constants/coupons';
import classNames from 'classnames';

export const DiscountUserBehaviour = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  const [selectedCouponData, setSelectedCouponData] = useState<Coupon>(values?.purchase_activity?.coupon_usage?.coupon);
  const [openCouponSelectionDialog, setOpenCouponSelectionDialog] = useState<boolean>(false);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardBehaviour[1]} />
        <div>
          <div className="font-semibold text-base block sm:hidden">Behaviour period</div>
          <div className="flex flex-col gap-0 xl:flex-row xl:gap-4 mb-6">
            <div className="w-[222px]">
              <div className="font-medium mt-4 mb-1">Start Date & Time</div>
              <Field name="purchase_activity_period.start">
                {({ field, form }: any) => {
                  return (
                    <DateTimePicker
                      initialDate={new Date(field.value)}
                      onDateChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      error={!!errors.purchase_activity_period?.start}
                      errorMessage={errors.purchase_activity_period?.start}
                    />
                  );
                }}
              </Field>
            </div>
            {values.purchase_activity_period.has_endTime && (
              <div className="w-[222px]">
                <div className="font-medium mt-4 mb-1">End Date & Time</div>
                <Field name="purchase_activity_period.end">
                  {({ field, form }: any) => (
                    <DateTimePicker
                      initialDate={new Date(field.value)}
                      onDateChange={(value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      error={!!errors.purchase_activity_period?.end}
                      errorMessage={errors.purchase_activity_period?.end}
                    />
                  )}
                </Field>
              </div>
            )}
          </div>

          <CheckboxWithLabel
            label="Set end date"
            value="purchase_activity_period.has_endTime"
            checked={values.purchase_activity_period.has_endTime}
            onClick={() =>
              setFieldValue('purchase_activity_period.has_endTime', !values.purchase_activity_period.has_endTime)
            }
          />
        </div>
      </div>
      <hr />

      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardBehaviour[0]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Customer behaviour</div>
          <RadioGroup {...getFieldProps('purchase_activity.type')} defaultValue={PurchaseActivityType.CouponCode}>
            <RadioWithLabel
              label="Use coupon codes"
              value={PurchaseActivityType.CouponCode}
              onClick={() => setFieldValue('purchase_activity.type', PurchaseActivityType.CouponCode)}
            />
            <RadioWithLabel
              label="Minimum order amount"
              value={PurchaseActivityType.OrderAmount}
              onClick={() => setFieldValue('purchase_activity.type', PurchaseActivityType.OrderAmount)}
            />
            <RadioWithLabel
              label="Minimum number of orders"
              value={PurchaseActivityType.OrderCount}
              onClick={() => setFieldValue('purchase_activity.type', PurchaseActivityType.OrderCount)}
            />
          </RadioGroup>
          {values?.purchase_activity?.type === PurchaseActivityType.CouponCode && (
            <>
              <Card
                className={classNames('p-3 mt-4 rounded-lg border', {
                  'border-red-600':
                    touched.purchase_activity?.selected_coupon_id && !!errors.purchase_activity?.selected_coupon_id
                })}
              >
                <div className="flex justify-between items-center">
                  <div className="flex flex-col">
                    <Label>{selectedCouponData ? selectedCouponData?.code : 'Coupon Code'}</Label>
                    <Label size={'sm'} className="text-gray-400">
                      {selectedCouponData
                        ? `${formatCouponType(selectedCouponData?.coupon_type)} Discount`
                        : 'Coupon type'}
                    </Label>
                  </div>
                  <Button
                    variant={'outline'}
                    size={'md'}
                    onClick={() => {
                      setOpenCouponSelectionDialog(true);
                    }}
                  >
                    Select Coupon
                  </Button>
                </div>
              </Card>
              {touched.purchase_activity?.selected_coupon_id && !!errors.purchase_activity?.selected_coupon_id && (
                <p className="text-red-600 text-xs mt-1 ml-0.5">{errors.purchase_activity?.selected_coupon_id}</p>
              )}
              <CouponSelection
                open={openCouponSelectionDialog}
                setOpen={setOpenCouponSelectionDialog}
                onSave={(value) => {
                  setSelectedCouponData(value);
                  setFieldValue('purchase_activity.coupon_usage.coupon', value);
                  setFieldValue('purchase_activity.selected_coupon_id', value.id);
                }}
                preSelections={selectedCouponData?.code || ''}
                key={'Coupon selection'}
              />
            </>
          )}
          {values.purchase_activity.type === PurchaseActivityType.OrderAmount && (
            <div className="w-[150px] mt-4">
              <div className="font-medium mb-1">Amount</div>
              <Input
                {...getFieldProps('purchase_activity.min_amount')}
                type="number"
                iconPosition="start"
                icon={<Unicons.UilRupeeSign size={15} />}
                error={touched.purchase_activity?.min_amount && !!errors.purchase_activity?.min_amount}
                errorMessage={errors.purchase_activity?.min_amount}
              />
            </div>
          )}
          {values.purchase_activity.type === PurchaseActivityType.OrderCount && (
            <div className="w-[150px] mt-4">
              <div className="font-medium mb-1">Number of orders</div>
              <Input
                {...getFieldProps('purchase_activity.min_quantity')}
                type="number"
                error={touched.purchase_activity?.min_quantity && !!errors.purchase_activity?.min_quantity}
                errorMessage={errors.purchase_activity?.min_quantity}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

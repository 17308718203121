import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Button,
  Input,
  RadioGroup,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { DiscountFields } from '../../../../types/coupon-data-types';
import SelectedProducts from '../../selected-products/selected-products';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountType, PurchaseRequirementType } from '../../../../graphql';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';
import SelectedCollections from '../../selected-collections/selected-collections';
import { ProductSelectionDialog } from '../../product-selection/product-selection';
import { InfoCardBXGYApplication, ItemsFrom } from '../../../../constants/coupons';
import { CollectionSelectionDialog } from '../../collection-selection/collection-selection';

export const DiscountBxgyCondition = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  const [openProductSelectionBuys, setOpenProductSelectionBuys] = useState(false);
  const [openProductSelectionGets, setOpenProductSelectionGets] = useState(false);
  const [searchCustomerBuys, setSearchCustomerBuys] = useState('');
  const [searchCustomerGets, setSearchCustomerGets] = useState('');
  const [openCollectionSelectionBuys, setOpenCollectionSelectionBuys] = useState(false);
  const [openCollectionSelectionGets, setOpenCollectionSelectionGets] = useState(false);

  useEffect(() => {
    if (values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS && searchCustomerBuys.length > 0) {
      setOpenProductSelectionBuys(true);
    } else if (
      values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION &&
      searchCustomerBuys.length > 0
    ) {
      setOpenCollectionSelectionBuys(true);
    } else {
      setOpenProductSelectionBuys(false);
      setOpenCollectionSelectionBuys(false);
    }
  }, [searchCustomerBuys]);

  useEffect(() => {
    if (values.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS && searchCustomerGets.length > 0) {
      setOpenProductSelectionGets(true);
    } else if (values.application.items_from === ItemsFrom.SPECIFIC_COLLECTION && searchCustomerGets.length > 0) {
      setOpenCollectionSelectionGets(true);
    } else {
      setOpenProductSelectionGets(false);
      setOpenCollectionSelectionGets(false);
    }
  }, [searchCustomerGets]);

  useEffect(() => {
    if (values.purchase_requirement.type === 'NONE') {
      setFieldValue('purchase_requirement.type', PurchaseRequirementType.PurchaseQuantity);
    }
    if (values.application.discount_type === 'FREE') {
      setFieldValue('application.discount_amount', 100);
    }
  }, [values]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardBXGYApplication[0]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Customer buys</div>
          <RadioGroup
            {...getFieldProps('purchase_requirement.type')}
            defaultValue={PurchaseRequirementType.PurchaseQuantity}
          >
            <RadioWithLabel
              label="Minimum quantity of items"
              value={PurchaseRequirementType.PurchaseQuantity}
              onClick={() => setFieldValue('purchase_requirement.type', PurchaseRequirementType.PurchaseQuantity)}
            />
            <RadioWithLabel
              label="Minimum purchase amount"
              value={PurchaseRequirementType.PurchaseAmount}
              onClick={() => setFieldValue('purchase_requirement.type', PurchaseRequirementType.PurchaseAmount)}
            />
          </RadioGroup>
          <div className={'flex mt-4 gap-4'}>
            <div className={'w-[150px]'}>
              {values.purchase_requirement.type === PurchaseRequirementType.PurchaseAmount ? (
                <div key={'purchase_requirement.min_amount'}>
                  <div className="font-medium mb-1">Amount</div>
                  <Input
                    {...getFieldProps('purchase_requirement.min_amount')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.purchase_requirement?.min_amount && !!errors.purchase_requirement?.min_amount}
                    errorMessage={errors.purchase_requirement?.min_amount}
                  />
                </div>
              ) : (
                <div key={'purchase_requirement.min_quantity'}>
                  <div className="font-medium mb-1">Quantity</div>
                  <Input
                    {...getFieldProps('purchase_requirement.min_quantity')}
                    type="number"
                    error={touched.purchase_requirement?.min_quantity && !!errors.purchase_requirement?.min_quantity}
                    errorMessage={errors.purchase_requirement?.min_quantity}
                  />
                </div>
              )}
            </div>
            <div className="flex-1">
              <div className="font-medium mb-1">Any items from</div>
              <Select
                {...getFieldProps('purchase_requirement.items_from')}
                value={values.purchase_requirement.items_from}
                onValueChange={(value) => {
                  setFieldValue('purchase_requirement.items_from', value);
                }}
                defaultValue={ItemsFrom.SPECIFIC_PRODUCTS}
              >
                <SelectTrigger className="h-10">
                  <SelectValue placeholder="Any items from" />
                </SelectTrigger>
                <SelectContent side="bottom">
                  <SelectItem value={ItemsFrom.SPECIFIC_PRODUCTS}>Specific products</SelectItem>
                  <SelectItem value={ItemsFrom.SPECIFIC_COLLECTION}>Specific collections</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <div className="flex mt-4 gap-2">
              <div className="flex-1">
                <Input
                  type="text"
                  placeholder="Search"
                  iconPosition="start"
                  icon={<Unicons.UilSearch size={18} />}
                  value={searchCustomerBuys}
                  error={
                    values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS
                      ? touched.purchase_requirement?.products && !!errors.purchase_requirement?.products
                      : touched.purchase_requirement?.collections && !!errors.purchase_requirement?.collections
                  }
                  errorMessage={
                    (values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS
                      ? errors.purchase_requirement?.products
                      : errors.purchase_requirement?.collections) as string
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setSearchCustomerBuys(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="outline"
                  className="h-10"
                  onClick={() => {
                    if (values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
                      setOpenProductSelectionBuys(true);
                    } else if (values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
                      setOpenCollectionSelectionBuys(true);
                    }
                  }}
                >
                  Browse
                </Button>
                <ProductSelectionDialog
                  key={'ProductSelectionDialogBuys'}
                  open={
                    values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_PRODUCTS && openProductSelectionBuys
                  }
                  setOpen={(val: boolean) => {
                    setOpenProductSelectionBuys(val);
                    setSearchCustomerBuys('');
                  }}
                  search={searchCustomerBuys}
                  preSelections={values.purchase_requirement.products}
                  onSave={(value: any) => {
                    setFieldValue('purchase_requirement.products', value);
                  }}
                />
                <CollectionSelectionDialog
                  key={'CollectionSelectionDialogBuys'}
                  open={
                    values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION &&
                    openCollectionSelectionBuys
                  }
                  setOpen={(val: boolean) => {
                    setOpenCollectionSelectionBuys(val);
                    setSearchCustomerBuys('');
                  }}
                  search={searchCustomerBuys}
                  preSelections={values.purchase_requirement.collections}
                  onSave={(value: any) => {
                    setFieldValue('purchase_requirement.collections', value);
                  }}
                />
              </div>
            </div>
            <div className="mt-2">
              {values.purchase_requirement.items_from === ItemsFrom.SPECIFIC_COLLECTION ? (
                <SelectedCollections
                  on="purchase_requirement.collections"
                  setFieldValue={setFieldValue}
                  value={values.purchase_requirement.collections}
                />
              ) : (
                <SelectedProducts
                  on="purchase_requirement.products"
                  setFieldValue={setFieldValue}
                  value={values.purchase_requirement.products}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardBXGYApplication[1]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Customer gets</div>
          <div className={'flex mt-4 gap-4'}>
            <div className={'w-[150px]'}>
              <div className="font-medium mb-1">Quantity</div>
              <Input
                {...getFieldProps('application.min_quantity')}
                type="number"
                error={touched?.application?.min_quantity && !!errors?.application?.min_quantity}
                errorMessage={errors?.application?.min_quantity}
              />
            </div>
            <div className="flex-1">
              <div className="font-medium mb-1">Any items from</div>
              <Select
                {...getFieldProps('application.items_from')}
                value={values.application.items_from}
                onValueChange={(value) => {
                  setFieldValue('application.items_from', value);
                }}
                defaultValue={ItemsFrom.SPECIFIC_PRODUCTS}
              >
                <SelectTrigger className="h-10">
                  <SelectValue placeholder="Any items from" />
                </SelectTrigger>
                <SelectContent side="bottom">
                  <SelectItem value={ItemsFrom.SPECIFIC_PRODUCTS}>Specific products</SelectItem>
                  <SelectItem value={ItemsFrom.SPECIFIC_COLLECTION}>Specific collections</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex mt-4 gap-2">
              <div className="flex-1">
                <Input
                  type="text"
                  placeholder="Search"
                  iconPosition="start"
                  icon={<Unicons.UilSearch size={18} />}
                  value={searchCustomerGets}
                  error={
                    values.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS
                      ? touched.application?.products && !!errors.application?.products
                      : touched.application?.collections && !!errors.application?.collections
                  }
                  errorMessage={
                    (values.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS
                      ? errors.application?.products
                      : errors.application?.collections) as string
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setSearchCustomerGets(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="outline"
                  className="h-10"
                  onClick={() => {
                    if (values.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS) {
                      setOpenProductSelectionGets(true);
                    } else if (values.application.items_from === ItemsFrom.SPECIFIC_COLLECTION) {
                      setOpenCollectionSelectionGets(true);
                    }
                  }}
                >
                  Browse
                </Button>
                <ProductSelectionDialog
                  key={'ProductSelectionDialogGets'}
                  open={values.application.items_from === ItemsFrom.SPECIFIC_PRODUCTS && openProductSelectionGets}
                  setOpen={(val: boolean) => {
                    setOpenProductSelectionGets(val);
                    setSearchCustomerGets('');
                  }}
                  search={searchCustomerGets}
                  preSelections={values.application.products}
                  onSave={(value: any) => {
                    setFieldValue('application.products', value);
                  }}
                />
                <CollectionSelectionDialog
                  key={'CollectionSelectionDialogGets'}
                  open={values.application.items_from === ItemsFrom.SPECIFIC_COLLECTION && openCollectionSelectionGets}
                  setOpen={(val: boolean) => {
                    setOpenCollectionSelectionGets(val);
                    setSearchCustomerGets('');
                  }}
                  search={searchCustomerGets}
                  preSelections={values.application.collections}
                  onSave={(value: any) => {
                    setFieldValue('application.collections', value);
                  }}
                />
              </div>
            </div>
            <div className="mt-2">
              {values.application.items_from === ItemsFrom.SPECIFIC_COLLECTION ? (
                <SelectedCollections
                  on="application.collections"
                  setFieldValue={setFieldValue}
                  value={values.application.collections}
                />
              ) : (
                <SelectedProducts
                  on="application.products"
                  setFieldValue={setFieldValue}
                  value={values.application.products}
                />
              )}
            </div>
          </div>

          <hr className="my-3 mt-5" />

          <div className="mt-6">
            <RadioGroup {...getFieldProps('application.discount_type')} defaultValue={DiscountType.Percentage}>
              <RadioWithLabel
                label="Percentage off"
                value={DiscountType.Percentage}
                onClick={() => setFieldValue('application.discount_type', DiscountType.Percentage)}
              />
              <RadioWithLabel
                label="Amount off each"
                value={DiscountType.Fixed}
                onClick={() => setFieldValue('application.discount_type', DiscountType.Fixed)}
              />
              <RadioWithLabel
                label="Free"
                value="FREE"
                onClick={() => setFieldValue('application.discount_type', 'FREE')}
              />
            </RadioGroup>
            {values.application.discount_type !== 'FREE' && (
              <div className="max-w-[230px] mt-4">
                <div className="font-medium mb-1">
                  {values.application.discount_type === DiscountType.Percentage ? 'Percentage off' : 'Amount'}
                </div>
                <div className="w-[150px]">
                  {values.application.discount_type === DiscountType.Percentage ? (
                    <Input
                      {...getFieldProps('application.discount_amount')}
                      type="number"
                      iconPosition="end"
                      icon={<Unicons.UilPercentage size={18} />}
                      error={touched?.application?.discount_amount && !!errors?.application?.discount_amount}
                      errorMessage={errors?.application?.discount_amount}
                    />
                  ) : (
                    <Input
                      {...getFieldProps('application.discount_amount')}
                      type="number"
                      iconPosition="start"
                      icon={<Unicons.UilRupeeSign size={15} />}
                      error={touched?.application?.discount_amount && !!errors?.application?.discount_amount}
                      errorMessage={errors?.application?.discount_amount}
                    />
                  )}
                </div>
              </div>
            )}
            {values.application.discount_type === DiscountType.Fixed && (
              <div className="font-normal mt-1 text-xs text-gray-500">
                For multiple quantities, the discount amount will be taken off each Y item.
              </div>
            )}

            <hr className="my-3 mt-5" />

            <div className="mt-5">
              <CheckboxWithLabel
                label="Set a maximum number of uses per order"
                value="application.show_max_per_order"
                checked={values.application.show_max_per_order}
                onClick={() => setFieldValue('application.show_max_per_order', !values.application.show_max_per_order)}
              />
              {values?.application?.show_max_per_order && (
                <div className={'mt-2 mb-3 w-[150px]'}>
                  <Input
                    {...getFieldProps('application.max_per_order')}
                    type="number"
                    error={touched?.application?.max_per_order && !!errors?.application?.max_per_order}
                    errorMessage={errors?.application?.max_per_order}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

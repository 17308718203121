import React from 'react';
import { Skeleton } from '../../flexyui';

type props = {
  heightGraph: string;
};
export const SkeletonDashboard: React.FC<props> = ({ heightGraph }) => {
  return (
    <div className="p-3">
      <Skeleton size="label" className="w-[40%] h-9" />

      <div className="mt-3">
        <Skeleton className={`w-full ${heightGraph}`} />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import classNames from 'classnames';
import { RootState } from '../../store';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Banner } from '../../components/banner';
import { Navbar } from '../../components/navbar/navbar';
import { Sidebar } from '../../components/sidebar/sidebar';

export const MainLayoutWrapper = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    localStorage.getItem('flexy_sidebar') === 'closed' || false
  );
  const banners = useSelector((state: RootState) => state.main.banners);
  return (
    <div className="h-full">
      <Banner />
      <Navbar sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <Sidebar sidebarCollapsed={sidebarCollapsed} />
      <div
        className={classNames('scroll-smooth bg-[#fbfbfb] transition-all duration-300 ease-in-out', {
          'min-h-[calc(100dvh-80px)] mt-20': banners.length > 0,
          'min-h-[calc(100dvh-48px)] mt-12': banners.length === 0,
          'lg:ml-16': sidebarCollapsed,
          'lg:ml-56': !sidebarCollapsed
        })}
      >
        <Outlet />
        {/* <div className="w-full flex justify-center">*/}
        {/*  <div className="w-full pt-10 pb-8 flex items-center justify-between px-6 text-base max-w-[75rem]">*/}
        {/*    <div className="flex items-center gap-2">*/}
        {/*      <a href="https://flexype.io/" target="_blank" rel="noopener noreferrer">*/}
        {/*        <img*/}
        {/*          src="https://static.flexype.in/assets/flexype-transparent.svg"*/}
        {/*          alt="FlexyPe Logo"*/}
        {/*          className="h-6 w-auto"*/}
        {/*        />*/}
        {/*      </a>*/}
        {/*      <span>*/}
        {/*        Copyright © {new Date().getFullYear()}{' '}*/}
        {/*        <a href="https://flexype.io/" target="_blank" className="hover:underline">*/}
        {/*          FlexyPe*/}
        {/*        </a>{' '}*/}
        {/*        | All Rights Reserved*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*    <div className="flex items-center gap-6">*/}
        {/*      <a href="/about" className="hover:underline">*/}
        {/*        About us*/}
        {/*      </a>*/}
        {/*      <a href="/contact" className="hover:underline">*/}
        {/*        Contact us*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/* </div>*/}
      </div>
    </div>
  );
};

import {
  Input,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '../../flexyui';
import {
  ShippingDetail,
  useOrderEditAddShippingLineMutation,
  useOrderEditUpdateShippingLineMutation
} from '../../../graphql';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { EventCategory, OrderEditEvents, trackEvents } from '../../../analytics';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';

type Props = {
  refetch: () => Promise<any>;
  calculatedOrderId: string;
  shippingLine: ShippingDetail | null;
  setShippingLine: (data: any) => void;
  openShippingLine: boolean;
  setOpenShippingLine: (data: boolean) => void;
  setShowModal: (data: boolean) => void;
};

export const ShippingLines: React.FC<Props> = ({
  refetch,
  calculatedOrderId,
  shippingLine,
  setShippingLine,
  openShippingLine,
  setOpenShippingLine,
  setShowModal
}) => {
  const [title, setTitle] = useState<string>(shippingLine?.title || '');
  const [fee, setFee] = useState<any>(shippingLine?.amount || '');

  const [addShippingLine, { loading: addShippingLineLoading }] = useOrderEditAddShippingLineMutation();
  const [editShippingLine, { loading: editShippingLineLoading }] = useOrderEditUpdateShippingLineMutation();

  const handleAddShippingLine = async () => {
    try {
      await addShippingLine({
        variables: {
          calculatedOrderId: calculatedOrderId,
          shippingTitle: title,
          shippingAmount: parseFloat(fee)
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.ADD_SHIPPING, {
        calculatedOrderId: calculatedOrderId,
        shippingTitle: title,
        shippingAmount: parseFloat(fee)
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setOpenShippingLine(false);
    setShowModal(false);
  };
  const handleEditShippingLine = async () => {
    if (!shippingLine?.id) return;

    try {
      await editShippingLine({
        variables: {
          calculatedOrderId: calculatedOrderId,
          shippingLineId: shippingLine.id,
          shippingTitle: title,
          shippingAmount: parseFloat(fee)
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.EDIT_SHIPPING, {
        calculatedOrderId: calculatedOrderId,
        shippingLineId: shippingLine.id,
        shippingTitle: title,
        shippingAmount: parseFloat(fee)
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setOpenShippingLine(false);
    setShowModal(false);
  };

  useEffect(() => {
    if (!openShippingLine) setShippingLine(null);
  }, [openShippingLine]);

  useEffect(() => {
    if (shippingLine) {
      setTitle(shippingLine.title);
      setFee(shippingLine.amount);
    } else {
      setTitle('');
      setFee('');
    }
  }, [shippingLine]);

  return (
    <>
      <Dialog open={openShippingLine} onOpenChange={(value: boolean) => setOpenShippingLine(value)}>
        <DialogContent className="!gap-0 !max-w-md" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <div className="text-sm flex items-center gap-2 font-semibold">
                  {shippingLine?.id ? 'Edit shipping fee' : 'Add shipping fees'}
                </div>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0 text-[#121B38]">
                <div className="w-full p-4 box-border">
                  <div className="flex justify-between items-center mb-4">
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Title</div>
                      <Input
                        type="text"
                        name="edit_reason"
                        className="!m-0 rounded-md text-black"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="mb-1 w-[48%]">
                      <div className="font-medium mb-1 text-[#2A324C]">Charges</div>
                      <Input
                        type="number"
                        name="discount_amount"
                        className="!m-0 rounded-md text-black"
                        iconPosition="start"
                        icon={<Unicons.UilRupeeSign size={15} className="text-[#2A324C]" />}
                        value={fee}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^\d*\.?\d{0,2}$/;
                          if (regex.test(value)) {
                            setFee(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button variant={'outline'} size={'md'} onClick={() => setOpenShippingLine(false)}>
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              type="submit"
              disabled={
                addShippingLineLoading || editShippingLineLoading || !title || !(fee?.length > 0 && Number(fee) >= 0)
              }
              onClick={() => {
                if (shippingLine?.id) handleEditShippingLine();
                else handleAddShippingLine();
              }}
            >
              {addShippingLineLoading || editShippingLineLoading ? (
                <LoadingIcon height={20} className={'animate-spin text-white'} />
              ) : shippingLine?.id ? (
                'Save'
              ) : (
                'Add'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

import React from 'react';
import { CartSessionStatus } from '../../../graphql';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  state: CartSessionStatus;
};

export const SessionState: React.FC<Props> = ({ state }) => {
  switch (state) {
    case CartSessionStatus.LoggedIn:
      return (
        <div className="flex gap-0.5 items-center text-[#666666] bg-[#e8e8e8] rounded-2xl py-[3px] pl-1 pr-3 font-medium">
          <Unicons.UilSignInAlt className="h-4" />
          <div className="text-xs">Login Completed</div>
        </div>
      );
    case CartSessionStatus.AddressUpdated:
      return (
        <div className="flex gap-0.5 items-center text-[#4f4700] bg-[#ffeb78] rounded-2xl py-[3px] pl-1 pr-3 font-medium">
          <Unicons.UilLocationPoint className="h-4" />
          <div className="text-xs">Address Updated</div>
        </div>
      );
    case CartSessionStatus.PaymentInitiated:
      return (
        <div className="flex gap-0.5 items-center text-[#4f4700] bg-[#ffd6a4] rounded-2xl py-[3px] pl-1 pr-2.5 font-medium">
          <Unicons.UilTransaction className="h-4" />
          <div className="text-xs">Payment Initiated</div>
        </div>
      );

    default:
      return <></>;
  }
};

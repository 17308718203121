import { reduce, isEqual, isObject } from 'lodash';

export const getDifferences = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  const ignoreKeys = ['active', 'metrics', 'tags', 'collection_selections', 'product_selections'];

  const diff = reduce(
    obj1,
    (result: Record<string, any>, value, key) => {
      if (ignoreKeys.includes(key)) return result;
      if (!isEqual(value, obj2[key])) {
        if (Array.isArray(value) || Array.isArray(obj2[key])) {
          result[key] = {
            old: value !== undefined ? value : null,
            new: obj2[key] !== undefined ? obj2[key] : null
          };
        } else if (isObject(value) && isObject(obj2[key])) {
          const nestedDiff = getDifferences(value, obj2[key]);
          if (Object.keys(nestedDiff).length) {
            result[key] = nestedDiff;
          }
        } else {
          result[key] = {
            old: value !== undefined ? value : null,
            new: obj2[key] !== undefined ? obj2[key] : null
          };
        }
      }
      return result;
    },
    {} as Record<string, any>
  );

  Object.keys(obj2).forEach((key) => {
    if (!(key in obj1)) {
      diff[key] = {
        old: null,
        new: obj2[key]
      };
    }
  });

  return diff;
};

/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdditionalLineAddressConfig,
  AddressCustomisationConfig,
  CouponDisplay,
  LayoutComponent,
  LoginCustomisationConfig,
  MethodLayoutStyle,
  PaymentCustomisationConfigsQuery,
  PaymentMethodDescription,
  PaymentMethodTitle,
  UpsellingCustomisationConfig
} from '../graphql';

type Button = {
  button_text: string;
};

type Banner = {
  text: string;
  position: number;
  enabled: boolean;
};

type BannerConfig = {
  enabled: boolean;
  background_color: string;
  text_color: string;
  banners: Banner[];
};
type CouponConfigs = {
  show_coupons: boolean;
  lock_coupon_access: boolean;
  display: CouponDisplay.CouponList | CouponDisplay.EntryField;
};
export type PaymentUiConfigs = {
  visibility: any;
  title?: string | undefined;
  description?: string | undefined;
  layout_title?: PaymentMethodTitle;
  layout_description?: PaymentMethodDescription;
  position?: number | undefined;
  key?: string | undefined;
};
export type GeneralCustomization = {
  logo?: string | null | undefined;
  layout_style?: string | null | undefined;
  color_scheme: { primary_color: string; text_color: string; upselling_color: string };
  layout_order: {
    position: number;
    name: LayoutComponent;
  }[];
  item_quantity_control: boolean;
  accept_international_orders: boolean;
  auto_redirect_shopify_checkout: boolean;
  show_estimated_time: boolean;
  redirect_shopify_checkout: {
    visibility: boolean;
    title: string;
  };
  discount_on_mrp: boolean;
  show_additional_details: boolean;
  show_cod_availability_reason: boolean;
};
export type LoginCustomization = {
  banner: BannerConfig;
  button: Button;
  coupons: CouponConfigs;
  upselling: UpsellingCustomisationConfig;
};
export type PaymentCustomization = {
  method_layout_style?: MethodLayoutStyle | null | undefined;
  banner: BannerConfig;
  button: Button;
  coupons: CouponConfigs;
  payment_methods?: PaymentUiConfigs[] | null | undefined;
};
export type AddressCustomization = {
  banner: BannerConfig;
  button: Button;
  coupons: CouponConfigs;
  upselling: UpsellingCustomisationConfig;
  additional_line_address: AdditionalLineAddressConfig;
};
export type CustomizationState = {
  general: GeneralCustomization;
  login: LoginCustomisationConfig;
  address: AddressCustomisationConfig;
  payment: PaymentCustomisationConfigsQuery;
  page: string;
};

export const initialState: CustomizationState = {
  general: {
    logo: 'https://static.flexype.in/assets/flexype-full-black.svg',
    color_scheme: {
      primary_color: '#000000',
      text_color: '#ffffff',
      upselling_color: '#db2777'
    },
    layout_order: [
      {
        position: 0,
        name: LayoutComponent.Banner
      },
      {
        position: 1,
        name: LayoutComponent.OrderSummary
      },
      {
        position: 2,
        name: LayoutComponent.Coupons
      },
      {
        position: 2,
        name: LayoutComponent.Upselling
      }
    ],
    layout_style: '',
    item_quantity_control: false,
    accept_international_orders: false,
    auto_redirect_shopify_checkout: false,
    show_estimated_time: false,
    redirect_shopify_checkout: {
      visibility: false,
      title: 'Ordering from outside INDIA?'
    },
    discount_on_mrp: false,
    show_additional_details: false,
    show_cod_availability_reason: false
  },
  login: {
    banner: {
      enabled: true,
      background_color: '#000000',
      text_color: '#FFFFFF',
      banners: [
        {
          text: 'Use FIRST10 Coupon for 10% Off',
          position: 0,
          enabled: true
        }
      ]
    },
    button: {
      button_text: 'Continue'
    },
    coupons: {
      show_coupons: true,
      lock_coupon_access: false,
      display: CouponDisplay.CouponList
    },
    upselling: {
      button_color: '#000000',
      show_upselling: true
    }
  },
  address: {
    banner: {
      enabled: false,
      background_color: '#000000',
      text_color: '#FFFFFF',
      banners: [
        {
          text: '',
          position: 0,
          enabled: false
        }
      ]
    },
    button: {
      button_text: 'Continue'
    },
    coupons: {
      show_coupons: true,
      lock_coupon_access: false,
      display: CouponDisplay.CouponList
    },
    upselling: {
      button_color: '#000000',
      show_upselling: true
    },
    additional_line_address: {
      enabled: false,
      required: false
    }
  },
  payment: {
    banner: {
      enabled: false,
      background_color: '#000000',
      text_color: '#FFFFFF',
      banners: [
        {
          text: '',
          position: 0,
          enabled: false
        }
      ]
    },
    button: {
      button_text: 'Continue'
    },
    coupons: {
      show_coupons: false,
      lock_coupon_access: false,
      display: CouponDisplay.CouponList
    },
    upselling: {
      button_color: '#000000',
      show_upselling: true
    },
    business_gst: {
      visibility: false
    },
    method_layout_style: MethodLayoutStyle.Layout_1,
    payment_methods: [
      {
        key: 'UPI',
        title: 'UPI ',
        description: "Pay through UPI's",
        layout_title: {
          LAYOUT_1: 'UPI',
          LAYOUT_2: 'UPI',
          LAYOUT_3: 'Pay via UPI'
        },
        layout_description: {
          LAYOUT_1: 'Pay through UPI',
          LAYOUT_2: 'Pay through UPI'
        },
        visibility: true,
        position: 0
      },
      {
        key: 'CARD',
        title: 'Debit/Credit cards',
        description: 'Pay with RuPay, Visa, Master Card',
        layout_title: {
          LAYOUT_1: 'Debit/Credit cards',
          LAYOUT_2: 'Debit/Credit cards',
          LAYOUT_3: 'Pay via Debit/Credit cards'
        },
        layout_description: {
          LAYOUT_1: 'Pay via RuPay, Visa, Master Card',
          LAYOUT_2: 'Pay via RuPay, Visa, Master Card'
        },
        visibility: true,
        position: 1
      },
      {
        key: 'COD',
        title: 'Cash on Delivery ',
        description: 'Pay via COD',
        layout_title: {
          LAYOUT_1: 'Cash on Delivery',
          LAYOUT_2: 'Cash on Delivery',
          LAYOUT_3: 'Cash on Delivery'
        },
        layout_description: {
          LAYOUT_1: 'Pay with Cash',
          LAYOUT_2: 'Pay with Cash'
        },
        visibility: true,
        position: 2
      },
      {
        key: 'NET_BANKING',
        title: 'Netbanking',
        description: 'Pay with net-banking',
        layout_title: {
          LAYOUT_1: 'NetBanking',
          LAYOUT_2: 'NetBanking',
          LAYOUT_3: 'Pay via NetBanking'
        },
        layout_description: {
          LAYOUT_1: 'Pay via NetBanking',
          LAYOUT_2: 'Pay via NetBanking'
        },
        visibility: true,
        position: 3
      },
      {
        key: 'WALLET',
        title: 'Wallets',
        description: 'PhonePe, Airtel, PayPal & more',
        layout_title: {
          LAYOUT_1: 'Wallets',
          LAYOUT_2: 'Wallets',
          LAYOUT_3: 'Pay via Wallets'
        },
        layout_description: {
          LAYOUT_1: 'PhonePe, Airtel, PayPal & more',
          LAYOUT_2: 'PhonePe, Airtel, PayPal & more'
        },
        visibility: true,
        position: 4
      },
      {
        key: 'EMI',
        title: 'EMIs',
        description: 'Pay with credit/debit card EMIs',
        layout_title: {
          LAYOUT_1: 'EMI',
          LAYOUT_2: 'EMI',
          LAYOUT_3: 'Pay via EMI'
        },
        layout_description: {
          LAYOUT_1: 'Pay via credit/debit card EMI',
          LAYOUT_2: 'Pay via credit/debit card EMI'
        },
        visibility: true,
        position: 5
      },
      {
        key: 'PAY_LATER',
        title: 'Pay Later',
        description: 'Pay with LazyPay, Simpl ',
        layout_title: {
          LAYOUT_1: 'Pay Later',
          LAYOUT_2: 'Pay Later',
          LAYOUT_3: 'Pay Later'
        },
        layout_description: {
          LAYOUT_1: 'Pay via LazyPay, Zest, etc.',
          LAYOUT_2: 'Pay via LazyPay, Zest, etc.'
        },
        visibility: false,
        position: 6
      },
      {
        key: 'PARTIAL_COD',
        title: 'Partial Payment (Pay $pending_amount on delivery)',
        description: 'Testing',
        layout_title: {
          LAYOUT_1: 'Partial Payment',
          LAYOUT_2: 'Partial Payment  ',
          LAYOUT_3: 'Partial Payment (Pay $pending_amount on delivery)'
        },
        layout_description: {
          LAYOUT_1: 'Pay $pending_amount on Delivery',
          LAYOUT_2: 'Pay $pending_amount on Delivery'
        },
        visibility: true,
        position: 7
      }
    ]
  },
  page: 'General'
};

export const customizationSlice = createSlice({
  name: 'customization-store',
  initialState,
  reducers: {
    setGeneralCustomization: (state, action: PayloadAction<GeneralCustomization>) => {
      if (action.payload) {
        state.general.logo = action.payload.logo;
        state.general.color_scheme.primary_color = action.payload.color_scheme.primary_color;
        state.general.color_scheme.text_color = action.payload.color_scheme.text_color;
        state.general.color_scheme.upselling_color = action.payload.color_scheme.upselling_color;
        state.general.layout_order = [...action.payload.layout_order];
        state.general.layout_style = action.payload.layout_style;
        state.general.item_quantity_control = action.payload.item_quantity_control;
        state.general.accept_international_orders = action.payload.accept_international_orders;
        state.general.auto_redirect_shopify_checkout = action.payload.auto_redirect_shopify_checkout;
        state.general.show_estimated_time = action.payload.show_estimated_time;
        state.general.redirect_shopify_checkout = action.payload.redirect_shopify_checkout;
        state.general.discount_on_mrp = action.payload.discount_on_mrp;
        state.general.show_additional_details = action.payload.show_additional_details;
        state.general.show_cod_availability_reason = action.payload.show_cod_availability_reason;
      }
      return state;
    },
    setLoginCustomization: (state, action: PayloadAction<LoginCustomization>) => {
      if (action.payload) {
        state.login.banner.enabled = action.payload.banner.enabled;
        state.login.banner.banners = action.payload.banner.banners;
        state.login.banner.background_color = action.payload.banner.background_color;
        state.login.banner.text_color = action.payload.banner.text_color;
        state.login.button.button_text = action.payload.button.button_text;
        state.login.coupons.display = action.payload.coupons.display;
        state.login.coupons.lock_coupon_access = action.payload.coupons.lock_coupon_access;
        state.login.coupons.show_coupons = action.payload.coupons.show_coupons;
        state.login.upselling.button_color = action.payload.upselling.button_color;
        state.login.upselling.show_upselling = action.payload.upselling.show_upselling;
      }
      return state;
    },
    setAddressCustomization: (state, action: PayloadAction<AddressCustomization>) => {
      if (action.payload) {
        state.address.banner.enabled = action.payload.banner.enabled;
        state.address.banner.banners = action.payload.banner.banners;
        state.address.banner.background_color = action.payload.banner.background_color;
        state.address.banner.text_color = action.payload.banner.text_color;
        state.address.button.button_text = action.payload.button.button_text;
        state.address.coupons.display = action.payload.coupons.display;
        state.address.coupons.lock_coupon_access = action.payload.coupons.lock_coupon_access;
        state.address.coupons.show_coupons = action.payload.coupons.show_coupons;
        state.address.upselling.button_color = action.payload.upselling.button_color;
        state.address.upselling.show_upselling = action.payload.upselling.show_upselling;
        state.address.additional_line_address = action.payload.additional_line_address;
      }
      return state;
    },
    setPaymentCustomization: (state, action: PayloadAction<PaymentCustomisationConfigsQuery>) => {
      if (action.payload) {
        state.payment.banner.enabled = action.payload.banner.enabled;
        state.payment.banner.banners = action.payload.banner.banners;
        state.payment.banner.background_color = action.payload.banner.background_color;
        state.payment.banner.text_color = action.payload.banner.text_color;
        state.payment.button.button_text = action.payload.button.button_text;
        state.payment.coupons.display = action.payload.coupons.display;
        state.payment.coupons.lock_coupon_access = action.payload.coupons.lock_coupon_access;
        state.payment.coupons.show_coupons = action.payload.coupons.show_coupons;
        state.payment.method_layout_style = action.payload.method_layout_style;
        state.payment.payment_methods = action.payload.payment_methods;
        state.payment.upselling.button_color = action.payload.upselling?.button_color;
        state.payment.upselling.show_upselling = action.payload.upselling?.show_upselling;
        state.payment.business_gst.visibility = action.payload.business_gst?.visibility;
      }
      return state;
    },
    setPage: (state, action: PayloadAction<string>) => {
      state.page = action.payload;
    }
  }
});

export const {
  setGeneralCustomization,
  setLoginCustomization,
  setAddressCustomization,
  setPaymentCustomization,
  setPage
} = customizationSlice.actions;

export default customizationSlice.reducer;

import * as Yup from 'yup';
import { Partnered, WebhookType } from '../../../graphql';

export const MetaPixelSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  pixel_id: Yup.string().required('Pixel ID is required'),
  conversion_api_token: Yup.string().required('Conversion API Token is required'),
  conversion_api_enabled: Yup.boolean().required()
});

export const GoogleAdsSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  conversion_id: Yup.string().required('Conversion ID is required'),
  conversion_label: Yup.string().required('Conversion API Token is required'),
  conversion_status: Yup.boolean().required()
});

export const GoogleAnalyticsSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  measurement_id: Yup.string().required('Measurement ID is required'),
  api_secret: Yup.string().required('API Secret is required')
});

export const getWebhookSchema = (type: WebhookType, partnered: Partnered) => {
  const isUrlRequired = partnered === Partnered.None;

  const urlRegex = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/.*[^/]$/;

  return Yup.object().shape({
    enabled: Yup.boolean().required('Enabled field is required'),
    url: isUrlRequired
      ? Yup.string().required('URL is required').matches(urlRegex, 'Invalid URL format')
      : Yup.string().optional(),
    api_secret: Yup.string().required('API Secret is required')
  });
};

import React, { FC } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

type props = {
  data: any;
};

export const DiscountInfoCard: FC<props> = ({ data }) => {
  return (
    <div className="mr-16 hidden sm:block">
      <div className="font-medium text-black mb-2">{data.heading}</div>
      <ul className="text-gray-500 list-disc">
        {data.info.map((item: any) => {
          return <li className="text-gray-500 ml-4 mb-4">{item} </li>;
        })}
      </ul>
    </div>
  );
};

import type { FC } from 'react';
import { UilDraggabledots, UilTrashAlt, UilImageAltSlash, UilImages } from '@iconscout/react-unicons';
import classNames from 'classnames';
import { Card } from '../flexyui';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export interface CardProps {
  id: number;
  product: any;
  index: number;
  onDelete: (product: any) => void;
}

export const UpsellCollectionCard: FC<CardProps> = ({ id, product, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });

  const style = {
    width: '200px',
    height: '220px',
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={classNames(
        'w-[200px] h-[220px] relative border-2 pointer-events-none rounded-xl flex justify-between flex-col m-2 ml-0 mb-0 parent-card overflow-hidden',
        {
          'opacity-75 overflow-hidden border-2 border-black border-dashed': isDragging
        }
      )}
      key={id}
    >
      <div className="action-buttons">
        <div
          className="absolute pointer-events-auto cursor-grab flex justify-center rounded-full m-2 p-1 items-center text-gray-600 hover:text-black top-0 left-0 bg-gray-100 h-6 w-6"
          {...listeners}
          {...attributes}
        >
          <UilDraggabledots size={15} className="inline-block" />
        </div>
        <div className="absolute right-0 cursor-pointer pointer-events-auto flex justify-center rounded-full m-2 p-1 items-center text-gray-600 hover:text-red-500 top-0 bg-gray-100 h-6 w-6">
          <UilTrashAlt
            size={15}
            className="inline-block"
            onClick={() => {
              onDelete(product);
            }}
          />
        </div>
      </div>
      <div className="upsell-product-image bg-white w-full min-h-[60%] pointer-events-none rounded-lg flex justify-center items-center">
        {product.image_src && product.image_src !== 'no image found' ? (
          <img
            src={product.image_src}
            style={{
              height: '100%',
              // width: '100%',
              // objectFit: 'cover',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
              margin: 'auto'
            }}
            alt="no image found"
          />
        ) : (
          <UilImages />
        )}
      </div>
      <div className="product-description flex pointer-events-none flex-col bg-gray-100 rounded-sm px-2 py-2 space-y-1 min-h-[40%]">
        <div className="product-title-title text-sm line-clamp-2 font-medium mb-2" title={product.title}>
          {product.title}
        </div>
        <div className="flex justify-between text-gray-500">
          <span className="text-sm text-gray-500">Total Products </span>{' '}
          <span className="font-medium">{product.product_count}</span>
        </div>
      </div>
    </Card>
  );
};

import {
  setSidebarState,
  setConfirmationDialog,
  setCancelButtonClicked,
  setRouteLink,
  setPageProgress
} from '../../store/main-slice';
import React from 'react';
import { Button } from '../flexyui';
import classNames from 'classnames';
import { Notifications } from './notifications';
import LoadingBar from 'react-top-loading-bar';
import * as Unicons from '@iconscout/react-unicons';
import { DashboardAction } from './dashboard-action';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import ContentWrapper from '../layout/content-wrapper';
import useScreenSize from '../../hooks/use-screen-size';
import { useLocation, useNavigate } from 'react-router-dom';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import ConfirmationDialog from '../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LogoWhite } from '../../assets/images/flexype-logo-white.svg';
import { ReactComponent as LogoTransparent } from '../../assets/images/flexype-transparent.svg';

type props = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (value: boolean) => void;
};

export const Navbar: React.FC<props> = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const isMobile = useScreenSize().width < 1024;
  const routeLink = useSelector((state: RootState) => state.main.routeLink);
  const sideBarState = useSelector((state: RootState) => state.main.sideBarState);
  const pageProgress = useSelector((state: RootState) => state.main.pageProgress);
  const confirmationDialog = useSelector((state: RootState) => state.main.confirmationDialog);
  const banners = useSelector((state: RootState) => state.main.banners);

  const { showActions, setShowActions, onSave, onCancel, isSaveCancelLoading } = useSaveCancelButtons();
  const handleSidebarToggle = () => {
    dispatch(setSidebarState(!sideBarState));
  };

  window.onpopstate = function (e) {
    if (showActions) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      history.pushState(null, document.title, `${location.pathname}${location.hash}`);
      dispatch(setRouteLink(`${location.pathname}${location.hash}`));
      dispatch(setConfirmationDialog(true));
    } else {
      dispatch(setConfirmationDialog(false));
    }
  };

  return (
    <>
      <LoadingBar
        color="#2f72ff"
        height={3}
        progress={pageProgress}
        onLoaderFinished={() => dispatch(setPageProgress(0))}
      />

      <div
        className={classNames('fixed z-10 w-screen h-12 transition-top duration-300 ease-in-out', {
          'top-8': banners.length > 0,
          'top-0': banners.length === 0
        })}
        style={{
          backgroundColor: showActions ? 'rgba(51, 51, 51, 1)' : 'white'
        }}
      >
        <div className="lg:hidden flex h-full border-b-[1px]">
          <div className="w-full h-full flex items-center pl-3 pr-1">
            <div className="flex items-center justify-between w-full h-full">
              <div className="flex items-center gap-3">
                <Unicons.UilBars
                  className="cursor-pointer"
                  onClick={handleSidebarToggle}
                  color={`${showActions ? 'white' : 'black'}`}
                />
                {!showActions && <LogoWhite className="h-6 w-20" />}
              </div>

              {showActions && (
                <div className="flex gap-1 items-center ml-1 sm:ml-4 mr-auto sm:gap-2">
                  <Unicons.UilExclamationTriangle className="z-10 text-white h-5 w-5 sm:h-6 sm:w-6" />
                  <span className="text-white text-sm">Unsaved changes</span>
                </div>
              )}

              {!showActions ? (
                isMobile && (
                  <div className="flex items-center gap-2">
                    <Notifications />
                    <DashboardAction />
                  </div>
                )
              ) : (
                <div className="pb-0 gap-2 flex items-center mr-2 sm:mr-6 sm:gap-3">
                  <Button
                    variant="outline"
                    size="sm"
                    className="!h-[30px] bg-[rgba(51, 51, 51, 1)] text-white border-[1px] hover:bg-[rgba(51, 51, 51, 1)]"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                  <Button variant="outline" size="sm" className="!h-[30px] min-w-16" onClick={() => onSave()}>
                    {isSaveCancelLoading ? (
                      <LoadingIcon height={16} className={'h-4 w-4 animate-spin text-black'} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="items-center justify-between border-b-[1px] hidden lg:flex">
          <div
            className={classNames('my-2 flex items-center justify-between transition-all duration-300 ease-in-out', {
              'w-16 mx-4': sidebarCollapsed,
              'w-56 mx-7': !sidebarCollapsed,
              'top-20': banners.length > 0,
              'top-12': banners.length === 0
            })}
          >
            {sidebarCollapsed ? (
              <LogoTransparent className="min-w-8 w-8" />
            ) : (
              <>{showActions ? <LogoWhite className="h-8 min-w-24 w-24" /> : <Logo className="h-8 min-w-24 w-24" />}</>
            )}

            {!showActions && (
              <button
                onClick={() => {
                  localStorage.setItem('flexy_sidebar', !sidebarCollapsed ? 'closed' : 'open');
                  setSidebarCollapsed(!sidebarCollapsed);
                }}
                className={classNames(
                  'p-0.5 rounded-full border flex justify-center items-center bg-gray-50 hover:bg-gray-100 transition-transform duration-300',
                  {
                    'mr-4': !sidebarCollapsed,
                    '-ml-3': sidebarCollapsed
                  }
                )}
              >
                <Unicons.UilAngleRight
                  size={20}
                  className={classNames('transition-transform duration-300', { 'rotate-180': !sidebarCollapsed })}
                />
              </button>
            )}
          </div>

          {!showActions ? (
            !isMobile && (
              <div className="flex items-center mr-2 gap-3">
                <Notifications />
                <DashboardAction />
              </div>
            )
          ) : (
            <ContentWrapper className="mr-[240px]">
              <div className="flex w-full justify-between">
                <div className="flex space-x-2 items-center ml-[90px]">
                  <Unicons.UilExclamationTriangle className="z-10 text-white h-6 w-6" />{' '}
                  <span className="text-white text-sm">Unsaved changes</span>{' '}
                </div>

                <div className="pb-0 space-x-3 flex items-center">
                  <Button
                    variant="outline"
                    size="md"
                    className="h-[30px] bg-[rgba(51, 51, 51, 1)] text-white border-[0.1px] hover:bg-[rgba(51, 51, 51, 1)]"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                  <Button variant="outline" size="md" className="!h-[30px]" onClick={() => onSave()}>
                    {isSaveCancelLoading ? (
                      <LoadingIcon height={20} className={'h-4 w-4 animate-spin text-black'} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </div>
            </ContentWrapper>
          )}
          <ConfirmationDialog
            showModal={confirmationDialog}
            setShowModal={setConfirmationDialog}
            onSave={() => {
              if (showActions) {
                dispatch(setCancelButtonClicked(true));
                setShowActions(false);
                if (history.length) {
                  navigate(routeLink);
                } else {
                  navigate(window.location.pathname);
                }
              }
              dispatch(setConfirmationDialog(false));
            }}
            onCancel={() => {
              dispatch(setConfirmationDialog(false));
              history.pushState(null, document.title, `${location.pathname}${location.hash}`);
            }}
            text={'This action will discard changes. Are you sure you want to continue?'}
            headerText="Discard changes"
            confirmButtonText="Discard"
            confirmActionVariant="destructive"
            loading={false}
          />
        </div>
      </div>
    </>
  );
};

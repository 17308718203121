export enum PageEvents {
  LOGIN = 'Login Page View',
  FORGOT_PASSWORD = 'Forgot Password Page View',
  RESET_PASSWORD = 'Reset Password Page View',
  ONBOARDING = 'Onboarding Page View',
  SET_PASSWORD = 'Set Password Page View',

  HOME = 'Home Page View',
  ORDERS = 'Orders Page View',
  CART = 'ACR Page View',
  SHIPPING = 'Shipping Page View',
  COUPONS = 'Coupons Page View',
  PAYMENT_OFFERS = 'Payment Offers Page View',

  PRODUCT_UPSELLING = 'Product Upselling Page View',
  COLLECTION_UPSELLING = 'Collection Upselling Page View',

  GENERAL_CUSTOMISATION = 'General Customisation Page View',
  LOGIN_CUSTOMISATION = 'Login Customisation Page View',
  ADDRESS_CUSTOMISATION = 'Address Customisation Page View',
  PAYMENT_CUSTOMISATION = 'Payment Customisation Page View',

  RTO_SUITE = 'RTO Suite Page View',

  STORE_DETAILS = 'Store Details Page View',
  PAYMENTS = 'Payments Page View',
  TAXES_DUTIES = 'Taxes & Duties Page View',

  INTEGRATIONS = 'Integrations Page View',
  META_PIXELS_INTEGRATIONS = 'Meta Pixels Integrations Page View',
  GOOGLE_ANALYTICS_INTEGRATIONS = 'Google Analytics Integrations Page View',
  GOOGLE_ADS_INTEGRATIONS = 'Google Ads Integrations Page View',
  ELEVEN_ZA_INTEGRATIONS = '11za Integrations Page View',
  ACR_INTEGRATIONS = 'ACR Integrations Page View',

  BILLING_USAGE = 'Billing & Usage Page View',
  USERS_PERMISSIONS = 'Users & Permissions Page View',
  CHANGE_PASSWORD = 'Change Password Page View'
}

export enum SessionEvents {
  SESSION_START = 'Session Start',
  SESSION_END = 'Session End'
}

export enum EventCategory {
  SESSION = 'Session',
  PAGE_VIEW = 'Page View',
  ADMINISTRATION = 'Administration',

  ANALYTICS = 'Analytics',
  ORDERS = 'Orders',
  ORDERS_EDIT = 'Order Edit',
  SHIPPING = 'Shipping',
  COUPONS = 'Coupons',
  PAYMENT_OFFERS = 'Payment Offers',
  UPSELLING = 'Upselling',
  CUSTOMIZATION = 'Customization',
  GENERAL_CUSTOMIZATION = 'General Customization',
  LOGIN_CUSTOMIZATION = 'Login Customization',
  ADDRESS_CUSTOMIZATION = 'Address Customization',
  PAYMENT_CUSTOMIZATION = 'Payment Customization',
  RTO_SUITE = 'RTO Suite',
  PARTIAL_COD = 'Partial Cod',
  SETTINGS = 'Settings',
  STORE_DETAILS = 'Store Details',
  PAYMENT = 'Payment',
  TAXES_DUTIES = 'Taxes & Duties',
  INTEGRATIONS = 'Integrations',
  USERS_PERMISSIONS = 'Users & Permissions',
  CHANGE_PASSWORD = 'Change Password'
}

export enum AdministrationEvents {
  LOG_OUT = 'Log Out',
  STORE_SWITCH = 'Store Switch',
  ONBOARDING_COMPLETED = 'Onboarding Completed',
  SET_PASSWORD = 'Set Password',
  RESET_PASSWORD = 'Reset Password',
  SEND_RESET_PASSWORD_MAIL = 'Send Reset Password Mail'
}

export enum AnalyticEvents {
  DATE_PICKER_CLICKED = 'Date Picker Clicked',
  DATE_RANGE_CHANGED = 'Date Range Changed',
  GET_REPORT_CLICKED = 'Get Report Clicked',
  SEND_REPORT_CLICKED = 'Send Report Clicked'
}

export enum OrderEvents {
  FILTERED_SEARCH = 'Filtered Search',
  VIEW_ORDER = 'View Order Details',
  VIEW_ORDER_IN_SHOPIFY = 'View Shopify Order Details'
}

export enum OrderEditEvents {
  SESSION_START = 'Order Edit Session Start',
  ADD_PRODUCT = 'Add Product',
  EDIT_QUANTITY = 'Edit Quantity',
  REMOVE_PRODUCT = 'Remove Product',
  ADD_DISCOUNT = 'Add Discount',
  EDIT_DISCOUNT = 'Edit Discount',
  REMOVE_DISCOUNT = 'Remove Discount',
  ADD_SHIPPING = 'Add Shipping',
  EDIT_SHIPPING = 'Edit Shipping',
  REMOVE_SHIPPING = 'Remove Shipping',
  SESSION_END = 'Order Edit Session End'
}

export enum ShippingEvents {
  SHIPPING_SYNC_START = 'Shipping Sync Start',
  VIEW_PROFILE = 'View Profile',
  ADD_ZONE_CLICKED = 'Add Zone Clicked',
  ADD_COUNTRY_ZONE = 'Add Country Zone ',
  ADD_PINCODE_ZONE = 'Add Pincode Zone',
  ADD_ZONE = 'Add Zone',
  EDIT_ZONE = 'Edit Zone',
  REMOVE_ZONE = 'Remove Zone',
  ADD_RATE = 'Add Rate',
  EDIT_RATE = 'Edit Rate',
  REMOVE_RATE = 'Remove Rate',
  NON_SERVICEABLE_PINCODE = 'Non Serviceable Pincode',
  ACTIVE_STATUS_CHANGED = 'Active Status Changed',
  SHIPPING_SYNC_END = 'Shipping Sync End'
}

export enum UpsellingEvents {
  ADD_PRODUCT_CLICKED = 'Add Product Clicked',
  ADD_PRODUCT = 'Add Product',
  REMOVE_PRODUCT = 'Remove Product',
  EDIT_PRODUCT_CLICKED = 'Edit Product Clicked',
  VIEW_VARIANTS = 'View Variants',
  PRODUCT_REARRANGED = 'Product Rearranged',
  ADD_COLLECTION_CLICKED = 'Add Collection Clicked',
  ADD_COLLECTION = 'Add Collection',
  REMOVE_COLLECTION = 'Remove Collection',
  COLLECTION_REARRANGED = 'Collection Rearranged'
}

export enum CommonCustomizationEvents {
  BANNERS_STATUS_CHANGED = 'Banners Status Changed',
  BANNER_VISIBILITY_CHANGED = 'Banner Visibility Changed',
  BANNER_EDIT = 'Banner Edit',
  BANNER_REARRANGED = 'Banner Rearranged',
  COUPONS_STATUS_CHANGED = 'Coupons Status Changed',
  COUPONS_UI_CHANGED = 'Coupons UI Changed',
  UPSELLING_STATUS_CHANGED = 'Upselling Status Changed',
  DESKTOP_PREVIEW = 'Desktop Preview'
}
export enum GeneralCustomizationEvents {
  LOGO_UPLOAD = 'Logo Upload',
  LAYOUT_REARRANGED = 'Layout Rearranged',
  QUANTITY_CHANGE = 'Quantity Change',
  ADDITIONAL_PRODUCT_DETAILS = 'Additional Product Details',
  COMPARE_PRICE = 'Compare Price',
  REDIRECT_SHOPIFY_CHECKOUT = 'Redirect Shopify Checkout',
  AUTO_REDIRECT = 'Auto Redirect',
  COD_AVAILABILITY_REASON = 'COD Availability Reason'
}
export enum LoginCustomizationEvents {
  LOCK_COUPONS = 'Lock Coupons'
}
export enum AddressCustomizationEvents {
  ADDITIONAL_ADDRESS_LINE = 'Additional Address Line',
  ADDITIONAL_ADDRESS_LINE_REQUIRED = 'Additional Address Line Required'
}
export enum PaymentCustomizationEvents {
  LAYOUT_CHANGED = 'Layout Changed',
  METHOD_VISIBILITY_CHANGED = 'Method Visibility Changed',
  METHOD_EDIT = 'Method Edit',
  METHODS_REARRANGED = 'Methods Rearranged',
  BILLING_GST = 'Billing GST'
}

export enum RtoSuiteEvents {
  COLLECTION_FILTER_STATUS_CHANGED = 'Collection Filter Status Changed',
  ADD_COLLECTION_FILTER = 'Add Collection Filter',
  REMOVE_COLLECTION_FILTER = 'Remove Collection Filter',
  COLLECTION_FILTER_TYPE = 'Collection Filter Type',
  PINCODE_FILTER_STATUS_CHANGED = 'Pincode Filter Status Changed',
  ADD_PINCODE_FILTER = 'Add Pincode Filter',
  REMOVE_PINCODE_FILTER = 'Remove Pincode Filter',
  PINCODE_FILTER_TYPE = 'Pincode Filter Type',
  USER_FILTER_STATUS_CHANGED = 'User Filter Status Changed',
  ADD_USER_FILTER = 'Add User Filter',
  REMOVE_USER_FILTER = 'Remove User Filter'
}
export enum PartialCodEvents {
  PARTIAL_COD_STATUS_CHANGED = 'Partial Cod Status Changed',
  PARTIAL_COD_TYPE = 'Partial Cod Type'
}

export enum StoreDetailsEvent {
  EDIT_BUSINESS_DETAILS_CLICKED = 'Edit Business Details Clicked',
  EDIT_BUSINESS_DETAILS = 'Edit Business Details',
  ADD_POC = 'Add Poc',
  EDIT_POC = 'Edit Poc',
  REMOVE_POC = 'Remove Poc',
  CHECKOUT_STATUS_CHANGED = 'Checkout Status Changed'
}
export enum PaymentEvent {
  CONFIGURE_PROVIDER = 'Configure Provider',
  REMOVE_PROVIDER = 'Remove Provider',
  REMOVE_ACTIVE_PROVIDER = 'Remove Active Provider',
  CHANGE_PROVIDER = 'Change Provider'
}
export enum TaxesEvent {
  ADD_OVERRIDE_CLICKED = 'Add Override Clicked',
  ADD_OVERRIDE = 'Add Override',
  REMOVE_OVERRIDE = 'Remove Override',
  ADD_PROVINCE = 'Add Province',
  EDIT_PROVINCE = 'Edit Province',
  REMOVE_PROVINCE = 'Remove Province'
}
export enum IntegrationEvent {
  META_STATUS_CHANGED = 'Meta Status Changed',
  ADD_META = 'Add Meta',
  EDIT_META = 'Edit Meta',
  REMOVE_META = 'Remove Meta',

  GA_STATUS_CHANGED = 'GA Status Changed',
  ADD_GA = 'Add GA',
  EDIT_GA = 'Edit GA',
  REMOVE_GA = 'Remove GA',

  GADS_STATUS_CHANGED = 'GAds Status Changed',
  ADD_GADS = 'Add GAds',
  EDIT_GADS = 'Edit GAds',
  REMOVE_GADS = 'Remove GAds',

  ADD_WEBHOOK = 'Add Webhook',
  EDIT_WEBHOOK = 'Edit Webhook',
  REMOVE_WEBHOOK = 'Remove Webhook'
}
export enum UserPermissionEvent {
  ADD_USER_PERMISSION = 'Add User Permission',
  REMOVE_USER_PERMISSION = 'Remove User Permission'
}
export enum ChangePasswordEvent {
  PASSWORD_CHANGED = 'Password Changed'
}

export enum DiscountEvent {
  ADD_DISCOUNT_CLICKED = 'Add Discount Clicked',
  DISCOUNT_ADDITION_STARTED = 'Discount Addition Started',
  DISCOUNT_ADDITION_COMPLETED = 'Discount Addition Completed',
  DISCOUNT_EDITING_STARTED = 'Discount Editing Started',
  DISCOUNT_EDITING_COMPLETED = 'Discount Editing Completed',
  VIEW_DISCOUNT_DETAILS = 'View Discount Details',
  DISCOUNT_STATUS_CHANGED = 'Discount Status Changed',
  DISCOUNT_VISIBILITY_CHANGED = 'Discount Status Changed',
  REMOVE_DISCOUNT = 'Remove Discount',
  FILTERED_SEARCH = 'Filtered Search'
}

/* eslint-disable import/default */
import dayjs from 'dayjs';
import Charts from 'react-apexcharts';
import React, { useCallback } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { DashboardGraphTitle } from '../title/title';
import { PerformanceMetricsQuery } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { convertAmount, calculateIncrement } from '../../../utils/convert-amount';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';

type Props = {
  metricsData?: PerformanceMetricsQuery['performanceMetrics'];
  loading: boolean;
};

export const TotalSales: React.FC<Props> = ({ metricsData, loading }) => {
  const calculateData = useCallback(() => {
    const dates: any = [];
    const currentData = metricsData?.graph?.current?.map((item: any) => {
      dates.push(dayjs.utc(item?.date_time).format('MMM D h:mm A'));
      return Math.round(item?.net_sales);
    });
    const prevData = metricsData?.graph?.previous?.map((item: any) => {
      return Math.round(Number(item?.net_sales));
    });

    return {
      series: [
        {
          name:
            dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D') !==
            dayjs.utc(metricsData?.metrics?.current?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.metrics?.current?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')}`,
          data: currentData || []
        },
        {
          name:
            dayjs.utc(metricsData?.metrics?.previous?.start).format('MMM D') !==
            dayjs.utc(metricsData?.metrics?.previous?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.metrics?.previous?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.metrics?.previous?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.metrics?.previous?.end).format('MMM D')}`,
          data: prevData || [],
          color: '#C5C5C5'
        }
      ],
      dates
    };
  }, [metricsData]);

  const options: any = {
    legend: {
      position: 'top',
      offsetY: 0,
      markers: {
        width: 10,
        height: 10
      },
      horizontalAlign: 'left',
      colors: ['black', '#A0A4AF'],
      style: {
        colors: ['black', '#A0A4AF']
      }
    },
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false,
        enabled: true,
        shared: true
      }
    },
    onDatasetHover: {
      highlightDataSeries: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
      color: '#EEEEEE'
    },
    grid: {
      row: {
        colors: ['transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      tickAmount: calculateData().dates.length < 30 ? 3 : 4,
      labels: {
        rotate: 0,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'D",
          day: "MMM 'D",
          hour: 'HH:mm'
        },
        style: {
          colors: '#A0A4AF',
          fontSize: '10px'
        }
      },
      axisBorder: {
        show: false // Hide the x-axis line
      },
      axisTicks: {
        show: false // Hide the x-axis ticks
      },
      crosshairs: {
        show: false // Hide the crosshair lines
      },
      tooltip: {
        enabled: false
      },
      categories:
        calculateData().dates.length === 24
          ? calculateData().dates.map((date: any) => dayjs(date).format('MMM D h:mm A'))
          : calculateData().dates.map((date: any) => dayjs(date).format('MMM D'))
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#A0A4AF'],
          fontSize: '10px' // Adjust the font size as needed
        },
        position: 'bottom', // Set the position to 'bottom' for y-axis labels at the bottom
        formatter: (value: number) => {
          if (value >= 1000000000) {
            // eslint-disable-next-line prefer-template
            return (value / 1000000000).toFixed(2) + 'B'; // Convert to "1b" format for billion or greater
          }
          if (value >= 1000000) {
            return `${(value / 1000000).toFixed(2).toString()} M`.replace('.00', ''); // Convert to "1m" format for million or greater
          }
          if (value >= 10000) {
            return `${(value / 1000).toFixed(2).toString()} K`.replace('.00', ''); // Convert to "10k" format for 10 thousand or greater
          }
          return value; // Return the original value for numbers below 10000
        }
      }
    }
  };

  const percentageChange = metricsData?.metrics?.previous?.net_sales
    ? calculateIncrement(
        Number(metricsData?.metrics?.current?.net_sales),
        Number(metricsData?.metrics?.previous?.net_sales)
      )
    : '-';

  return (
    <div className="bg-white row-span-2 border-b-[1px] md:border-b-[0px] md:border-r-[1px] sm:col-span-2 rounded-t-xl md:rounded-tr-none md:rounded-bl-xl md:rounded-tl-xl">
      <div className="font-medium text-black pt-4 px-4 flex items-center">
        <div>Net Sales</div>
        <ButtonWithInfo
          heading="Net Sales"
          description="Total revenue after deducting the discounts."
          calculation={true}
          calculationVariable="Net Sales"
          calculationValue="gross - discounts"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <SkeletonDashboard heightGraph="h-[300px]" />
      ) : (
        <>
          <DashboardGraphTitle
            title="Net Sales"
            loading={loading}
            value={convertAmount(Number(metricsData?.metrics?.current?.net_sales || '0'))}
            prevValue={convertAmount(Number(metricsData?.metrics?.previous?.net_sales || '0'))}
            percentageChange={percentageChange}
          />
          {metricsData?.metrics?.current?.start ? (
            <Charts options={options} series={calculateData().series} type="line" height={300} />
          ) : (
            <div className="text-center my-40">
              <div className="text-gray-300 text-base">Not Available</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

/* eslint-disable camelcase */
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Label, Skeleton } from '../flexyui';
import { EventCategory, ShippingEvents, trackEvents } from '../../analytics';
import { GetShippingProfileQuery, useGetShippingSyncerQuery, useMutationMutation } from '../../graphql';

type Props = {
  profileData: GetShippingProfileQuery['getShippingProfile'];
  loading: boolean;
  profileRefetch: () => void;
};

export const Profiles: React.FC<Props> = ({ profileData, loading, profileRefetch }) => {
  const navigate = useNavigate();
  const { data, refetch } = useGetShippingSyncerQuery();
  const [syncShipping] = useMutationMutation();

  const [lastSync, setLastSync] = useState<string>('');
  const [syncLoading, setSyncLoading] = useState<boolean>(false);

  const formatLastSync = (timestamp: string | number) => {
    return new Date(parseInt(timestamp.toString(), 10)).toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  useEffect(() => {
    if (data?.getShippingSyncer?.last_sync) {
      setLastSync(formatLastSync(data.getShippingSyncer.last_sync));
    }
  }, [data]);

  const profileSyncQuery = async () => {
    if (syncLoading) return;

    setSyncLoading(true);
    trackEvents(EventCategory.SHIPPING, ShippingEvents.SHIPPING_SYNC_START);

    try {
      const { data: syncRes } = await syncShipping();

      if (!syncRes?.syncShipping) {
        enqueueSnackbar('Oops! Failed to initiate sync. Please try again later.', {
          variant: 'error'
        });
        setSyncLoading(false);
        return;
      }

      const checkSyncStatus = async () => {
        const result = await refetch();
        const { status, last_sync } = result?.data?.getShippingSyncer || {};

        if (status === 'COMPLETED') {
          setSyncLoading(false);
          if (last_sync) setLastSync(formatLastSync(last_sync));
          profileRefetch();

          trackEvents(EventCategory.SHIPPING, ShippingEvents.SHIPPING_SYNC_END);
        } else if (status === 'ERROR') {
          enqueueSnackbar('Sync process failed. Please try again later.', {
            variant: 'error'
          });
          setSyncLoading(false);
        } else {
          setTimeout(checkSyncStatus, 1000);
        }
      };

      checkSyncStatus();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setSyncLoading(false);
    }
  };

  return (
    <>
      <Card className="flex flex-col gap-4 p-4">
        <div className="w-full flex flex-col justify-between items-start sm:w-auto sm:flex-row sm:items-center">
          <div className="flex flex-col space-y-1">
            <Label size="md" className="text-base">
              Shipping Profiles
            </Label>
            <Label size="sm" className="text-gray-400">
              Choose where you ship and how much you charge for shipping at checkout
            </Label>
          </div>
          <div className="w-full mt-4 flex justify-between items-center gap-4 sm:w-auto sm:mt-0 sm:justify-start">
            {!!lastSync && !syncLoading && (
              <div className="text-sm font-normal text-gray-500">Last synced: {lastSync}</div>
            )}
            <Button variant="primary" size="sm" onClick={profileSyncQuery}>
              <Unicons.UilSync size={18} className={`mr-2 ${syncLoading ? 'animate-spin' : ''}`} />
              Sync
            </Button>
          </div>
        </div>
        {loading &&
          [0, 1].map(() => (
            <div className="bg-gray-50 rounded-lg border p-3">
              <Skeleton className="w-full sm:w-[250px] !m-0 !mb-2" />
              <div className="flex items-center gap-2">
                <Skeleton className="w-[80px] !m-0" />
                <Skeleton className="w-[80px] !m-0" />
              </div>
            </div>
          ))}

        {profileData.map((profile) => (
          <div
            className="bg-gray-50  rounded-lg border flex justify-between items-center p-3 cursor-pointer hover:bg-slate-50 transition-colors ease-linear"
            onClick={() => {
              navigate(`/shipping/profile/${profile.id}`);
              trackEvents(EventCategory.SHIPPING, ShippingEvents.VIEW_PROFILE);
            }}
          >
            <div className="flex flex-col items-start gap-1">
              <Label size="md" className="text-sm cursor-pointer">
                {profile.name}
              </Label>
              <Label className="text-gray-400 cursor-pointer text-left max-w-[95%] sm:max-w-full" size={'sm'}>
                {profile.product_count} Products • {profile.zone_count} Zones
              </Label>
            </div>
            <Unicons.UilAngleRight size={20} />
          </div>
        ))}
      </Card>

      {/* <Card className="mt-4 flex flex-col gap-5 px-4 py-4">*/}
      {/*  <div className="flex justify-between items-center">*/}
      {/*    <div className="flex flex-col space-y-1">*/}
      {/*      <Label size="md" className="text-base">*/}
      {/*        Pickup in store*/}
      {/*      </Label>*/}
      {/*      <Label size="sm" className="text-gray-400">*/}
      {/*        Let customers pick up their orders at your locations.*/}
      {/*      </Label>*/}
      {/*    </div>*/}
      {/*    <Button variant="primary" size="sm">*/}
      {/*      Set up*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/* </Card>*/}
    </>
  );
};

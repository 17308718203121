import React, { useState } from 'react';
import { ShippingLines } from './shipping-lines';
import * as Unicons from '@iconscout/react-unicons';
import { formatPrice } from '../../../utils/format-price';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { EventCategory, OrderEditEvents, trackEvents } from '../../../analytics';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../flexyui';
import { CalculatedOrder, ShippingDetail, useOrderEditDeleteShippingLineMutation } from '../../../graphql';

type Props = {
  calculatedOrderId: string;
  calculatedOrder: CalculatedOrder;
  refetch: () => Promise<any>;
};

export const OrderSummary: React.FC<Props> = ({ calculatedOrderId, calculatedOrder, refetch }) => {
  const summary = calculatedOrder?.summary;
  const [shippingLine, setShippingLine] = useState<ShippingDetail | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [openShippingLine, setOpenShippingLine] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const [deleteShippingLine, { loading: deleteShippingLineLoading }] = useOrderEditDeleteShippingLineMutation();

  const handleDeleteShippingLine = async () => {
    if (!shippingLine?.id) return;

    try {
      await deleteShippingLine({
        variables: {
          calculatedOrderId: calculatedOrderId,
          shippingLineId: shippingLine.id
        }
      });

      trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.REMOVE_SHIPPING, {
        calculatedOrderId: calculatedOrderId,
        shippingLineId: shippingLine.id
      });

      await refetch();
    } catch (error) {
      handleGraphqlErrors(error);
    }

    setShowModal(false);
    setShowConfirmationDialog(false);
  };

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div>Updated total</div>
        <div>₹{formatPrice(summary?.subtotal_amount || 0)}</div>
      </div>
      {summary?.order_level_discount ? (
        <div className="flex items-center justify-between mb-2">
          <div>Order level discount</div>
          <div>-₹{formatPrice(summary?.order_level_discount || 0)}</div>
        </div>
      ) : null}
      <div className="flex justify-between mb-2">
        <div className="flex">
          <div
            className="w-[130px] text-primary cursor-pointer hover:underline"
            onClick={() => {
              if (calculatedOrder?.shipping_details.length > 0) setShowModal(true);
              else {
                setShippingLine(null);
                setOpenShippingLine(true);
              }
            }}
          >
            {calculatedOrder?.shipping_details.length > 0 ? 'Edit shipping fees' : 'Add shipping fee'}
          </div>
          <div>
            {calculatedOrder?.shipping_details.length > 0
              ? calculatedOrder.shipping_details.map((shipping) => (
                  <div className="mb-2 last:mb-0">{shipping?.title || 'Standard'}</div>
                ))
              : 'No shipping'}
          </div>
        </div>
        <div className="flex flex-col items-end">
          {calculatedOrder?.shipping_details.length > 0
            ? calculatedOrder.shipping_details.map((shipping) => (
                <div className="mb-2 last:mb-0">₹{formatPrice(shipping?.amount || 0)}</div>
              ))
            : '₹0.00'}
        </div>
      </div>
      {(summary?.tax_lines?.length || 0) > 0 && (
        <div className="flex justify-between mb-2">
          <div className="flex">
            <div className="w-[130px]">Taxes</div>
            <div>
              {summary?.tax_lines?.map((tax) => (
                <div className="mb-2 last:mb-0">
                  {tax?.title} {((tax?.rate || 0) * 100).toString().replace(/\.0+$/, '')}%
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div>
              {summary?.tax_lines?.map((tax) => <div className="mb-2 last:mb-0">₹{formatPrice(tax?.price || 0)}</div>)}
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between mb-3.5">
        <div>Paid by customer</div>
        <div>₹{formatPrice(summary?.paid_amount || 0)}</div>
      </div>
      <hr />
      <div className="flex items-center justify-between font-medium mt-3">
        <div>Amount to {(summary?.outstanding_amount || 0) >= 0 ? 'Collect' : 'Refund'}</div>
        <div>₹{formatPrice(Math.abs(summary?.outstanding_amount || 0))}</div>
      </div>

      <ShippingLines
        refetch={refetch}
        calculatedOrderId={calculatedOrderId}
        shippingLine={shippingLine}
        setShippingLine={setShippingLine}
        openShippingLine={openShippingLine}
        setOpenShippingLine={setOpenShippingLine}
        setShowModal={setShowModal}
      />

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteShippingLine}
        headerText="Remove shipping fee?"
        text="This action will remove the shipping. Are you sure you want to continue?"
        confirmButtonText="Remove"
        confirmActionVariant="destructive"
        loading={deleteShippingLineLoading}
      />

      <Dialog open={showModal} onOpenChange={(value: boolean) => setShowModal(value)}>
        <DialogContent className="!gap-0 !max-w-lg" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <div className="text-sm flex items-center gap-2 font-semibold">Edit shipping fees</div>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0 text-[#121B38]">
                <div className="w-full p-4 box-border">
                  <div className="border rounded-xl pt-2 mb-3">
                    {calculatedOrder?.shipping_details.map((shipping) => (
                      <div className="flex items-center justify-between px-2 pb-2">
                        <div className="flex items-center gap-1">
                          <div>{shipping?.title || 'Standard'}</div>
                          <div>·</div>
                          <div>₹{shipping?.amount?.toFixed(2)}</div>
                        </div>
                        <div className="flex items-center gap-1">
                          {shipping?.is_editable && (
                            <Unicons.UilEditAlt
                              size={25}
                              className="py-1 text-gray-600 cursor-pointer rounded-lg hover:bg-gray-200 hover:text-gray-800"
                              onClick={() => {
                                setShippingLine(shipping);
                                setOpenShippingLine(true);
                              }}
                            />
                          )}
                          <Unicons.UilTrash
                            size={24}
                            className="py-1 text-gray-600 cursor-pointer rounded-lg hover:bg-gray-200 hover:text-gray-800"
                            onClick={() => {
                              setShippingLine(shipping);
                              setShowConfirmationDialog(true);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="text-primary cursor-pointer hover:underline"
                    onClick={() => setOpenShippingLine(true)}
                  >
                    Add shipping fee
                  </div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

import classNames from 'classnames';
import React, { useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { formatPrice } from '../../../utils/format-price';
import { SessionState } from '../session-state/session-state';
import { CartSessionStatus, OrderQuery } from '../../../graphql';
import { Card, Label, Separator, Skeleton } from '../../flexyui';
import { PaymentStatus } from '../payment-status/payment-status';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
  basePath: string;
};

export const OrderSummary: React.FC<Props> = ({ data, loading, basePath }) => {
  const [couponExpanded, setCouponExpanded] = useState<boolean>(false);
  const [taxesExpanded, setTaxesExpanded] = useState<boolean>(false);
  const [shippingExpanded, setShippingExpanded] = useState<boolean>(false);

  return (
    <Card className="p-4 col-span-2 mb-4">
      <div className="flex items-center gap-3 mb-4">
        <Label size="md" className="text-base">
          {basePath === '/orders' ? 'Order' : 'Checkout'} Summary
        </Label>
        {loading ? (
          <Skeleton size="label" />
        ) : basePath === '/orders' ? (
          <PaymentStatus text={data?.order_invoice?.payment_state} />
        ) : (
          <SessionState state={data?.session_state as CartSessionStatus} />
        )}
      </div>

      {loading ? (
        [0, 1, 2, 3].map((index) => (
          <div key={`${index}_summary`} className="flex items-center justify-between pb-2">
            <div className="flex items-center gap-3">
              <Skeleton size="md" className="w-32" />
              {index !== 3 && <Skeleton size="md" className="w-20 hidden md:block" />}
            </div>
            <Skeleton size="md" className="w-14" />
          </div>
        ))
      ) : (
        <div>
          <div className="w-full flex flex-col md:flex-row gap-2">
            <div className="w-[150px] font-medium md:font-normal">Subtotal</div>
            <div className="flex-1 flex items-center justify-between">
              <div>{data?.ordered_products?.reduce((sum, p) => sum + (p?.quantity || 1), 0)} item(s)</div>
              <div>₹{formatPrice(data?.order_invoice?.subtotal || 0)}</div>
            </div>
          </div>

          {data?.coupon_codes && data.coupon_codes.length > 0 && (
            <div className="w-full flex flex-col md:flex-row gap-2 mt-3 md:mt-2">
              <div className="w-[150px] font-medium md:font-normal">Discounts</div>

              {data.coupon_codes.length > 1 ? (
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => setCouponExpanded(!couponExpanded)}
                    >
                      <div>Coupon details</div>
                      <Unicons.UilAngleDown
                        size={20}
                        className={classNames('transition-transform duration-300', {
                          'rotate-180': couponExpanded
                        })}
                      />
                    </div>
                    <div
                      className={`text-right transition-opacity duration-300 ease-in-out overflow-hidden ${
                        couponExpanded ? 'opacity-0' : 'opacity-100'
                      }`}
                    >
                      -₹{formatPrice(data?.order_invoice?.discount || 0)}
                    </div>
                  </div>
                  <div
                    className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                      couponExpanded ? 'max-h-30' : 'max-h-0'
                    }`}
                  >
                    {data.coupon_codes.map((coupon, index) => (
                      <div key={index} className="flex items-center justify-between mt-2">
                        <div>{coupon?.code}</div>
                        <div className="text-right">-₹{formatPrice(coupon?.discount_amount || 0)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex-1 flex justify-between">
                  <div>{data.coupon_codes[0]?.code}</div>
                  <div className="text-right">-₹{formatPrice(data.coupon_codes[0]?.discount_amount || 0)}</div>
                </div>
              )}
            </div>
          )}

          {data?.shipping_details && data.shipping_details.length > 0 && (
            <div className="w-full flex flex-col md:flex-row gap-2 mt-3 md:mt-2">
              <div className="w-[150px] font-medium md:font-normal">Shipping</div>

              {data.shipping_details.length > 1 ? (
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => setShippingExpanded(!shippingExpanded)}
                    >
                      <div>Shipping details</div>
                      <Unicons.UilAngleDown
                        size={20}
                        className={classNames('transition-transform duration-300', {
                          'rotate-180': shippingExpanded
                        })}
                      />
                    </div>
                    <div
                      className={`text-right transition-opacity duration-300 ease-in-out overflow-hidden ${
                        shippingExpanded ? 'opacity-0' : 'opacity-100'
                      }`}
                    >
                      ₹{formatPrice(data?.order_invoice?.total_shipping_price || 0)}
                    </div>
                  </div>
                  <div
                    className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                      shippingExpanded ? 'max-h-30' : 'max-h-0'
                    }`}
                  >
                    {data.shipping_details.map((shipping, index) => (
                      <div key={index} className="flex items-center justify-between mt-2">
                        <div>{shipping?.title}</div>
                        <div className="text-right">₹{formatPrice(shipping?.standard_price || 0)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex-1 flex justify-between">
                  <div>{data.shipping_details[0]?.title}</div>
                  <div className="text-right">₹{formatPrice(data.shipping_details[0]?.standard_price || 0)}</div>
                </div>
              )}
            </div>
          )}

          {data?.tax_lines && data.tax_lines.length > 0 && (
            <div className="w-full flex flex-col md:flex-row gap-2 mt-3 md:mt-2">
              <div className="w-[150px] font-medium md:font-normal">
                Taxes {data?.order_invoice?.taxes_included && '(Included)'}
              </div>

              {data.tax_lines.length > 1 ? (
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center cursor-pointer" onClick={() => setTaxesExpanded(!taxesExpanded)}>
                      <div>Tax details</div>
                      <Unicons.UilAngleDown
                        size={20}
                        className={classNames('transition-transform duration-300', {
                          'rotate-180': taxesExpanded
                        })}
                      />
                    </div>
                    <div
                      className={`text-right transition-opacity duration-300 ease-in-out overflow-hidden ${
                        taxesExpanded ? 'opacity-0' : 'opacity-100'
                      }`}
                    >
                      ₹{formatPrice(data?.order_invoice?.total_tax || 0)}
                    </div>
                  </div>
                  <div
                    className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${
                      taxesExpanded ? 'max-h-30' : 'max-h-0'
                    }`}
                  >
                    {data.tax_lines.map((tax, index) => (
                      <div key={index} className="flex items-center justify-between mt-2">
                        <div>
                          {tax?.title} {((tax?.rate || 0) * 100).toString().replace(/\.0+$/, '')}%
                        </div>
                        <div className="text-right">₹{formatPrice(tax?.price || 0)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex-1 flex justify-between">
                  <div>
                    {data.tax_lines[0]?.title} {((data.tax_lines[0]?.rate || 0) * 100).toString().replace(/\.0+$/, '')}%
                  </div>
                  <div className="text-right">₹{formatPrice(data.tax_lines[0]?.price || 0)}</div>
                </div>
              )}
            </div>
          )}

          <div className="flex justify-between font-semibold text-black mt-3 md:mt-2">
            <div>Total</div>
            <div>₹{formatPrice(data?.order_invoice?.total_amount || 0)}</div>
          </div>
          <Separator className="my-2" lineStyle="dashed" />
          {basePath === '/orders' && (
            <div className="flex justify-between">
              <div>Paid</div>
              <div>₹{formatPrice(data?.order_invoice?.paid_amount || 0)}</div>
            </div>
          )}
          {typeof data?.order_invoice?.outstanding_amount === 'number' &&
            data?.order_invoice?.outstanding_amount !== 0 && (
              <div className="flex justify-between mt-2">
                {basePath === '/orders' ? (
                  <div>Amount to {(data?.order_invoice?.outstanding_amount || 0) >= 0 ? 'Collect' : 'Refund'}</div>
                ) : (
                  <div>To be paid by customer</div>
                )}
                <div>₹{formatPrice(Math.abs(data?.order_invoice?.outstanding_amount || 0))}</div>
              </div>
            )}
        </div>
      )}
    </Card>
  );
};

import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Skeleton } from '../../../flexyui';
import { PaymentProviderCard } from './payment-provider-card';
import { PaymentProvidersQuery, usePaymentProvidersQuery } from '../../../../graphql';
import { PaymentProviderIcons } from '../../../../constants/settings';

type Props = {
  data: PaymentProvidersQuery | undefined;
  loading: boolean;
  refetch: () => void;
  usedProviders: Array<string>;
};

export const PaymentProviders: React.FC<Props> = ({ data, loading, refetch, usedProviders }) => {
  const [paymentProviders, setPaymentProviders] = useState<any>([]);

  const handlePaymentProviders = () => {
    if (!data) return;

    const providerArray = (Object.keys(data.paymentProviders) as Array<keyof typeof data.paymentProviders>).map(
      (provider) => {
        const providerData = data.paymentProviders[provider];
        const PaymentIcon = PaymentProviderIcons[provider as keyof typeof PaymentProviderIcons];

        return {
          name: provider,
          active: !!providerData.api_key && !!providerData.api_secret,
          account_type: providerData.account_type,
          account_id: providerData.account_id,
          api_key: providerData.api_key,
          api_secret: providerData.api_secret,
          icon: PaymentIcon ? <PaymentIcon height={24} /> : null
        };
      }
    );

    setPaymentProviders(providerArray);
  };

  useEffect(() => {
    handlePaymentProviders();
  }, [data?.paymentProviders]);

  return (
    <div className="mb-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {loading &&
          Object.keys(PaymentProviderIcons).map((each) => {
            const PaymentIcon = PaymentProviderIcons[each as keyof typeof PaymentProviderIcons];

            return (
              <Card className="h-fit p-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]">
                <div className="flex items-center justify-between mb-8">
                  <PaymentIcon height={24} />
                  <Skeleton variant="circular" className="!m-0 w-[60px]" />
                </div>
                <div className="flex justify-end">
                  <Button
                    variant="info"
                    className="no-underline w-fit h-fit font-normal text-gray-400 relative group -mr-1"
                  >
                    Configure
                    <Unicons.UilAngleRight size={20} />
                  </Button>
                </div>
              </Card>
            );
          })}
        {paymentProviders.map((provider: any) => (
          <PaymentProviderCard provider={provider} refetch={refetch} usedProviders={usedProviders} />
        ))}
      </div>
    </div>
  );
};

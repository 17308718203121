import { Separator, Skeleton } from '../../../flexyui';

const TabLayoutLoading = () => {
  return (
    <div>
      <Skeleton variant="label" size="label" />
      <div className="mt-3 space-y-2">
        {[1, 2, 3].map((_, index) => (
          <div className="flex" key={index}>
            <Skeleton variant="rounded" size="xl" className="h-10" />
          </div>
        ))}
      </div>
      <Separator />
      <div className="flex justify-between items-center">
        <Skeleton variant="label" size="label" />
        <Skeleton variant="circular" size="md" />
      </div>
      <Separator />
      <Skeleton size="xl" variant="rounded" className="h-[135px]" />
      <Separator />
      <Skeleton variant="label" size="label" />
      <div className="mb-4 mt-3">
        <div className="flex mb-2 justify-between">
          <Skeleton variant="label" size="label" />
          <Skeleton variant="label" size="label" />
        </div>
        <Skeleton variant="rounded" size="xl" className="h-10" />
      </div>
      <Separator />
      <Skeleton variant="label" size="label" />
      <div className="flex flex-col gap-2 mb-5 mt-3">
        <div className="flex gap-2 items-center">
          <Skeleton variant="rounded" size="sm" />
          <Skeleton variant="label" size="lg" />
        </div>
        <div className="flex gap-2 items-center">
          <Skeleton variant="rounded" size="sm" />
          <Skeleton variant="label" size="lg" />
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-5 mt-3">
        <div className="flex gap-2 items-center">
          <Skeleton variant="rounded" size="icon" />
          <Skeleton variant="label" size="lg" />
        </div>
        <div className="flex gap-2 items-center">
          <Skeleton variant="rounded" size="icon" />
          <Skeleton variant="label" size="lg" />
        </div>
      </div>
      <Separator />
      <div className="flex gap-2 items-center justify-end">
        <Skeleton className="w-[130px] h-[40px]" />
        <Skeleton className="w-[130px] h-[40px]" />
      </div>
    </div>
  );
};

export default TabLayoutLoading;

import {
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowSelectionState,
  ColumnDef
} from '@tanstack/react-table';
import { Input } from '../Input';
import classNames from 'classnames';
import { Checkbox } from '../Checkbox';
import { Skeleton } from '../../flexyui/Skeleton';
import { CouponOperation } from '../../../graphql';
import * as Unicons from '@iconscout/react-unicons';
import React, { FC, useEffect, useState } from 'react';
import { DataTablePagination } from './data-tabale-pagination';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../flexyui';

type props = {
  columnData: ColumnDef<any, any>[];
  data: any;
  showFilterInput?: boolean;
  onRowClick: (item: any) => void;
  enableRowSelection?: boolean;
  rowsPerPageOptions: Array<number>;
  rowsPerPage: number;
  count: number;
  page: number;
  setPage: (value: number) => void;
  onRowsPerPageChange: (value: any) => void;
  onBulkUpdate?: (operation: CouponOperation, coupons: any | any[]) => Promise<void>;
  showPagination: boolean;
  loadingRows?: number;
  bulkLoading?: boolean;
  resetSelectionRef?: React.MutableRefObject<(() => void) | null>;
  isPaymentOffer?: boolean;
  filterContent?: React.ReactNode;
};

export const DataTable: FC<props> = ({
  columnData,
  data,
  showFilterInput = false,
  onRowClick,
  enableRowSelection = false,
  rowsPerPageOptions,
  rowsPerPage,
  count,
  page,
  setPage,
  onRowsPerPageChange,
  onBulkUpdate,
  showPagination = false,
  loadingRows = 5,
  bulkLoading = false,
  resetSelectionRef,
  isPaymentOffer = false,
  filterContent
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const columns =
    enableRowSelection && data?.length > 0
      ? [
          {
            id: 'select',
            header: ({ table }: any) => (
              <div className="flex justify-center items-center">
                {data && (
                  <Checkbox
                    checked={table?.getIsAllRowsSelected()}
                    onChange={table?.getToggleAllRowsSelectedHandler()}
                    onClick={(e: any) => e.stopPropagation()}
                  />
                )}
              </div>
            ),
            cell: ({ row }: any) => (
              <div className="flex justify-center items-center py-2 px-3">
                <Checkbox
                  checked={row.getIsSelected()}
                  disabled={!row.getCanSelect()}
                  onChange={row.getToggleSelectedHandler()}
                  onClick={(e: any) => e.stopPropagation()}
                />
              </div>
            )
          },
          ...columnData
        ]
      : [...columnData];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
      columnFilters,
      pagination: { pageIndex: page, pageSize: rowsPerPage }
    },
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    enablePinning: true,
    manualPagination: true,
    autoResetAll: false,
    enableRowSelection: true
  });

  const handleBulkUpdate = async (operation: CouponOperation) => {
    const selectedRowsData = table.getSelectedRowModel().rows.map((row) => row.original.row);
    if (onBulkUpdate) {
      await onBulkUpdate(operation, selectedRowsData);
      setRowSelection({});
    }
  };

  useEffect(() => {
    if (resetSelectionRef) {
      resetSelectionRef.current = () => setRowSelection({});
    }
  }, [resetSelectionRef]);

  useEffect(() => {
    setRowSelection({});
  }, [page]);

  return (
    <>
      <div>
        <div className="rounded-xl border overflow-hidden">
          {showFilterInput && filterContent}
          {data && Object.keys(rowSelection)?.length > 0 && (
            <table className="w-full caption-bottom text-sm bg-gray-100">
              <TableHeader className="w-full">
                <TableRow className="flex flex-grow bg-white hover:bg-white">
                  <div className="w-full h-[49.5px] px-4 py-1 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <Checkbox
                        checked={table?.getIsAllRowsSelected()}
                        onChange={table?.getToggleAllRowsSelectedHandler()}
                        onClick={(e: any) => e.stopPropagation()}
                      />
                      {rowSelection && table.getFilteredSelectedRowModel().rows.length > 0 && (
                        <div className="ml-4">
                          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length}{' '}
                          row(s) selected.
                        </div>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="outline"
                        className="w-fit h-[34px] hidden md:flex"
                        onClick={() => handleBulkUpdate(CouponOperation.Activate)}
                        disabled={
                          !table
                            .getSelectedRowModel()
                            .rows.map((row) => row.original.row)
                            ?.some((coupon: any) => !coupon.active) || bulkLoading
                        }
                      >
                        Activate
                      </Button>
                      <Button
                        variant="outline"
                        className="w-fit h-[34px] hidden md:flex"
                        onClick={() => handleBulkUpdate(CouponOperation.Deactivate)}
                        disabled={
                          !table
                            .getSelectedRowModel()
                            .rows.map((row) => row.original.row)
                            ?.some((coupon: any) => coupon.active) || bulkLoading
                        }
                      >
                        Deactivate
                      </Button>
                      {isPaymentOffer && (
                        <Button
                          variant="outline"
                          className="w-fit h-[34px] hidden md:flex"
                          onClick={() => setShowConfirmationDialog(true)}
                          disabled={bulkLoading}
                        >
                          <div className="text-red-500">Delete</div>
                        </Button>
                      )}
                      <DropdownMenu>
                        <DropdownMenuTrigger
                          className={classNames('focus:outline-0', {
                            'block md:hidden': isPaymentOffer
                          })}
                        >
                          <Button size="icon" variant="icon">
                            <Unicons.UilEllipsisH size={22} className="p-[2px] text-gray-600" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-36 !z-[1500]" sideOffset={10} align="end">
                          <DropdownMenuItem
                            className="md:hidden"
                            onClick={() => handleBulkUpdate(CouponOperation.Activate)}
                            disabled={
                              !table
                                .getSelectedRowModel()
                                .rows.map((row) => row.original.row)
                                ?.some((coupon: any) => !coupon.active) || bulkLoading
                            }
                          >
                            Activate
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="md:hidden"
                            onClick={() => handleBulkUpdate(CouponOperation.Deactivate)}
                            disabled={
                              !table
                                .getSelectedRowModel()
                                .rows.map((row) => row.original.row)
                                ?.some((coupon: any) => coupon.active) || bulkLoading
                            }
                          >
                            Deactivate
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className={isPaymentOffer ? 'hidden' : ''}
                            onClick={() => handleBulkUpdate(CouponOperation.Discoverable)}
                            disabled={
                              !table
                                .getSelectedRowModel()
                                .rows.map((row) => row.original.row)
                                ?.some((coupon: any) => !coupon.discoverable) || bulkLoading
                            }
                          >
                            Visible
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className={isPaymentOffer ? 'hidden' : ''}
                            onClick={() => handleBulkUpdate(CouponOperation.NonDiscoverable)}
                            disabled={
                              !table
                                .getSelectedRowModel()
                                .rows.map((row) => row.original.row)
                                ?.some((coupon: any) => coupon.discoverable) || bulkLoading
                            }
                          >
                            Hidden
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => setShowConfirmationDialog(true)} disabled={bulkLoading}>
                            <div className="text-red-500">Delete</div>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                </TableRow>
              </TableHeader>
            </table>
          )}
          <Table className="rounded-xl">
            {Object.keys(rowSelection)?.length === 0 && (
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id} className="h-[50px]">
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} className="bg-gray-100 text-gray-600">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
            )}
            <TableBody>
              {data && table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="cursor-pointer"
                    data-state={row.getIsSelected() && 'selected'}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row.original);
                      }
                    }}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      const lastIndex = index === 0;
                      return (
                        <TableCell
                          key={cell.id}
                          className={classNames('border-l border-gray-100', {
                            'border-l-0': lastIndex,
                            'min-w-[50px] w-[50px] max-w-[50px]': cell.id === '0_select'
                          })}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : data && table.getRowModel().rows?.length === 0 ? (
                <TableRow className="bg-white hover:bg-white">
                  <TableCell colSpan={columns?.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              ) : (
                [...Array(loadingRows)].map((_, index) => (
                  <TableRow key={index} className="hover:bg-transparent">
                    {table.getHeaderGroups()[0].headers.map((header) => (
                      <TableCell key={header.id} className="px-2 py-4">
                        <Skeleton size="xl" />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>

        {showPagination && data && table.getRowModel().rows?.length > 0 && (
          <div className="py-6">
            <DataTablePagination
              table={table}
              rowsPerPageOptions={rowsPerPageOptions}
              rowsPerPage={rowsPerPage}
              count={count}
              page={page}
              setPage={setPage}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </div>
        )}
      </div>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={() => handleBulkUpdate(CouponOperation.Delete)}
        headerText="Delete Coupon?"
        text="This action will delete the selected discount(s)."
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
      />
    </>
  );
};

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  Button
} from '../flexyui';
import { FormikErrors } from 'formik';
import { CouponType } from '../../graphql';
import * as Unicons from '@iconscout/react-unicons';
import React, { FC, useEffect, useRef } from 'react';
import { addCouponsData } from '../../constants/coupons';
import { discountHeader } from '../../constants/discount';
import { ActiveButton } from '../shared/active-button/active-button';
import { getDiscountPageKeys } from '../../utils/discount/discount-page-keys';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { CreateDiscountPreview } from '../coupons/coupons-preview/coupons-preview';
import { CreateOfferPreview } from '../payment-offers/offers-preview/offer-preview';

type Props<T = any> = {
  open: boolean;
  setOpen: (value: boolean) => void;
  activeStep: number;
  setActiveStep: (value: number) => void;
  type: CouponType;
  errors: FormikErrors<T>;
  discountDetails: any;
  setDiscountDetails: (value: any) => void;
  totalActiveStepIndex?: number;
  content: React.ReactNode;
  isSubmitting: boolean;
  resetForm: any;
  submitForm: () => Promise<any>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

export const DiscountDialog: FC<Props> = ({
  open,
  setOpen,
  activeStep,
  setActiveStep,
  type,
  errors,
  discountDetails,
  setDiscountDetails,
  totalActiveStepIndex = 3,
  content,
  isSubmitting,
  resetForm,
  submitForm,
  setFieldTouched
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const filterErrorsForActiveStep = () => {
    const keysForActiveStep = getDiscountPageKeys(type)[activeStep];
    const filteredErrors: Record<string, any> = {};

    keysForActiveStep.forEach((key) => {
      if (errors[key]) filteredErrors[key] = errors[key];
    });

    return filteredErrors;
  };

  const highlightErrors = (errors: FormikErrors<any>, prefix = '') => {
    Object.entries(errors).forEach(([key, value]) => {
      const fieldPath = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'object' && value !== null) highlightErrors(value as FormikErrors<any>, fieldPath);
      else setFieldTouched(fieldPath, true, false);
    });
  };

  const couponTitle =
    type === CouponType.Payment
      ? 'Payment Offer'
      : addCouponsData.find((coupon) => coupon.id === type)?.title + ' Discount';

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeButton = scrollContainerRef.current.querySelector(`[data-coupon-step-number="${activeStep}"]`);
      if (activeButton) {
        activeButton.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [activeStep]);

  return (
    <AlertDialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <AlertDialogContent className="!gap-0" size="fullWidth">
        <AlertDialogHeader className="w-full h-full">
          <AlertDialogTitle className="p-4 sm:pl-6 sm:pr-5 bg-gray-50">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-4">
                <Unicons.UilPercentage />
                <h1 className="text-xl font-medium hidden sm:block">
                  {discountDetails?.id ? `Edit ${couponTitle}` : `Create ${couponTitle}`}
                </h1>
                <h1 className="text-xl font-medium block sm:hidden">
                  {discountDetails?.id ? 'Edit Discount' : 'Create Discount'}
                </h1>
              </div>
              <Button
                size="icon"
                variant="icon"
                onClick={() => {
                  resetForm();
                  setActiveStep(0);
                  setOpen(false);
                  setDiscountDetails(null);
                }}
                className="hover:bg-slate-100"
              >
                <Unicons.UilTimes className="text-primary" />
              </Button>
            </div>
          </AlertDialogTitle>
          <AlertDialogDescription className="!p-0 !m-0 w-full !h-[calc(100dvh-65px)] overflow-hidden text-[#2A324C]">
            <div className="flex h-full">
              <div className="flex-1 relative">
                <div className="border-r-[1px] h-full">
                  <div className="w-[100vw] sm:w-[calc(100vw-22rem)] sticky px-4 sm:px-8">
                    <div
                      className="flex items-center gap-2 overflow-x-auto scrollbar-hide py-3 sm:py-4"
                      ref={scrollContainerRef}
                    >
                      {(totalActiveStepIndex === 3
                        ? discountHeader.filter((header) => header.step !== 'User Behaviour')
                        : discountHeader
                      ).map((header, index) => {
                        return (
                          <ActiveButton
                            key={header.step}
                            step={header.step}
                            logo={header.logo}
                            activeStep={activeStep}
                            stepNumber={index}
                            setActiveStep={setActiveStep}
                            disabled={Object.keys(errors).length > 0}
                            totalActiveStepIndex={totalActiveStepIndex}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <hr />

                  <div className="h-[calc(100dvh-180px)] sm:h-[calc(100dvh-190px)] mb-2 overflow-auto">{content}</div>
                </div>
                <div className="px-4 max-h-[55px] flex justify-end gap-2 absolute bg-gray-50 bottom-0 border-t border-r p-3 w-full transition duration-150 ease-out hover:ease-in z-10">
                  {activeStep !== 0 && (
                    <Button variant="backward" className={'h-8'} onClick={() => setActiveStep(activeStep - 1)}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="forward"
                    className={'h-8'}
                    onClick={async () => {
                      const relevantErrors = filterErrorsForActiveStep();
                      if (Object.keys(relevantErrors).length > 0) {
                        highlightErrors(relevantErrors);
                        return;
                      }

                      if (activeStep !== totalActiveStepIndex) {
                        setActiveStep(activeStep + 1);
                      } else {
                        await submitForm();
                        resetForm();
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <LoadingIcon height={20} className={'animate-spin text-white'} />
                    ) : activeStep === totalActiveStepIndex ? (
                      discountDetails?.id ? (
                        'Save'
                      ) : (
                        'Submit'
                      )
                    ) : (
                      'Next'
                    )}
                  </Button>
                </div>
              </div>
              <div className="p-4 hidden sm:block">
                {type === CouponType.Payment ? (
                  <CreateOfferPreview activeStep={activeStep} selectedPaymentOffer={null} isEdit={open} />
                ) : (
                  <CreateDiscountPreview activeStep={activeStep} selectedCoupon={null} isEdit={open} />
                )}
              </div>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
      </AlertDialogContent>
    </AlertDialog>
  );
};

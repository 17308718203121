import { store } from './store';
import MainApp from './app/app';
import mixpanel from 'mixpanel-browser';
import snackbarConfig from './config/snackbar';
import { SnackbarProvider } from 'notistack';
import { AuthContext } from './context/auth';
import client from './graphql/apollo-provider';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  const [accessToken, setAccessToken] = useLocalStorage('access_token', '');
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', '');

  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
    debug: process.env.REACT_APP_ENV === 'development'
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AuthContext.Provider
            value={{
              accessToken,
              refreshToken,
              setAccessToken,
              setRefreshToken
            }}
          >
            <ReduxProvider store={store}>
              <SnackbarProvider {...snackbarConfig}>
                <MainApp />
              </SnackbarProvider>
            </ReduxProvider>
          </AuthContext.Provider>
        </BrowserRouter>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;

import React, { FC, useState } from 'react';
import { UilDraggabledots, UilEye, UilEditAlt, UilEyeSlash } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  Card,
  Label,
  Separator
} from '../../flexyui';
import { InputTextField } from '../../shared';
import PaymentCard from './payment-card';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { MethodLayoutStyle } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import * as Unicons from '@iconscout/react-unicons';

export interface CardProps {
  data: any;
  id: number | undefined;
  handleCancel: () => void;
  handleEdit: (value: any, type: string) => void;
  handleVisibility: (value: any, type: string) => void;
}

const PaymentMethodCard: FC<CardProps> = ({ id, data, handleEdit, handleCancel, handleVisibility }) => {
  const { title, description, key: paymentType, visibility, PARTIAL_COD } = data;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: `${id}` });

  const [open, setOpen] = useState<boolean>(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto'
  };

  const paymentCustomization = useSelector((state: RootState) => state.customization.payment);
  const hasDescription = paymentCustomization.method_layout_style !== MethodLayoutStyle.Layout_3;

  return (
    <>
      <Card
        key={id}
        className="overflow-hidden mb-[14px] pointer-events-none"
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
      >
        <div className="flex">
          <div className="bg-gray-100 p-3 w-full">
            <Label htmlFor={title || 'Label'} size={'paragraph'}>
              <UilDraggabledots size="20" className="text-gray-600 inline-block mr-3 cursor-grab pointer-events-auto" />
              {paymentType?.replace(/_/g, ' ')}
            </Label>
            <div className="mt-2">
              <PaymentCard subtitle={title} description={description} paymentIcon={paymentType} />
            </div>
          </div>
          <div className="flex flex-col justify-around p-[10px]">
            <Button
              variant={'icon'}
              className="p-[6px] pointer-events-auto"
              onClick={() => handleVisibility(!visibility, paymentType)}
            >
              {visibility ? <UilEye size={22} /> : <UilEyeSlash size={22} />}
            </Button>
            <Button variant={'icon'} className="p-[6px] pointer-events-auto" onClick={() => setOpen(true)}>
              <UilEditAlt size={22} />
            </Button>
          </div>
        </div>
      </Card>

      <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <Formik
          initialValues={{
            title,
            description,
            ...(paymentType === 'COD' && {
              partialCodTitle: PARTIAL_COD?.title || '',
              partialCodDescription: PARTIAL_COD?.description || ''
            })
          }}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            handleEdit(values, paymentType);
            setOpen(false);
            resetForm();
          }}
        >
          {(props) => (
            <Form>
              <DialogContent size="sm" close={true}>
                <DialogHeader>
                  <DialogTitle>Edit Payment Method</DialogTitle>

                  <div className="py-2 flex flex-col px-5">
                    {paymentType === 'COD' && <Label className="mb-2">COD</Label>}
                    <PaymentCard
                      subtitle={props.values.title}
                      description={props.values.description}
                      paymentIcon={paymentType}
                    />
                    <Label variant="default" size="paragraph" className="mt-5 mb-1">
                      Title
                    </Label>
                    <InputTextField name="title" type="text" />
                    {hasDescription && (
                      <>
                        <Label variant="default" size="paragraph" className="mt-2 mb-1">
                          Description
                        </Label>
                        <InputTextField name="description" type="text" />
                      </>
                    )}
                  </div>

                  {paymentType === 'COD' && (
                    <>
                      <Separator className="!my-3" lineStyle="dashed" />
                      <div className="flex flex-col px-5">
                        <div className="flex items-center mb-2">
                          <Label>Partial COD</Label>
                          <ButtonWithInfo
                            heading="Note"
                            description={
                              <div>
                                In order to show the pending amount, use{' '}
                                <span className="font-mono text-green-700 leading-5">$pending_amount </span>
                                <span className="-ml-1">variable</span>
                              </div>
                            }
                            calculation={false}
                          >
                            <Unicons.UilInfoCircle size={15} className="ml-1.5" />
                          </ButtonWithInfo>
                        </div>
                        <PaymentCard
                          subtitle={props.values.partialCodTitle}
                          description={props.values.partialCodDescription}
                          paymentIcon={paymentType}
                          isPartialCod={true}
                        />
                        <Label variant="default" size="paragraph" className="mt-5 mb-1">
                          Title
                        </Label>
                        <InputTextField name="partialCodTitle" type="text" />
                        {hasDescription && (
                          <>
                            <Label variant="default" size="paragraph" className="mt-2 mb-1">
                              Description
                            </Label>
                            <InputTextField name="partialCodDescription" type="text" />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </DialogHeader>
                <DialogFooter className="sm:justify-end">
                  <Button
                    variant="outline"
                    size="md"
                    onClick={() => {
                      setOpen(false);
                      props.resetForm();
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    onClick={props.submitForm}
                    disabled={
                      props.values.title === '' ||
                      (hasDescription && props.values.description === '') ||
                      (paymentType === 'COD' &&
                        (props.values.partialCodTitle === '' ||
                          (hasDescription && props.values.partialCodDescription === '')))
                    }
                  >
                    Save
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default PaymentMethodCard;

export function numberWithCommas(x: string | number) {
  const parts = String(x).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatPrice = (num: number) => {
  const rounded = Math.ceil(num * 100) / 100;
  const parts = String(rounded).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.') + (parts.length === 1 ? '.00' : parts[1].length === 1 ? '0' : '');

  // return parts.join('.');
};

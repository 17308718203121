import dayjs from 'dayjs';
import classNames from 'classnames';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import * as Unicons from '@iconscout/react-unicons';
import { formatPrice } from '../../../utils/format-price';
import { Button, Card, Label, Skeleton } from '../../flexyui';
import { PaymentProviderIcons } from '../../../constants/settings';
import { capitalizeFirstLetter } from '../../../utils/convert-currency';
import { OrderQuery, PaymentProvider, TransactionState, TransactionType } from '../../../graphql';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
};

export const OrderTransactions: React.FC<Props> = ({ data, loading }) => {
  const [showAll, setShowAll] = useState(false);

  const transactionsToShow =
    data?.transaction_history && data.transaction_history.length > 0
      ? showAll
        ? data.transaction_history
        : [data.transaction_history[0]]
      : [];

  return (
    <Card className="p-4">
      <div className="mb-4">
        <Label size="md" className="text-base">
          Transactions
        </Label>
      </div>
      {loading ? (
        <Skeleton className="w-full !m-0 h-[100px]" />
      ) : transactionsToShow.length > 0 ? (
        transactionsToShow.map((translation) => {
          const PaymentIcon = PaymentProviderIcons[translation?.provider as keyof typeof PaymentProviderIcons];

          return (
            <div className="border border-muted shadow p-3 rounded-lg mb-4 last:mb-0">
              <div className="flex items-center justify-between mb-3">
                <div className="font-medium flex items-center gap-0.5 text-gray-500 -ml-1">
                  {translation?.type === TransactionType.Refund ? (
                    <Unicons.UilArrowUpRight className="h-4" />
                  ) : (
                    <Unicons.UilArrowDownLeft className="h-4" />
                  )}
                  {translation?.type === TransactionType.Refund ? 'Refund' : 'Payment'}
                </div>
                <div
                  className={classNames(
                    'flex gap-0.5 items-center border rounded-2xl py-[1px] pl-0.5 pr-2.5 font-medium w-fit text-xs',
                    {
                      'border-green-700 text-green-700 bg-green-50': translation?.state === TransactionState.Success,
                      'border-yellow-700 text-yellow-700 bg-yellow-50': translation?.state === TransactionState.Pending,
                      'border-blue-700 text-blue-700 bg-blue-50': translation?.state === TransactionState.Initiated,
                      'border-red-700 text-red-700 bg-red-50': translation?.state === TransactionState.Failed
                    }
                  )}
                >
                  {translation?.state === TransactionState.Success && <Unicons.UilCheckCircle className="h-4" />}
                  {translation?.state === TransactionState.Pending && <Unicons.UilClock className="h-4" />}
                  {translation?.state === TransactionState.Initiated && <Unicons.UilSync className="h-3.5" />}
                  {translation?.state === TransactionState.Failed && <Unicons.UilTimes className="h-4" />}

                  {translation?.type === TransactionType.Refund && translation?.state === TransactionState.Initiated
                    ? 'Processing'
                    : capitalizeFirstLetter(translation?.state || '')}
                </div>
              </div>
              <div className="flex items-center justify-between mt-3">
                <div className="text-gray-500">Amount</div>
                <div className="font-medium">
                  ₹
                  {formatPrice(
                    translation?.provider === PaymentProvider.CashOnDelivery
                      ? translation?.amount || 0
                      : (translation?.amount || 0) / 100
                  )}
                </div>
              </div>
              {translation?.payment_method && (
                <div className="flex items-center justify-between mt-3">
                  <div className="text-gray-500">Method</div>
                  <div className="font-medium">{translation.payment_method}</div>
                </div>
              )}
              {translation?.provider && translation?.provider !== PaymentProvider.CashOnDelivery && (
                <div className="flex items-center justify-between mt-3">
                  <div className="text-gray-500">Gateway</div>
                  <div className={translation?.provider === PaymentProvider.Razorpay ? '-mr-2' : ''}>
                    <PaymentIcon height={20} />
                  </div>
                </div>
              )}
              <div className="text-xs flex items-center justify-between text-gray-500 mt-4">
                <div className="flex items-center">
                  <span>ID:</span>
                  <span className="ml-0.5 w-[80px] overflow-hidden whitespace-nowrap text-ellipsis">
                    {translation?.order_id}
                  </span>
                  <Unicons.UilCopy
                    size={16}
                    className="cursor-pointer "
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(translation?.order_id || '');
                      enqueueSnackbar('Copied to clipboard', {
                        variant: 'success'
                      });
                    }}
                  />
                </div>
                <div>{dayjs(translation?.created_at).format('MMM D YYYY, hh:mm A')}</div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-gray-500">No transaction data available for this session.</div>
      )}

      {data?.transaction_history && data.transaction_history.length > 1 && (
        <Button
          variant="link"
          className="w-fit flex items-center text-primary cursor-pointer hover:no-underline h-6 text-xs"
          onClick={() => setShowAll(!showAll)}
        >
          <Unicons.UilAngleDown
            className={classNames('pt-0.5 transition-transform duration-300', { 'rotate-180': showAll })}
          />
          {showAll ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Card>
  );
};

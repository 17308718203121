import React from 'react';
import { OrderQuery } from '../../../graphql';
import { Card, Label, Skeleton } from '../../flexyui';

type Props = {
  data: OrderQuery['order'] | undefined;
  loading: boolean;
};

export const AnalyticsDetails: React.FC<Props> = ({ data, loading }) => {
  const formatKey = (key: string) => {
    return key.startsWith('utm_')
      ? 'UTM ' +
          key
            .slice(4)
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase())
      : key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Card className="p-4 mb-4">
      <div className="mb-4">
        <Label size="md" className="text-base">
          Additional details
        </Label>
      </div>
      {loading ? (
        <>
          <Skeleton size={'label'} className="w-[50%] h-4 mb-1" />
          <Skeleton size={'label'} className="w-[80%] h-4 mb-4" />
          <Skeleton size={'label'} className="w-[50%] h-4 mb-1" />
          <Skeleton size={'label'} className="w-[80%] h-4" />
        </>
      ) : (
        <>
          {data?.order_invoice?.payment_method && (
            <div className="mb-3">
              <div className="font-medium mb-1">Payment Method</div>
              <div className="text-gray-500">{data.order_invoice.payment_method}</div>
            </div>
          )}
          {data?.meta_data &&
            Object.entries(data.meta_data).map(([key, value]) =>
              value ? (
                <div key={key} className="mt-3">
                  <div className="font-medium mb-1">{formatKey(key)}</div>
                  <div className="text-gray-500 break-all">{value}</div>
                </div>
              ) : null
            )}
          {data?.order_utm &&
            Object.entries(data.order_utm).map(([key, value]) =>
              value ? (
                <div key={key} className="mt-3">
                  <div className="font-medium mb-1">{formatKey(key)}</div>
                  <div className="text-gray-500 break-all">{value}</div>
                </div>
              ) : null
            )}
        </>
      )}
    </Card>
  );
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import * as Unicons from '@iconscout/react-unicons';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setPageProgress } from '../../store/main-slice';
import { DashboardAction } from '../navbar/dashboard-action';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { Card, Dialog, DialogContent, DialogDescription, DialogHeader } from '../flexyui';

type Props = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
};

export const GettingStartedWrapper: React.FC<Props> = ({ leftContent, rightContent }) => {
  const dispatch = useDispatch<AppDispatch>();
  const pageProgress = useSelector((state: RootState) => state.main.pageProgress);
  const [showContactDetails, setShowContactDetails] = useState<boolean>(false);

  return (
    <>
      <LoadingBar
        color="#2f72ff"
        height={3}
        progress={pageProgress}
        onLoaderFinished={() => dispatch(setPageProgress(0))}
      />

      <div className="w-full h-screen flex items-center justify-center p-10 xl:p-0 bg-slate-200">
        <div
          className="h-full w-full items-center justify-center hidden md:flex opacity-0 scale-95 transition-all duration-500"
          onLoad={(e) => {
            e.currentTarget.classList.add('opacity-100', 'scale-100');
          }}
        >
          <div className="relative bg-white rounded-2xl max-w-[1200px] w-full border border-[#d4d5d5] shadow-xl flex flex-col overflow-hidden">
            <div className="absolute top-0 z-10 w-full flex items-center justify-between pl-6 pr-2 py-4">
              <Logo className="h-8 w-24" />
              <DashboardAction />
            </div>
            <div className="flex-1 grid grid-cols-10">
              <div className="col-span-4 bg-[#f8f9f9] pt-[66px] px-4 flex items-center justify-center">
                <div className="w-full h-full pt-4 pb-6 max-w-[300px] xl:max-w-[370px]">
                  {leftContent}
                  <div className="w-full flex flex-col items-center justify-center">
                    <div
                      onClick={() => setShowContactDetails(true)}
                      className="flex items-center gap-1.5 py-1.5 px-2 rounded-2xl border border-[#d4d5d5] w-fit cursor-pointer"
                    >
                      <div className="min-w-5 w-5 h-5 rounded-full bg-black flex items-center justify-center">
                        <Unicons.UilQuestion size={20} className="text-white" />
                      </div>
                      <div className="font-semibold">Help</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-6 pt-[66px] flex items-center justify-center">
                <div className="w-full h-full max-h-full pt-4 pb-6 relative max-w-[520px]">{rightContent}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-screen w-screen flex items-center justify-between md:hidden">
          <div className="w-full text-center px-5">Please open the app in desktop to continue with the onboarding.</div>
        </div>
      </div>

      <Dialog open={showContactDetails} onOpenChange={(value: boolean) => setShowContactDetails(value)}>
        <DialogContent className="!gap-0" close={true}>
          <div>
            <DialogHeader>
              <DialogDescription className="!p-0 !m-0 text-[#2A324C]">
                <div className="p-4">
                  <div>
                    <div className="flex flex-col items-center text-center mt-4">
                      <div className="text-xl font-semibold">Get in touch with our team</div>
                      <div className="text-gray-500 text-xs">
                        Reach out to our team for assistance or questions. We're here for you!
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 mt-6">
                      <Card className="shadow-lg p-3 hover:shadow-xl hover:scale-105 transition-all duration-300">
                        <div className="flex gap-3">
                          <div className="p-1.5 border border-gray-300 w-fit h-fit rounded-xl">
                            <Unicons.UilPhone size={20} />
                          </div>
                          <div>
                            <div className="text-base font-medium">Email us</div>
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'mailto:hello@flexype.io';
                              }}
                              className="font-medium text-blue-500 hover:underline"
                            >
                              hello@flexype.io
                            </Link>
                          </div>
                        </div>
                      </Card>
                      <Card className="shadow-lg p-3 hover:shadow-xl hover:scale-105 transition-all duration-300">
                        <div className="flex gap-3">
                          <div className="p-1.5 border border-gray-300 w-fit h-fit rounded-xl">
                            <Unicons.UilEnvelope size={20} />
                          </div>
                          <div>
                            <div className="text-base font-medium">Call us</div>
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location.href = 'tel:+918419918149';
                              }}
                              className="font-medium text-blue-500 hover:underline"
                            >
                              +91 84199 18149
                            </Link>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
